import React from 'react';
import { useWindowSize } from 'src/hooks/useWindowSize';
import {
  PageSectionStoryblok,
  StoryblokComponentType,
  StoryblokDesktopLayout,
} from 'src/typings';
import { parseRichText } from 'src/util';
import { ArtCutout, CutoutLocation } from '../';
import { CtaButton } from 'src/components/buttons';
import { Title, SiteCtaButtonStyle } from '../style';
import {
  PageSectionArtBoxStyle,
  PageSectionArtStyle,
  PageSectionParagraphStyle,
  PageSectionStyle,
  PageSectionTextStyle,
} from './style';
import { imageByWidth } from '../imageSize';

interface PageSectionProps {
  data: PageSectionStoryblok;
  nextSection: StoryblokComponentType;
  offset?: number;
  slug?: string;
}

export function PageSection(props: PageSectionProps) {
  const { isMobile, windowSize } = useWindowSize();
  const { data, nextSection, offset } = props;

  const desktopLayout = data.desktop_layout;

  const isRightLayout = desktopLayout === StoryblokDesktopLayout.right;
  let location = isRightLayout
    ? CutoutLocation.right
    : desktopLayout === StoryblokDesktopLayout.left
    ? CutoutLocation.left
    : CutoutLocation.bottom;
  location = isMobile ? CutoutLocation.bottom : location;

  const overlay = ArtCutout(location, isMobile);

  const overlay2 = ArtCutout(CutoutLocation.top, isMobile);

  const overlay2Visible = location === CutoutLocation.bottom && offset !== 0;

  const isFullWidth = isMobile || desktopLayout === StoryblokDesktopLayout.full;

  const imageComponent = data.image?.filename ? (
    <PageSectionArtBoxStyle layout={desktopLayout} isFullWidth={isFullWidth}>
      <PageSectionArtStyle
        src={
          isMobile
            ? imageByWidth(data.image.filename, windowSize.width)
            : data.image.filename
        }
        alt={data.image.alt}
        layout={desktopLayout}
        className={'page-section-fade'}
      />
      {overlay}
      {overlay2Visible && overlay2}
    </PageSectionArtBoxStyle>
  ) : (
    <div />
  );

  const contentSection = (
    <PageSectionTextStyle
      layout={desktopLayout}
      isFullWidth={isFullWidth}
      hasCta={data?.cta?.length > 0}
    >
      {data.title && <Title>{data.title}</Title>}
      {data.text_area && (
        <PageSectionParagraphStyle
          layout={desktopLayout}
          isFullWidth={isFullWidth}
        >
          {parseRichText(data.text_area)}
        </PageSectionParagraphStyle>
      )}

      {data.cta && data.cta.length > 0
        ? data.cta.map((button) => (
            <SiteCtaButtonStyle key={button._uid}>
              <CtaButton data={button} />
            </SiteCtaButtonStyle>
          ))
        : nextSection == StoryblokComponentType.PageSection && (
            <SiteCtaButtonStyle />
          )}
    </PageSectionTextStyle>
  );

  return (
    <PageSectionStyle
      className={`SiteHeader ${desktopLayout}`}
      key={data._uid}
      layout={desktopLayout}
    >
      {!isMobile && isRightLayout && contentSection}
      {imageComponent}
      {(isMobile || !isRightLayout) && contentSection}
    </PageSectionStyle>
  );
}
