import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { eps, farmingStartTime, oneYear } from 'src/constants';
import { ExtendedFarmData } from 'src/contracts';
import { MyBalances } from 'src/redux/Account/Reducer';
import { GlobalBalances } from 'src/redux/Contracts/Reducer';
import { ethToDecimal } from '.';

export const calculateTotalGenesisMined = (
  start: number,
  claimBalance: bigint,
  claimLastUpdated: number,
  storedEmissions: BigNumberish,
) => {
  // Fallback here is the mainnet sale start time, 8am Pacific, Jan 18th.
  const startTime = (start || 1642521600) + 604800;
  const twentyTwo = BigInt(10 ** 22);
  const eighteen = BigInt(10 ** 18);
  const now = BigInt(Date.now()) / BigInt(1000) - BigInt(startTime);
  const twoYears = BigInt(63113904);
  const lastEmission = BigInt(claimLastUpdated - startTime);
  const existingEmissionLevel =
    (twentyTwo * lastEmission) / (lastEmission + twoYears);
  const newEmissionLevel = (twentyTwo * now) / (now + twoYears);
  const emission = claimBalance * (newEmissionLevel - existingEmissionLevel);
  return parseFloat(
    (
      (emission + BigInt(storedEmissions.toString().split('.')[0])) /
      eighteen
    ).toString(),
  );
};

export const calculateTotalGenesisEarned = () => {
  const time = BigInt(
    Math.floor(Date.now().valueOf() / 1000 - farmingStartTime),
  );
  const emitted = BigNumber.from((time * eps * oneYear).toString()).div(
    BigNumber.from((oneYear + time).toString()),
  );
  return ethToDecimal(emitted);
};

export const calculateCurrentGenesisEarned = (
  farmData: ExtendedFarmData,
  friendlyGenesisPerDay: number,
) => {
  const genesisToCollect = farmData.genesisEarned || '0';
  const friendlyGenesisToCollect = ethToDecimal(genesisToCollect);

  // Calculates the time between the last data pull and 2 seconds ago.
  // The 2 seconds helps because it smooths out the amount we want to show
  const twoSecondsAgo = Date.now().valueOf() - 2000;
  const secondsSincePull =
    farmData?.timestamp && farmData.timestamp < twoSecondsAgo
      ? (twoSecondsAgo - farmData.timestamp) / 1000
      : 0;
  const earned =
    friendlyGenesisToCollect +
    (friendlyGenesisPerDay / (24 * 60 * 60)) * secondsSincePull;
  return earned;
};

export const getFriendlyGenesisBalance = (
  myBalances: MyBalances,
  globalBalances: GlobalBalances,
  farmData: ExtendedFarmData,
) => {
  return (
    farmData.friendlyGenesisEarned +
    ethToDecimal(
      myBalances.genesis +
        (globalBalances.totalVotePower > BigInt(0)
          ? (myBalances.governance * globalBalances.govGenesisBalance) /
            globalBalances.totalVotePower
          : BigInt(0)),
    )
  );
};
