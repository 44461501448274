import React from 'react';
import { ButtonType } from 'src/typings';
import { ModalBase, ModalProps, ModalHeader, ModalFooter, ModalBody } from '.';
import { useSelector } from 'react-redux';
import { AppState } from 'src/redux/CreateStore';
import { contract } from 'src/contracts';
import { useTranslation } from 'react-i18next';
import { DataTable, TableDataFormat, TableRowData } from '../table';
import { address } from 'src/interfaces/Common';
import { NavButton } from '../buttons';
import { Routes } from 'src/constants/routes';
import { VerticalSpacer } from '..';
import {
  ethToDecimal,
  getFriendlyGenesisBalance,
  calculateTotalGenesisMined,
} from 'src/util';

export function CurrencyDetailsModal(
  props: ModalProps & { currency: address },
) {
  const { visible, show, currency } = props;
  const isGame = currency === contract.side.game;
  const { t } = useTranslation();

  const myBalances = useSelector((state: AppState) => state.Account.myBalances);
  const globalBalances = useSelector(
    (state: AppState) => state.Contracts.globalBalances,
  );
  const farmData = useSelector((state: AppState) => state.Account.farmData);
  const lastEmission = useSelector(
    (state: AppState) => state.Account.lastEmission,
  );
  const storedEmission = useSelector(
    (state: AppState) => state.Account.worldEmission,
  );
  const globalClaims = useSelector(
    (state: AppState) => state.Contracts.globalClaims,
  );
  const prices = useSelector((state: AppState) => state.Contracts.prices);

  const gameOnSideChain = ethToDecimal(myBalances.game);
  const friendlyFarmBalance = ethToDecimal(myBalances.farm);

  const gameBalance = gameOnSideChain + friendlyFarmBalance;

  const genesisOnSideChain = ethToDecimal(myBalances.genesis);
  const genesisFarmEarned = farmData.friendlyGenesisEarned;
  const genesisMined = calculateTotalGenesisMined(
    globalClaims[1]?.onSale,
    myBalances.world,
    lastEmission,
    storedEmission,
  );
  const genesisBalance =
    getFriendlyGenesisBalance(myBalances, globalBalances, farmData) +
    genesisMined;

  const isLoading = myBalances.isLoading;
  const gameRows: TableRowData[] = [
    {
      label: t('modal.currency.game.table.side'),
      data: gameOnSideChain,
      dataFormat: TableDataFormat.Game,
      usdRatio: prices.game,
      isLoading,
    },
    {
      label: t('modal.currency.game.table.farm'),
      data: friendlyFarmBalance,
      dataFormat: TableDataFormat.Game,
      usdRatio: prices.game,
      isLoading,
    },
    {
      label: t('modal.currency.game.table.total'),
      data: gameBalance,
      dataFormat: TableDataFormat.Game,
      usdRatio: prices.game,
      isLoading,
    },
  ];

  const genesisRows: TableRowData[] = [
    {
      label: t('modal.currency.genesis.table.side'),
      data: genesisOnSideChain,
      dataFormat: TableDataFormat.Genesis,
      usdRatio: prices.genesis,
      isLoading,
    },
    {
      label: t('modal.currency.genesis.table.farm'),
      data: genesisFarmEarned,
      dataFormat: TableDataFormat.Genesis,
      usdRatio: prices.genesis,
      isLoading,
    },
    {
      label: t('modal.currency.genesis.table.mining'),
      data: genesisMined,
      dataFormat: TableDataFormat.Genesis,
      usdRatio: prices.genesis,
      isLoading,
    },
    {
      label: t('modal.currency.genesis.table.total'),
      data: genesisBalance,
      dataFormat: TableDataFormat.Genesis,
      usdRatio: prices.genesis,
      isLoading,
    },
  ];

  const mainNetString = isGame
    ? t('modal.currency.game.mainnet')
    : t('modal.currency.genesis.mainnet');

  return (
    <ModalBase visible={visible} show={show}>
      <>
        <ModalHeader>
          <h3>
            {t(
              isGame
                ? 'modal.currency.game.title'
                : 'modal.currency.genesis.title',
            )}
          </h3>
        </ModalHeader>
        <ModalBody>
          <p>
            {t(
              isGame
                ? 'modal.currency.game.text'
                : 'modal.currency.genesis.text',
            )}
          </p>
          <VerticalSpacer />
          <DataTable rows={isGame ? gameRows : genesisRows} />
          <VerticalSpacer />
          {mainNetString && <p>{mainNetString}</p>}
        </ModalBody>
        <ModalFooter>
          <NavButton
            text={t('button.deposit')}
            type={ButtonType.primary}
            link={'https://wallet.polygon.technology/bridge/'}
            padded
            onClick={() => show(false)}
          />
          {isGame ? (
            <NavButton
              text={t('button.getGame')}
              type={ButtonType.primary}
              link={Routes.getGame}
              onClick={() => show(false)}
            />
          ) : (
            <NavButton
              text={t('button.getGenesis')}
              type={ButtonType.primary}
              link={Routes.getGenesis}
              onClick={() => show(false)}
            />
          )}
        </ModalFooter>
      </>
    </ModalBase>
  );
}
