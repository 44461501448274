import React from 'react';
import { ModalBase, ModalProps, ModalHeader, ModalBody } from '.';

export function WrongNetworkModal(props: ModalProps) {
  const { visible, show } = props;

  function onShowHide(isShowing: boolean) {
    show(isShowing);
  }

  return (
    <ModalBase visible={visible} show={onShowHide}>
      <>
        <ModalHeader>
          <h3>Wrong Network</h3>
        </ModalHeader>
        <ModalBody>
          <p>
            Please open you Metamask and switch to the &quot;Polygon&quot;
            network
          </p>
        </ModalBody>
      </>
    </ModalBase>
  );
}
