// @flow
import { fork } from 'redux-saga/effects';
import { getStoriesSaga, setLanguageSaga } from './Stories/Sagas';
import { reloadAccountDataSaga, setAddressSaga } from './Account/Sagas';

export default function* root() {
  yield fork(getStoriesSaga);
  yield fork(reloadAccountDataSaga);
  yield fork(setAddressSaga);
  yield fork(setLanguageSaga);
}
