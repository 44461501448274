import { BigNumber } from 'ethers';
import { store } from 'src';
import { uint256 } from 'src/interfaces/Common';
import { sendTransaction } from './sendTransaction';
import {
  TransactionData,
  TransactionStatus,
  TransactionType,
} from './transactionTypes';

type FarmDepositTx = TransactionData<[amount: uint256]>;

export interface FarmDepositResult {
  gameStaked: BigNumber;
  balance: BigNumber;
  debt: BigNumber;
  timestamp: number;
}

// Assumption here is that all amount checks have already taken place
export async function farmingDepositTx(amount: string) {
  const { Contracts } = store.getState();
  const { farm } = Contracts.contracts;
  console.log('farming contract', farm);
  if (!farm) {
    throw 'farmingDepositTx store failure: no farm contract';
  }

  function callback(tx: FarmDepositTx) {
    try {
      console.log('farmingDepositTx completed deposit:');
    } catch (err) {
      console.error('farmingDepositTx failed to pase receipt', tx.receipt, err);
    }
  }

  function onError(error: unknown, tx: FarmDepositTx) {
    console.log('farmingDepositTx error', error, tx.receipt);
  }

  console.log('farmingDepositTx starting contract call');
  const transaction: FarmDepositTx = {
    type: TransactionType.FarmDeposit,
    status: TransactionStatus.created,
    function: farm.deposit,
    params: [amount],
  };
  // The contract call via metamask
  try {
    console.log('farmingDepositTx sending tx', transaction);
    sendTransaction(transaction, callback, onError);
  } catch (error) {
    console.log(error);
  }
}
