import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'rsuite';
import { submitHubspotForm } from 'src/util';
import { MobileProps, VerticalSpacer } from '..';

// hooks
import { ModalBase, ModalProps } from '.';

// components
import {
  EmailInputTitle,
  EmailInputSubTitle,
  EmailSignupWrapper,
  EmailInput,
} from '../newsletter/style';
import { ButtonStyle, ButtonText } from '../buttons/style';
import { ButtonType } from 'src/typings';

type Props = MobileProps & ModalProps;

export const EmailSignupModal = (props: Props) => {
  const { visible, show } = props;
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const { t } = useTranslation();

  async function submit(event: React.MouseEvent) {
    event.preventDefault();
    const result = await submitHubspotForm(email);
    if (!result) {
      setIsError(true);
    } else {
      setEmail('');
      setIsSubmitted(true);
    }
  }

  useEffect(() => {
    if (visible) {
      setEmail('');
      setIsError(false);
      setIsSubmitted(false);
    }
  }, [visible]);

  const Content = () => {
    return (
      <>
        <EmailInputTitle>
          {t('modal.emailSignup.titleA')}
          <br />
          {t('modal.emailSignup.titleB')}
        </EmailInputTitle>
        {isSubmitted ? (
          <>
            <EmailInputSubTitle>
              {t('modal.emailSignup.thanks')}
            </EmailInputSubTitle>
            <VerticalSpacer />
            <ButtonStyle
              onClick={() => show(false)}
              className={`closeButton`}
              $variant={ButtonType.primary}
            >
              <ButtonText>{t('modal.emailSignup.closeButton')}</ButtonText>
            </ButtonStyle>
          </>
        ) : (
          <>
            <EmailInputSubTitle>
              {t('modal.emailSignup.subtitle')}
            </EmailInputSubTitle>
            <EmailInput
              type={'email'}
              placeholder={t('modal.emailSignup.enterEmail')}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setIsError(false);
              }}
              disabled={isSubmitted}
              isError={isError}
            />
            <VerticalSpacer />
            <ButtonStyle
              onClick={(event) => submit(event)}
              className={`emailSignupButton`}
              $variant={ButtonType.primary}
            >
              <ButtonText>{t('modal.emailSignup.submitButton')}</ButtonText>
            </ButtonStyle>
            <VerticalSpacer />
            <ButtonStyle
              onClick={() => show(false)}
              className={`emailCancelButton`}
              $variant={ButtonType.tertiary}
            >
              <ButtonText>{t('modal.emailSignup.cancelButton')}</ButtonText>
            </ButtonStyle>
          </>
        )}
      </>
    );
  };

  return (
    <ModalBase visible={visible} show={show} size={'md'}>
      <>
        <Modal.Header />
        <EmailSignupWrapper>{Content()}</EmailSignupWrapper>
      </>
    </ModalBase>
  );
};
