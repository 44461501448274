/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import 'rc-slider/assets/index.css';
import 'rsuite/dist/styles/rsuite-default.css';
import { useWindowSize } from 'src/hooks/useWindowSize';
import {
  WorldSingleStyle,
  NFTImageContainer,
  NFTImage,
  NFTTitle,
  SubHeader,
  ContentContainer,
  BackButtonContainer,
  BackButtonImage,
  BackButtonText,
  InnerContainer,
  NFTImageInner,
  getImageWidth,
  ComingSoon,
  PriceDiscoveryText,
} from './style';
import { VerticalSpacer } from 'src/components';
import {
  calculatePrice,
  ClaimData,
  getClaimCountByPrice,
  MIN_BUY_PRICE,
  PRICE_DISCOVERY_START,
} from 'src/constants/worlds';
import { useSelector } from 'react-redux';
import { AppState } from 'src/redux/CreateStore';
import { AllClaimData } from 'src/redux/Account/Reducer';
import { EllipsisParagraph } from './ellipsis';
import { imageByWidth } from '../../cmsdriven/imageSize';
import { MemoSimpleClaimGrid } from 'src/components/datadriven/ClaimGrid';
import LeftArrowImg from 'src/img/left_arrow.svg';
import { InformationModal } from 'src/components/modals/InformationModal';
import useModal from 'src/hooks/useModal';
import { SocialLinks } from 'src/constants/social';
import { NbspLink } from '../../cmsdriven/TokenDetailsSection/style';
import { usd, noDecimals, useInterval } from 'src/util';
import { SingleClaimDataTable } from './singleClaimDataTable';
import { ButtonType, StoryblokWorldGridLayout } from 'src/typings';
import { ActionButton, NavButton } from 'src/components/buttons';
import { SellClaimModal } from 'src/components/modals/SellClaimModal';
import { calculateMaticPrice } from 'src/calls';
import { Countdown } from './countdown';
import { Video } from '../ClaimGrid/hoverVideo';
import { Routes } from 'src/constants/routes';

export interface ClaimSingleProps {
  id: number;
}

export function ClaimSingle(props: ClaimSingleProps) {
  const { id } = props;
  const [isOnSale, setIsOnSale] = useState(false);
  const [canTransact, setCanTransact] = useState(false);
  const [saleStart, setSaleStart] = useState(0);
  const info = useModal();
  const sellModal = useModal();
  const { isMobile, windowSize } = useWindowSize();
  const { t } = useTranslation();
  const globalClaims: AllClaimData = useSelector(
    (state: AppState) => state.Contracts.globalClaims,
  );

  const reserves = useSelector((state: AppState) => state.Contracts.reserves);
  const prices = useSelector((state: AppState) => state.Contracts.prices);
  const isAllowedToTransact = useSelector(
    (state: AppState) => state.Account.isAllowedToTransact,
  );

  const claim = globalClaims[id] || ({} as ClaimData);
  const {
    myBalance,
    totalSupply,
    buyPrice,
    burnPrice,
    yearEmission,
    art,
    name,
    description,
    video,
    onSale,
  } = claim;

  function setTransactionStatus() {
    const nowSeconds = Date.now() / 1000;
    let saleStartTime = onSale;

    const canTransactStatus =
      nowSeconds >= onSale + 900 ||
      (isAllowedToTransact.isAllowedByAllowList &&
        isAllowedToTransact.isAllowedByBlockList);

    // This pushes the onSale date forward by 15 minutes if your account is:
    //   (a) blocked, and the blocklist is active, or
    //   (b) not on the allowlist, and the allowlist is active
    if (!canTransactStatus) {
      saleStartTime += 900;
    }
    setSaleStart(saleStartTime);
    setCanTransact(canTransactStatus);
    setIsOnSale(nowSeconds >= onSale);
  }
  // If it's on sale, and it's only been on sale for the last 30 minutes, refresh data every 5s?

  const isComingThisMonth =
    (!isOnSale || !canTransact) &&
    saleStart < Date.now() / 1000 + 60 * 60 * 24 * 35;

  const [imgUrl, setImgUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [price, setPrice] = useState(calculatePrice(onSale, buyPrice));

  useInterval(() => {
    const newPrice = calculatePrice(onSale, buyPrice);
    if (price !== newPrice) {
      setPrice(newPrice);
    }
    if (
      (!isOnSale && Date.now() / 1000 >= onSale) ||
      (!canTransact && Date.now() / 1000 >= saleStart)
    ) {
      setTransactionStatus();
    }
  }, 1000);

  useEffect(() => {
    if (claim && video) {
      setVideoUrl(video);
    }

    if (claim && art) {
      setImgUrl(art);
    }
    setPrice(calculatePrice(onSale, buyPrice));
    setTransactionStatus();
  }, [claim]);

  const gamePrice = prices.game;
  const genesisPrice = prices.genesis;
  const { price: maticPrice } = calculateMaticPrice(reserves, prices, price);

  const apr = (yearEmission * (genesisPrice / gamePrice) * 100) / price;

  const imgWidth = isMobile
    ? Math.max(windowSize.width, 600)
    : getImageWidth(windowSize.width);
  const vidWidth = isMobile ? windowSize.width : imgWidth;
  const vidHeight = (vidWidth * 9) / 16;

  const imageContainer = (
    <NFTImageContainer>
      <NFTImageInner>
        <NFTImage
          imgWidth={imgWidth}
          src={imgUrl ? imageByWidth(imgUrl, imgWidth) : ''}
          alt={name}
        />
        {videoUrl && (
          <Video
            imgWidth={vidWidth}
            imgHeight={vidHeight}
            videoUrl={videoUrl}
            autoplay
            // frameBorder="0"
            // allow="autoplay"
            // allowFullScreen
          ></Video>
        )}
      </NFTImageInner>
      {!isMobile && (
        <MemoSimpleClaimGrid
          claims={globalClaims}
          layout={StoryblokWorldGridLayout.smallGrid}
          prices={prices}
        />
      )}
    </NFTImageContainer>
  );

  const SellModal = SellClaimModal({ ...sellModal, claim: claim });
  const InfoModal = InformationModal({
    ...info,
    title: t('modal.claim-info.title'),
    information: (
      <div>
        <p>{t('modal.claim-info.mainpara')}</p>
        <h4 style={{ textAlign: 'left' }}></h4>
        <p>{t('modal.claim-info.buypara')}</p>
        <h4 style={{ textAlign: 'left' }}>{t('modal.claim-info.sellprice')}</h4>
        <p>{t('modal.claim-info.sellpara')}</p>
        <p>
          {t('modal.claim-info.moreinfo')}{' '}
          <NbspLink href={SocialLinks.WHITEPAPER}>
            {t('modal.claim-info.whitepaper')}
          </NbspLink>
        </p>
      </div>
    ),
  });

  const maxBurnPrice = Math.floor((Date.now() / 1000 - onSale) / 3600);
  const minBurnPrice = 168;
  const isTooSoon = maxBurnPrice < minBurnPrice;
  const isReducedPrice = !isTooSoon && maxBurnPrice < burnPrice;
  const actualBurnPrice = isTooSoon
    ? minBurnPrice
    : isReducedPrice
    ? maxBurnPrice
    : burnPrice;
  const saleStartDays = Math.ceil((168 - maxBurnPrice) / 24);
  const waitDays = Math.ceil((burnPrice - maxBurnPrice) / 24);
  const days = Math.ceil((burnPrice - maxBurnPrice) / 24);

  const sellButton = (
    <ActionButton
      type={ButtonType.primary}
      onClick={() => sellModal.show(true)}
      isDisabled={myBalance <= 0}
    >
      {t(isTooSoon ? 'claims.sellTooSoon' : 'claims.sell')}
    </ActionButton>
  );

  const downloadName = () => {
    const fileName = claim.art.split('/').pop();
    return fileName.split('.')[0];
  };

  const presaleClaimsRemaining =
    PRICE_DISCOVERY_START <= MIN_BUY_PRICE
      ? 0
      : getClaimCountByPrice(price) - totalSupply - 300;

  const dataTable = SingleClaimDataTable(
    claim,
    price,
    presaleClaimsRemaining,
    apr,
    gamePrice,
    genesisPrice,
    maticPrice,
    canTransact,
  );

  return (
    <WorldSingleStyle key={id}>
      {isMobile && imageContainer}
      <ContentContainer>
        <BackButtonContainer onClick={() => history.back()}>
          <BackButtonImage src={LeftArrowImg} />
          <BackButtonText>{t('claims.back')}</BackButtonText>
        </BackButtonContainer>
        {name && (
          <InnerContainer>
            {isMobile && <VerticalSpacer />}
            <NFTTitle>{name}</NFTTitle>
            {(!isOnSale || !canTransact) && (
              <>
                <VerticalSpacer />
                <ComingSoon $isComingThisMonth={isComingThisMonth}>
                  <VerticalSpacer />
                  <SubHeader>
                    {isComingThisMonth
                      ? `${t(
                          canTransact ? 'claims.launch' : 'claims.access',
                        )}: ${new Date(saleStart * 1000).toLocaleString(
                          'default',
                          {
                            month: 'short',
                            day: 'numeric',
                          },
                        )}, ${new Date(saleStart * 1000).toLocaleTimeString(
                          'default',
                          {
                            hour: 'numeric',
                            minute: '2-digit',
                          },
                        )}`
                      : t('claims.comingSoon')}
                  </SubHeader>
                  {isComingThisMonth && <VerticalSpacer />}
                  {isComingThisMonth && <Countdown toTime={saleStart} />}
                  <VerticalSpacer />
                </ComingSoon>
                <VerticalSpacer />
              </>
            )}
            <EllipsisParagraph text={description} />

            {isOnSale && price > buyPrice && (
              <>
                <PriceDiscoveryText>
                  {t('claims.launchTitle')}
                  <br />
                  <b>{presaleClaimsRemaining}</b> {t('claims.claimsRemaining')}
                </PriceDiscoveryText>
              </>
            )}
            {isOnSale && dataTable}
            {(!isAllowedToTransact.isAllowedByAllowList ||
              !isAllowedToTransact.isAllowedByBlockList) && (
              <>
                <VerticalSpacer />
                <SubHeader>
                  {t(
                    !isAllowedToTransact.isAllowedByBlockList
                      ? 'claims.blockListHeader'
                      : 'claims.allowListHeader',
                  )}
                </SubHeader>
                <p>
                  {t(
                    !isAllowedToTransact.isAllowedByBlockList
                      ? 'claims.blockList'
                      : 'claims.allowList',
                  )}
                </p>

                <NavButton
                  text={t('claims.discord')}
                  type={ButtonType.primary}
                  link={SocialLinks.DISCORD}
                  padded
                />
              </>
            )}
            {isOnSale && presaleClaimsRemaining > 0 && (
              <p>{t('claims.launchText')}</p>
            )}
            <VerticalSpacer />
            <SubHeader>{t('claims.genesisMining')}</SubHeader>
            <p>{t('claims.description.mining')}</p>
            <p>
              {t('claims.description.buying', {
                genesisamount: noDecimals(yearEmission),
                genesisusdvalue: usd.format(yearEmission * genesisPrice),
                apr: apr.toFixed(2) + '%',
              })}
            </p>
            {myBalance > 0 && (
              <p>
                <a href={`/${Routes.account}`}>{t('claims.viewClaims')}</a>
              </p>
            )}
            <VerticalSpacer />
            <SubHeader>{t('claims.liquidity')}</SubHeader>

            <p>
              {isTooSoon
                ? t('claims.liquidityBeforeExplanation', {
                    days: saleStartDays,
                    waitDays: days,
                    price: actualBurnPrice,
                    burnPrice,
                  })
                : isReducedPrice
                ? t('claims.liquidityReducedExplanation', {
                    waitDays: waitDays,
                    price: actualBurnPrice,
                    burnPrice,
                  })
                : t('claims.liquidityExplanation', {
                    price: actualBurnPrice,
                  })}
              {t('claims.description.prices')}
            </p>
            <p>
              <a onClick={() => info.show(true)}>
                {t('claims.pricingDetails')}
              </a>
            </p>
            <VerticalSpacer />
            {isOnSale && sellButton}
            <VerticalSpacer />
            <VerticalSpacer />
            <SubHeader>{t('claims.download')}</SubHeader>
            <p>
              {t('claims.description.download', {
                worldname: name,
              })}{' '}
              <NbspLink
                target="_blank"
                href={`https://creativecommons.org/publicdomain/zero/1.0/`}
                rel="noopener noreferrer"
              >
                CCO
              </NbspLink>
            </p>
            <p>
              <NbspLink
                href={`https://images.genesis.game/claims/glb/${downloadName()}.glb`}
              >
                {t('claims.description.glb', {
                  worldname: name,
                })}
              </NbspLink>
            </p>
            <p>
              <NbspLink
                href={`https://images.genesis.game/claims/obj/${downloadName()}.zip`}
              >
                {t('claims.description.obj', {
                  worldname: name,
                })}
              </NbspLink>
            </p>
            <p>
              <NbspLink
                target="_blank"
                href={`https://images.genesis.game/claims/mp4/${downloadName()}.mp4`}
                rel="noopener noreferrer"
              >
                {t('claims.description.mp4', {
                  worldname: name,
                })}
              </NbspLink>
            </p>
            <p>
              <NbspLink
                target="_blank"
                href={`https://images.genesis.game/claims/jpg/${downloadName()}.jpg`}
                rel="noopener noreferrer"
              >
                {t('claims.description.jpg', {
                  worldname: name,
                })}
              </NbspLink>
            </p>
            <p>
              <NbspLink
                target="_blank"
                href={`https://images.genesis.game/claims/gif/${downloadName()}.gif`}
                rel="noopener noreferrer"
              >
                {t('claims.description.gif', {
                  worldname: name,
                })}
              </NbspLink>
            </p>
          </InnerContainer>
        )}
      </ContentContainer>
      {isMobile ? (
        <MemoSimpleClaimGrid
          claims={globalClaims}
          layout={StoryblokWorldGridLayout.smallGrid}
          prices={prices}
        />
      ) : (
        imageContainer
      )}
      {InfoModal}
      {SellModal}
    </WorldSingleStyle>
  );
}
