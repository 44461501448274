import React from 'react';

export interface WorldTags {
  hub: boolean;
  magic: number;
  tech: number;
}

export enum WorldSize {
  s = 0,
  m = 1,
  l = 2,
  xl = 3,
}

export interface World {
  art: {
    icon: string;
    key: string;
    other: string[];
    portal: string;
  };
  description: string;
  featured: number;
  id: number;
  name: string;
  s: WorldSize;
  x: number;
  y: number;
  tags: WorldTags;
}

const imageSize = 180; //Math.floor(cellSize - 20);
export const worldPlaceholderImage = `https://picsum.photos/id/1/${imageSize}`;

export function getMarketingImageUrl(image: string) {
  return `https://d2k0zlbx2uwvq8.cloudfront.net/marketing/${image}`;
}
export function getArtImageUrl(image: string) {
  return `https://d2k0zlbx2uwvq8.cloudfront.net/art/${image}`;
}
export function getLandImageUrl(image: string) {
  return `https://d2k0zlbx2uwvq8.cloudfront.net/land/${image}`;
}

export function getBackgroundImageStyle(image: string) {
  const backgroundImage: React.CSSProperties = {
    backgroundImage: getMarketingImageUrl(image),
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  };
  return backgroundImage;
}

export function generateWorldTags(world: World) {
  const { s, x, y, tags } = world;
  const { hub, magic, tech } = tags;
  const sizeTag = s.toString();
  const locationTag = ` | (${x},${y})`;
  const hubTag = hub ? ' | hub' : '';
  const magicTag =
    magic <= 1 ? ' | low magic' : magic >= 4 ? ' | high magic' : '';
  const techTag = tech <= 1 ? ' | low tech' : tech >= 4 ? ' | high tech' : '';
  return (
    <p>
      {sizeTag}
      {locationTag}
      {hubTag}
      {magicTag}
      {techTag}
    </p>
  );
}
