import { call, put, takeLatest } from 'redux-saga/effects';
import { multicallAllData } from 'src/contracts/multicall';

import { RELOAD_ACCOUNT_DATA, CONNECT_ACCOUNT_SUCCESS } from '../ActionTypes';
import { reloadAccountDataResult } from './Actions';

export function* setAddressSaga() {
  yield takeLatest(CONNECT_ACCOUNT_SUCCESS, reloadAccountData);
}

export function* reloadAccountDataSaga() {
  yield takeLatest(RELOAD_ACCOUNT_DATA, reloadAccountData);
}

function* reloadAccountData() {
  try {
    const payload = yield call(multicallAllData);
    yield put(reloadAccountDataResult(payload));
  } catch (e) {
    console.log('getMulticallData: caught rpc error', e);
    // yield put(GetBondedActions.updateMulticallDataError(e));
    // yield call(delay, 3);
    // yield put(GetBondedActions.updateMulticallDataAction());
  }
}
