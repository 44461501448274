// @flow

import {
  GET_STORIES,
  GET_STORIES_SUCCESS,
  GET_STORIES_ERROR,
  SET_STORIES_LANGUAGE,
  REFRESH_CLAIMS_ACTION,
} from '../ActionTypes';
import { createReducer } from '../CreateReducer';
import { ClaimStoryblok, StoryStoryblok } from 'src/typings';

export type StoriesState = {
  stories: { [slug: string]: StoryStoryblok };
  claims: { [id: number]: ClaimStoryblok };
  isFetching: boolean;
  error: string;
  lang: string;
};

const INITIAL_STATE = {
  stories: {},
  claims: {},
  isFetching: false,
  error: null,
  lang: null,
};

const reducers = {
  [SET_STORIES_LANGUAGE]: (state: StoriesState, { lang }) => {
    state.lang = lang;
    return state;
  },
  [GET_STORIES]: (state: StoriesState) => {
    state.isFetching = true;
    return state;
  },
  [GET_STORIES_SUCCESS]: (
    state: StoriesState,
    {
      data,
    }: {
      data: {
        stories: StoryStoryblok[];
        claims: ClaimStoryblok[];
      };
    },
  ) => {
    const stories = {};
    (data.stories as StoryStoryblok[]).map((story) => {
      stories[story.full_slug] = story;
    });
    state.stories = stories;
    const claims = {};
    (data.claims as ClaimStoryblok[]).map((claim) => {
      claims[claim.id] = claim;
    });
    state.claims = claims;
    state.isFetching = false;
    return state;
  },
  [GET_STORIES_ERROR]: (state: StoriesState, { error }) => {
    state.stories = {};
    state.isFetching = false;
    state.error = error;
    return state;
  },
  [REFRESH_CLAIMS_ACTION]: (state: StoriesState) => {
    state.claims = { ...state.claims };
    return state;
  },
};

export const reducer = createReducer(INITIAL_STATE, reducers);

export default reducer;
