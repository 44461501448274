/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';

import { StoryblokWorldGridLayout } from 'src/typings';
import { GridContainer } from './style';
import { AllClaimData, MiningClaims } from 'src/redux/Account/Reducer';
import { MiningClaimCard } from '.';
import { USDPrices } from 'src/redux/Contracts/Reducer';

export const SimpleClaimGrid = (props: {
  claims: AllClaimData;
  claimBalances?: MiningClaims;
  layout: StoryblokWorldGridLayout;
  prices: USDPrices;
}) => {
  const { claims, claimBalances, layout, prices } = props;
  return (
    <GridContainer>
      {Object.keys(claimBalances || claims).map((key) => {
        const item = claims[key];
        const showItem =
          !!item && (!claimBalances ? true : claimBalances[key] > 0);
        return showItem ? (
          <MiningClaimCard
            key={item.id}
            layout={layout}
            prices={prices}
            item={item}
          />
        ) : null;
      })}
    </GridContainer>
  );
};

// We memoize the grid because we get a full set of claimdata, and we don't use the detailed data to render this
export const MemoSimpleClaimGrid = React.memo(
  SimpleClaimGrid,
  (prevProps: AllClaimData, nextProps: AllClaimData) => {
    return !prevProps[1] && !!nextProps[1];
  },
);
