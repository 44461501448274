import { store } from 'src';
import { sendTransaction } from './sendTransaction';
import {
  TransactionData,
  TransactionStatus,
  TransactionType,
} from './transactionTypes';

// Assumption here is that all amount checks have already taken place
export async function genesisWithdrawTx() {
  const { Contracts } = store.getState();
  const { governance } = Contracts.contracts;
  if (!governance) {
    throw 'Governance WithdrawTx store failure: no genesis  contract';
  }

  function callback(tx: TransactionData<[]>) {
    console.log('Governance WithdrawTx success', tx.receipt);
  }

  function onError(error: unknown, tx: TransactionData<[]>) {
    console.log('Governance WithdrawTx error', error, tx.receipt);
  }

  console.log('Governance WithdrawTx starting contract call');
  const transaction: TransactionData<[]> = {
    type: TransactionType.GenesisWithdrawal,
    status: TransactionStatus.created,
    function: governance.withdrawAll,
    params: [],
  };
  // The contract call via metamask
  try {
    console.log('Governance WithdrawTx sending tx', transaction);
    sendTransaction(transaction, callback, onError);
  } catch (error) {
    console.log(error);
  }
}
