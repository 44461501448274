import React, { useEffect } from 'react';
import { VerticalSpacer } from 'src/components';
import { theme } from 'src/theme';
import styled from 'styled-components';
import { color, space } from 'styled-system';
import farmImage from 'src/img/tiles/farmCamelot.png';
import buildImage from 'src/img/tiles/build.png';
import playImage from 'src/img/tiles/play.png';
import mineImage from 'src/img/tiles/mineOz.png';
import bankImage from 'src/img/tiles/bank.png';
import { GameCurrency, GenesisCurrency } from 'src/util';
import { Routes } from 'src/constants/routes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface HighlightProps {
  title: string;
  image: string;
  link: string;
  content: JSX.Element;
  tag?: boolean;
}

/*
  If a piece comes on screen, show it.
*/

interface HighlightSectionProps {
  highlights: JSX.Element;
}

export function HighlightArea(props: HighlightSectionProps) {
  const { highlights } = props;
  const animatedElements = [];
  const chainContents = [];
  let chain = Promise.resolve();

  function check(e: Element) {
    return new Promise<void>((res) => {
      setTimeout(() => {
        if (animatedElements.includes(e)) {
          e.classList.add('fadeIn');
        } else {
          e.classList.remove('fadeIn');
        }

        const location = chainContents.indexOf(e);
        if (location >= 0) {
          chainContents.splice(location, 1);
        }
        res();
      }, 300);
    });
  }

  const callback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        const elem = entry.target;
        if (!animatedElements.includes(elem)) {
          animatedElements.push(elem);
          const isInChain = chainContents.indexOf(elem) >= 0;
          if (!isInChain) {
            chainContents.push(elem);
            chain = chain.then(() => check(elem));
          }
        }
      } else {
        const elem = entry.target;
        const location = animatedElements.indexOf(elem);
        if (location >= 0) {
          animatedElements.splice(location, 1);
          const isInChain = chainContents.indexOf(elem) >= 0;
          if (!isInChain) {
            chainContents.push(elem);
            chain = chain.then(() => check(elem));
          }
        }
      }
    });
  };
  const options = {
    root: null,
    threshold: 0.25,
    delay: 0,
  };

  const myObserver = new IntersectionObserver(callback, options);
  useEffect(() => {
    const psa = document.querySelectorAll('.highlight-fade');
    if (psa) {
      psa.forEach((entry) => {
        myObserver.observe(entry);
      });
    }
  }, []);

  return (
    <>
      <VerticalSpacer />
      {highlights}
    </>
  );
}

function Highlight(props: HighlightProps) {
  const { title, tag, link, image, content } = props;
  const { t } = useTranslation();
  return (
    <HighlightStyle to={`/${link}`}>
      {tag && (
        <HighlightTagHolder>
          <HighlightTag>
            <h6>{t('highlight.future')}</h6>
          </HighlightTag>
        </HighlightTagHolder>
      )}
      <HighlightTitle>{title}</HighlightTitle>
      <HighlightImage src={image} />
      <HighlightPadding>{content}</HighlightPadding>
    </HighlightStyle>
  );
}

const HighlightAreaStyle = styled.div(
  () => ({
    margin: `${theme.spacing.mobileDouble} auto 0`,
    width: 'fit-content',
    maxWidth: '960px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  }),
  space,
  color,
);

const HighlightStyle = styled(Link).attrs(() => ({
  className: 'highlight-fade',
}))(
  () => ({
    boxSizing: 'border-box',
    padding: 0,
    margin: `${theme.spacing.mobileSingle} ${theme.spacing.mobileSingle} ${theme.spacing.mobileDouble}`,
    width: '288px',
    height: '360px',
    border: `2px solid ${theme.colors.purple}`,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    opacity: 0,
    transition: 'opacity 1s ease-in-out, transform .3s',
    transformOrigin: 'top center',
    cursor: 'pointer',
    ':hover': {
      transform: 'scale(1.05)',
      textDecoration: 'none',
    },
  }),
  space,
  color,
);

const HighlightPadding = styled.div(
  () => ({
    padding: `0 ${theme.spacing.mobileSingle} ${theme.spacing.mobileDouble}`,
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  }),
  space,
  color,
);

const HighlightTagHolder = styled.div(
  () => ({
    width: '100%',
    height: '100%',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    position: 'absolute',
    overflow: 'hidden',
  }),
  space,
  color,
);
const HighlightTag = styled.div(
  () => ({
    padding: '4px',
    width: '160px',
    height: '30px',
    top: '15px',
    right: '-50px',
    transform: 'rotate(45deg)',
    backgroundColor: theme.colors.purple,
    color: theme.colors.white,
    position: 'absolute',
  }),
  space,
  color,
);

const HighlightTitle = styled.h3(
  () => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: '-21px',
    background: theme.colors.white,
    margin: 'auto',
    width: '121px',
    color: theme.colors.purple,
    fontWeight: 700,
  }),
  space,
  color,
);

export const HighlightImage = styled.img(
  () => ({
    width: '280px',
    height: '280px',
    margin: `0 auto`,
  }),
  space,
  color,
);
const HighlightText = styled.p(
  () => ({
    maxWidth: '100%',
    width: '400px',
    fontWeight: 500,
    textAlign: 'center',
    // fontSize: theme.fontSizes[2],
    color: theme.colors.black,
    margin: 0,
  }),
  space,
  color,
);

export const MainHighlights = (
  <HighlightAreaStyle>
    <Highlight
      title={'EARN'}
      image={farmImage}
      link={Routes.earn}
      content={
        <>
          <HighlightText>
            Farm, Mine, and Stake{<br />}
            {GameCurrency} and {GenesisCurrency}
          </HighlightText>
        </>
      }
    />
    <Highlight
      title={'Build'}
      tag
      image={buildImage}
      link={Routes.metaverse}
      content={
        <>
          <HighlightText>
            Build on your land to earn passive income
          </HighlightText>
        </>
      }
    />
    <Highlight
      title={'Play'}
      tag
      image={playImage}
      link={Routes.metaverse}
      content={
        <>
          <HighlightText>
            Explore the world, defeat monsters, and take their loot.
          </HighlightText>
        </>
      }
    />
  </HighlightAreaStyle>
);

export const EarnHighlights = (
  <HighlightAreaStyle>
    <Highlight
      title={'FARM'}
      image={farmImage}
      link={Routes.farming}
      content={
        <>
          <HighlightText>
            Stake your {GameCurrency} in the farm to earn {GenesisCurrency}
          </HighlightText>
        </>
      }
    />
    <Highlight
      title={'MINE'}
      image={mineImage}
      link={Routes.mining}
      content={
        <>
          <HighlightText>
            Mining claims earn you {GenesisCurrency} and give you a guaranteed
            Land
          </HighlightText>
        </>
      }
    />
    <Highlight
      title={'Stake'}
      image={bankImage}
      link={Routes.staking}
      content={
        <>
          <HighlightText>
            Stake your {GenesisCurrency} to earn auto-compounded rewards and
            governance rights
          </HighlightText>
        </>
      }
    />
  </HighlightAreaStyle>
);

/*

import craftImage from 'src/img/tiles/craft.png';
import mineImage from 'src/img/tiles/mineBlock.png';
import bankImage from 'src/img/tiles/mineOz.png';

      <HighlightAreaStyle>
        <Highlight
          title={'FARM'}
          image={farmImage}
          link={Routes.farming}
          content={
            <>
              <HighlightText>
                Stake your {gameCurrency} in the farm to earn {genesisCurrency}
              </HighlightText>
            </>
          }
        />
        <Highlight
          title={'MINE'}
          image={mineImage}
          link={Routes.mining}
          content={
            <>
              <HighlightText>
                Mining claims earn you {genesisCurrency} and give you a
                guaranteed Land
              </HighlightText>
            </>
          }
        />
        <Highlight
          title={'Stake'}
          image={bankImage}
          link={Routes.farming}
          content={
            <>
              <HighlightText>
                Stake your {genesisCurrency} to earn auto-compounded rewards and
                governance rights
              </HighlightText>
            </>
          }
        />
        <Highlight
          title={'Build'}
          tag
          image={buildImage}
          link={Routes.metaverse}
          content={
            <>
              <HighlightText>
                Add buildings to your land to earn passive income
              </HighlightText>
            </>
          }
        />
        <Highlight
          title={'Play'}
          tag
          image={playImage}
          link={Routes.farming}
          content={
            <>
              <HighlightText>
                Buy your Character, then roam the world to complete quests,
                defeat monsters, and take their treasure.
              </HighlightText>
            </>
          }
        />
        <Highlight
          title={'Craft'}
          tag
          image={craftImage}
          link={Routes.farming}
          content={
            <>
              <HighlightText>
                Craft new items, then sell them for {genesisCurrency}
              </HighlightText>
            </>
          }
        />
      </HighlightAreaStyle>
*/
