import React from 'react';
import { useTranslation } from 'react-i18next';
import { ModalBase, ModalProps, ModalHeader, ModalBody } from '.';

export function ConnectingModal(props: ModalProps) {
  const { t } = useTranslation();
  const { visible, show } = props;

  function onShowHide(isShowing: boolean) {
    show(isShowing);
  }

  return (
    <ModalBase visible={visible} show={onShowHide}>
      <>
        <ModalHeader>
          <h3>{t('modal.connecting.title')}</h3>
        </ModalHeader>
        <ModalBody>
          <p>{t('modal.connecting.text')}</p>
          <p>
            {t('modal.connecting.getMetamask')}{' '}
            <a
              href="https://metamask.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              metamask.io
            </a>
          </p>
        </ModalBody>
      </>
    </ModalBase>
  );
}
