import styled from 'styled-components';
import {
  space,
  color,
  flex,
  FlexProps,
  ColorProps,
  SpaceProps,
} from 'styled-system';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { MobileProps, MenuOpenProps } from '..';
import { theme } from 'src/theme';
import { desktopHeaderHeight, Media, mobileHeaderHeight } from 'src/constants';
import { Link } from 'react-router-dom';

type WrapperProps = SpaceProps & ColorProps;

export const HeaderStyle = styled.header<WrapperProps>(
  () => ({
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 1px 5px',
    height: mobileHeaderHeight,
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid transparent',
    background: theme.colors.white,
    overflow: 'overlay',
    top: 0,
    left: 0,
    right: 0,
    position: 'fixed',
    zIndex: 900,
    [Media.desktop]: {
      height: desktopHeaderHeight,
    },
  }),
  space,
  color,
);

export const HeaderBackground = styled.div<WrapperProps>(
  () => ({
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: theme.colors.white,
    zIndex: 901,
  }),
  space,
  color,
);

export const MenuBody = styled.div<WrapperProps>(() => ({
  position: 'absolute',
  right: 0,
  zIndex: 100,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.colors.white,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.4)',
  width: '100%',
  [Media.tablet]: {
    width: 'fit-content',
  },
}));

type RowProps = FlexProps & MobileProps;

export const Row = styled.div<RowProps>(
  (props) => ({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    zIndex: 902,
    background: theme.colors.white,
    padding: props.$mobile ? '0' : '0 16px',
  }),
  flex,
);

export const LogoImage = styled.img<MobileProps>((props) => ({
  width: props.$mobile ? '34px' : '50px',
  height: props.$mobile ? '34px' : '50px',
  margin: '0 8px',
  cursor: 'pointer',
  outline: 'none',
}));

export const InputContainer = styled.div({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.white}`,
  borderRadius: '5px',
  color: theme.colors.darkGrey,
  height: '45px',
  padding: '0 0 0 10px',
  margin: '0',
  width: '40%',
  marginLeft: 'auto',
});

export const SearchIcon = styled.img<MobileProps>((props) => ({
  width: props.$mobile ? '48px' : '48px',
  height: '48px',
}));

export const UpIcon = styled(FiChevronUp)({
  width: '24px',
  height: '24px',
});

export const DownIcon = styled(FiChevronDown)({
  width: '24px',
  height: '24px',
});

export const AccountIconSVG = styled.svg<MenuOptionProps>((props) => ({
  width: '48px',
  fill: props.$isActive ? theme.colors.blue : theme.colors.darkGrey,
  ':hover': {
    fill: theme.colors.blue,
  },
}));

export const MenuIconSVG = styled.svg<MenuOpenProps>(() => ({
  width: '48px',
  fill: theme.colors.darkGrey,
  ':hover': {
    fill: theme.colors.blue,
  },
}));

export const SearchField = styled.input({
  flex: 1,
  cursor: 'text',
  letterSpacing: 'normal',
  wordSpacing: 'normal',
  textTransform: 'none',
  textIndent: '0',
  textShadow: 'none',
  display: 'inline-block',
  textAlign: 'start',
  appearance: 'auto',
  backgroundColor: theme.colors.transparent,
  color: theme.colors.darkGrey,
  border: 'none',
  marginLeft: '8px',
  outline: 'none',
});

export const ButtonContainer = styled.div({
  display: 'flex',
  flexFlow: 'row wrap',
  overflow: 'hidden',
  height: '48px',
  margin: '0',
});

type DesktopButtonProps = MobileProps &
  MenuOpenProps & {
    $isActive: boolean;
    $isButton: boolean;
    $isRight?: boolean;
  };

export const RightHandSide = styled.div({
  marginLeft: 'auto',
  display: 'flex',
  flexDirection: 'row',
});

export const DesktopButton = styled(Link)<DesktopButtonProps>((props) => ({
  display: 'flex',
  height: '48px',
  cursor: 'pointer',
  lineHeight: '48px',
  color: props.$isActive ? theme.colors.blue : theme.colors.darkGrey,
  textDecoration: props.$isActive && 'underline',
  padding: props.$isButton ? '16px 0' : props.$mobile ? '16px 8px' : '16px',
  alignItems: 'center',
  position: 'relative',
  marginLeft: 0,
  textUnderlineOffset: '8px',
  textAlign: 'center',
  fontWeight: props.$isActive
    ? theme.fontWeights.bold
    : theme.fontWeights.regular,
  textTransform: 'uppercase',
  fontFamily: 'Montserrat',
  fontSize: theme.fontSizes[0],
  [Media.desktop]: {
    fontSize: theme.fontSizes[1],
  },
  ':link': {
    textDecoration: 'none',
  },
  ':visited': {
    textDecoration: 'none',
  },
  ':hover': {
    textDecoration: 'underline',
    color: theme.colors.blue,
  },
}));

export const DesktopMenuButton = styled.h6<DesktopButtonProps>((props) => ({
  display: 'flex',
  height: '48px',
  cursor: 'pointer',
  lineHeight: '40px',
  fontWeight: props.$isActive
    ? theme.fontWeights.bold
    : theme.fontWeights.regular,
  color: props.$isActive && theme.colors.blue,
  textDecoration: props.$isActive && 'underline',
  padding: props.$isButton ? '16px 0' : props.$mobile ? '16px 4px' : '16px',
  alignItems: 'center',
  position: 'relative',
  marginLeft: 0,
  textUnderlineOffset: '8px',
  ':hover': {
    textDecoration: 'underline',
    color: theme.colors.blue,
  },
}));

interface MenuOptionProps {
  $isMobile: boolean;
  $isActive: boolean;
  $isSubMenu?: boolean;
  $first?: boolean;
  $last?: boolean;
}

interface MenuOptionCaretProps {
  $isMobile: boolean;
  $isActive: boolean;
}

interface MenuOptionContainerProps {
  $first?: boolean;
  $last?: boolean;
}

export const MenuButton = styled.div<MenuOptionProps>((props) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  margin: props.$isMobile ? 'auto' : '0',
  padding: '0 24px',
}));

export const MenuOptionExternal = styled.a<MenuOptionProps>((props) => ({
  height: props.$first ? '72px' : props.$last ? '80px' : '40px',
  padding: '0 24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: theme.fontSizes[1],
  fontWeight: props.$isActive
    ? theme.fontWeights.bold
    : theme.fontWeights.regular,
  cursor: 'pointer',
  color: props.$isActive ? theme.colors.blue : theme.colors.darkGrey,
  paddingTop: props.$first && !props.$isMobile ? '24px' : '0',
  paddingBottom: props.$last ? '40px' : '0',
  margin: '0',
  textUnderlineOffset: '8px',
  textAlign: 'center',
  textTransform: 'uppercase',
  fontFamily: 'Montserrat',
  ':link': {
    textDecoration: 'none',
  },
  ':visited': {
    textDecoration: 'none',
  },
  ':hover': {
    textDecoration: 'underline',
    color: theme.colors.blue,
  },
}));

export const MenuRow = styled.div<MenuOptionContainerProps>(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

export const MenuOption = styled.div<MenuOptionContainerProps>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: theme.fontSizes[1],
  cursor: 'pointer',
  paddingTop: props.$first ? '24px' : '0',
  paddingBottom: props.$last ? '40px' : '0',
  textUnderlineOffset: '8px',
  textAlign: 'center',
  textTransform: 'uppercase',
  fontFamily: 'Montserrat',
}));

export const MenuOptionCaret = styled.div<MenuOptionCaretProps>((props) => ({
  height: '40px',
  padding: '0 24px',
  display: 'flex',
  alignItems: 'center',
  fontWeight: props.$isActive
    ? theme.fontWeights.bold
    : theme.fontWeights.regular,
  color: props.$isActive ? theme.colors.blue : theme.colors.darkGrey,
  margin: '0',
  textDecoration: 'none',
}));

export const MenuOptionContent = styled(Link)<MenuOptionProps>((props) => ({
  height: '40px',
  width: props.$isSubMenu ? 'unset' : '100%',
  padding: '0 24px',
  display: 'flex',
  alignItems: 'center',
  fontWeight: props.$isActive
    ? theme.fontWeights.bold
    : theme.fontWeights.regular,
  color: props.$isActive ? theme.colors.blue : theme.colors.darkGrey,
  margin: '0',
  ':link': {
    textDecoration: 'none',
  },
  ':visited': {
    textDecoration: 'none',
  },
  ':hover': {
    textDecoration: 'underline',
    color: theme.colors.blue,
  },
}));

export const MenuSubOptionContent = styled(Link)<MenuOptionProps>((props) => ({
  height: '40px',
  width: props.$isSubMenu ? 'unset' : '100%',
  display: 'flex',
  alignItems: 'center',
  fontSize: theme.fontSizes[0],
  fontWeight: props.$isActive
    ? theme.fontWeights.bold
    : theme.fontWeights.regular,
  color: props.$isActive ? theme.colors.blue : theme.colors.darkGrey,
  padding: '0 40px',
  ':link': {
    textDecoration: 'none',
  },
  ':visited': {
    textDecoration: 'none',
  },
  ':hover': {
    textDecoration: 'underline',
    color: theme.colors.blue,
  },
}));

type MobileMenuProps = {
  expanded: boolean;
};

export const MobileMenu = styled.div<MobileMenuProps>((props) => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  zIndex: 899,
  top: '48px',
  left: 0,
  width: '100vw',
  height: '100%',
  transition: 'transform 0.3s ease-in-out',
  transform: props.expanded ? 'translateY(0)' : 'translateY(-150%)',
}));

type BlurDivProps = {
  expanded: boolean;
};

export const MenuBackground = styled.div`
  width: 100%;
  height: 100%;
`;

export const BlurDiv = styled.div<BlurDivProps>`
  position: fixed;
  visibility: ${(props) => (props.expanded ? '' : 'hidden')};
  display: block;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(196, 196, 196, 0.01);
  backdrop-filter: blur(3px) brightness(0.8);
`;

export const StyledLi = styled.li`
  float: left;
`;

export const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

export const DropDownLi = styled(StyledLi)`
  display: inline-block;
  width: unset;
  &:hover {
    background-color: ${theme.colors.white};
  }
  @media (hover: hover) {
    &:hover ${DropDownContent} {
      display: block;
    }
  }
`;

export const SubA = styled.a`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  &:hover {
    background-color: ${theme.colors.lightGrey};
    cursor: pointer;
  }
`;

export const SubLink = styled(Link)<DesktopButtonProps>((props) => ({
  display: 'flex',
  height: '40px',
  cursor: 'pointer',
  lineHeight: '30px',
  color: props.$isActive ? theme.colors.blue : theme.colors.darkGrey,
  textDecoration: props.$isActive && 'underline',
  padding: '12px 16px',
  alignItems: 'center',
  position: 'relative',
  marginLeft: 0,
  textUnderlineOffset: '8px',
  textAlign: 'center',
  fontWeight: props.$isActive
    ? theme.fontWeights.bold
    : theme.fontWeights.regular,
  textTransform: 'uppercase',
  fontFamily: 'Montserrat',
  fontSize: theme.fontSizes[0],
  ':link': {
    textDecoration: 'none',
  },
  ':visited': {
    textDecoration: 'none',
  },
  ':hover': {
    textDecoration: 'underline',
    color: theme.colors.blue,
  },
}));
