import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import { Media } from 'src/constants';
import { theme } from 'src/theme';

type WrapperProps = SpaceProps & ColorProps;

export const FunctionalSectionStyle = styled.section<WrapperProps>(
  () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [Media.desktop]: {
      flexDirection: 'row',
    },
    margin: 'auto',
    maxWidth: theme.spacing.maxContentWidth,
  }),
  space,
  color,
);

export const TextSectionStyle = styled.div<WrapperProps>(
  () => {
    return {
      padding: theme.spacing.mobileSingle,
      [Media.desktop]: {
        padding: theme.spacing.desktopSingle,
      },
      margin: 'auto',
      maxWidth: theme.spacing.maxContentWidth,
    };
  },
  space,
  color,
);
