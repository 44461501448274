import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ModalBase, ModalProps, ModalHeader, ModalBody } from '.';
import { AppState } from 'src/redux/CreateStore';
import {
  AnyTransaction,
  TransactionStatus,
} from 'src/transactions/transactionTypes';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { InnerDivFirst, InnerDivSecond, InnerDivThird } from '../loading/style';
import { VerticalSpacer } from '..';
import { signingSvg } from 'src/img/signingSvg';
import { successSvg } from 'src/img/successSvg';
import { failureSvg } from 'src/img/failureSvg';
import { useWindowSize } from 'src/hooks/useWindowSize';

type TransactionStatusRowProps = {
  id: string;
  tx: AnyTransaction;
  chars: number;
};

function TransactionStatusRow(props: TransactionStatusRowProps) {
  const { tx, chars } = props;
  if (!tx) {
    return null;
  }
  const { t } = useTranslation();
  return (
    <TxRowStyle>
      <TxRowMain>
        {tx.type}: {t(`txStatus.${TransactionStatus[tx.status]}`)}
      </TxRowMain>
      {tx.response?.hash && (
        <TxRowHash
          href={`https://polygonscan.com/tx/${tx.response.hash}`}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          {tx.response.hash.slice(0, chars)}...
        </TxRowHash>
      )}
    </TxRowStyle>
  );
}

function TransactionStatusWidget(props: TransactionStatusRowProps) {
  const { tx } = props;
  const { t } = useTranslation();
  switch (tx?.status) {
    case TransactionStatus.signing:
      return (
        <>
          <VerticalSpacer />
          <Alert>{signingSvg()}</Alert>
          <VerticalSpacer />
          <p>{t('modal.txStatus.waitingForSignature')}</p>
        </>
      );
    case TransactionStatus.pending:
      return (
        <>
          <VerticalSpacer />
          <Spinner>
            <InnerDivFirst />
            <InnerDivSecond />
            <InnerDivThird />
          </Spinner>
          <VerticalSpacer />
          <p>{t('modal.txStatus.pending')}</p>
        </>
      );
    case TransactionStatus.success:
      return (
        <>
          <VerticalSpacer />
          <Alert>{successSvg()}</Alert>
          <VerticalSpacer />
          <p>{t('modal.txStatus.success')}</p>
        </>
      );
    case TransactionStatus.failed:
      return (
        <>
          <VerticalSpacer />
          <Alert>{failureSvg()}</Alert>
          <VerticalSpacer />
          <p>{t('modal.txStatus.failed')}</p>
        </>
      );
    default:
      return null;
  }
}

export function TransactionStatusModal(props: ModalProps) {
  const { windowSize } = useWindowSize();
  const { t } = useTranslation();
  const { visible, show } = props;
  const transactions = useSelector(
    (state: AppState) => state.Contracts.transactions,
  );
  const mostRecentTx = useSelector(
    (state: AppState) => state.Contracts.mostRecentTx,
  );
  const txCount = Object.keys(transactions).length;

  function onShowHide(isShowing: boolean) {
    show(isShowing);
  }

  useEffect(() => {
    if (Object.keys(transactions).length > 0) {
      show(true);
    } else {
      show(false);
    }
  }, [transactions]);

  let title = 'default';
  switch (mostRecentTx?.status) {
    case TransactionStatus.signing:
      title = 'signing';
      break;
    case TransactionStatus.pending:
      title = 'pending';
      break;
    case TransactionStatus.success:
      title = 'success';
      break;
    case TransactionStatus.failed:
      title = 'failed';
      break;
    default:
      break;
  }

  // This is ok with the current font choices, but we may want to upgrade to:
  //   https://github.com/pablosichert/react-truncate
  const chars = Math.max(
    8,
    Math.min(32, Math.floor((windowSize.width - 250) / 12)),
  );

  const txDataMap = Object.keys(transactions).map((k) => (
    <TransactionStatusRow key={k} id={k} tx={transactions[k]} chars={chars} />
  ));

  return (
    <ModalBase visible={visible} show={onShowHide}>
      <>
        <ModalHeader>
          <h3>{t(`modal.txStatus.title.${title}`)}</h3>
        </ModalHeader>
        <ModalBody>
          {mostRecentTx && (
            <TransactionStatusWidget
              id={mostRecentTx.response?.hash || mostRecentTx.type}
              tx={mostRecentTx}
              chars={chars}
            />
          )}
          <TxRowStyle>
            <TxRowMain>
              <b>
                {`${txCount} ${
                  txCount === 1
                    ? t('modal.txStatus.transaction')
                    : t('modal.txStatus.transactions')
                }`}
              </b>
            </TxRowMain>
            {txCount > 0 && <p>{t('modal.txStatus.polygonscan')}</p>}
          </TxRowStyle>
          {txDataMap}
        </ModalBody>
      </>
    </ModalBase>
  );
}

const TxRowStyle = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const TxRowMain = styled.p({});

const TxRowHash = styled.a({
  '::after': {
    content:
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==)',
    margin: '0px 3px 0px 5px',
  },
});

const Spinner = styled.div({
  margin: 'auto',
  width: '100px',
  height: '100px',
  '-webkit-perspective': '780px',
  perspective: '780px',
});

const Alert = styled.div({
  margin: 'auto',
  width: '100px',
  height: '100px',
});
