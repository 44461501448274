import React, { useEffect, useState } from 'react';
import { CookiesProvider } from 'react-cookie';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { AppRouter } from './router';

import { FilterContext, MetamaskContext } from './contexts';
import { useMetamask } from './hooks/useMetamask';
import { getStoriesAction } from 'src/redux/Stories/Actions';
import { connectCachedWallet } from 'src/util';
import GlobalStyle from './style';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { TransactionStatusModal } from './components/modals/TransactionStatusModal';
import useModal from './hooks/useModal';
import { BottomConsole } from 'src/components/bottomConsole/index';
import { DataManager } from './components/dataManager';
import { Cron } from './components/cron';
import { isHeadless } from './redux';

export const App: React.FC = () => {
  const [showMobileFilter, setShowMobileFilter] = useState<boolean>(false);
  const { walletAddr, connectWallet, updateWalletAddr } = useMetamask();
  const dispatch = useDispatch();

  const updateMobileFilterVisibility = (val) => {
    setShowMobileFilter(val);
  };

  useEffect(() => {
    dispatch(getStoriesAction());
  }, []);

  useEffect(() => {
    if (showMobileFilter) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    } else {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
    }
  }, [showMobileFilter]);

  useEffect(() => {
    // Only connect to ethereum if we're not running in headless mode (using react-snap)
    if (!isHeadless) {
      connectCachedWallet();
    }
  }, []);

  const { visible: txVisible, show: txShow } = useModal();

  // Only rended the datamanger, console, and cron elements if we're not running in headless mode (using react-snap)
  return (
    <CookiesProvider>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        {!isHeadless && <DataManager />}
        <BrowserRouter>
          <FilterContext.Provider
            value={{
              visible: showMobileFilter,
              toggleVisible: updateMobileFilterVisibility,
            }}
          >
            <MetamaskContext.Provider
              value={{
                walletAddr,
                updateWalletAddr,
                connectWallet,
              }}
            >
              <AppRouter />
              {!isHeadless && <BottomConsole />}
              {!isHeadless && <Cron />}
            </MetamaskContext.Provider>
          </FilterContext.Provider>

          <TransactionStatusModal visible={txVisible} show={txShow} />
        </BrowserRouter>
      </ThemeProvider>
    </CookiesProvider>
  );
};
