import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers';
import ReactGA from 'react-ga';
import Web3Modal from 'web3modal';
import {
  connectAccountError,
  connectAccountSuccessAction,
  disconnectAccountAction,
} from 'src/redux/Account/Actions';
import { loadContracts } from 'src/contracts/loadContracts';
import { storeContractsDataAction } from 'src/redux/Contracts/Actions';
import { address } from 'src/interfaces/Common';
import { store } from 'src';
import MetamaskLogo from 'src/img/metamask.png';
import CoinbaseLogo from 'src/img/coinbase-wallet.png';
import { switchToPolygon } from '.';
import { batch } from 'react-redux';

//To do:
/*
  What happens if metamask shows account X, but the one connected is account Y?
*/

export const web3Modal: Web3Modal = new Web3Modal({
  network: 'matic',
  cacheProvider: true,
  disableInjectedProvider: true,
  providerOptions: {
    'custom-metamask': {
      display: {
        logo: MetamaskLogo,
        name: 'MetaMask Wallet',
        description: 'Connect to your MetaMask Wallet',
      },
      package: true,
      connector: async () => {
        let provider = null;
        if (typeof window.ethereum !== 'undefined') {
          const providers = window.ethereum.providers;
          provider = !providers
            ? window.ethereum
            : providers?.find((p) => p.isMetaMask); // <-- LOOK HERE
          if (!provider) {
            window.open(
              'https://metamask.app.link/dapp/genesis.game/',
              '_blank',
            );
          }
          try {
            await provider.request({ method: 'eth_requestAccounts' });
          } catch (error) {
            throw new Error('User Rejected');
          }
        } else {
          window.open('https://metamask.app.link/dapp/genesis.game/', '_blank');
          throw new Error('No MetaMask Wallet found');
        }
        return provider;
      },
    },
    'custom-coinbase': {
      display: {
        logo: CoinbaseLogo,
        name: 'Coinbase Wallet',
        description: 'Connect to your Coinbase Wallet',
      },
      package: true,
      connector: async () => {
        let provider = null;
        if (typeof window.ethereum !== 'undefined') {
          const providers = window.ethereum.providers;
          provider = !providers
            ? window.ethereum
            : providers?.find((p) => p.isWalletLink); // <-- LOOK HERE
          if (!provider && providers.length > 0) {
            provider = providers[0];
          }
          if (!provider) {
            window.open('https://www.coinbase.com/wallet', '_blank');
          }
          try {
            await provider.request({ method: 'eth_requestAccounts' });
          } catch (error) {
            throw new Error('User Rejected');
          }
        } else {
          window.open('https://www.coinbase.com/wallet', '_blank');
          throw new Error('No MetaMask Wallet found');
        }
        return provider;
      },
    },
  },
});

export async function connectCachedWallet() {
  if (web3Modal.cachedProvider) {
    connectWallet();
  }
}

let isListening = false;

export async function connectWallet() {
  try {
    const provider = await web3Modal.connect();
    const ethersProvider = new Web3Provider(provider, 'any');
    const networkInfo = await ethersProvider.getNetwork();
    const signer = ethersProvider.getSigner();

    if (networkInfo?.chainId !== 137 && !(await switchToPolygon(signer))) {
      console.log('ethersProvider network', networkInfo);
      return undefined;
    }

    if (!isListening) {
      ethersProvider.on('network', (newNetwork, oldNetwork) => {
        // When a Provider makes its initial connection, it emits a "network"
        // event with a null oldNetwork along with the newNetwork. So, if the
        // oldNetwork exists, it represents a changing network
        if (newNetwork?.chainId !== 137) {
          console.log(
            'network is not polygon',
            oldNetwork?.chainId,
            newNetwork?.chainId,
          );
        }
      });

      try {
        window?.ethereum?.on('accountsChanged', async () => {
          web3Modal.clearCachedProvider();
          store.dispatch(disconnectAccountAction());
          await connectWallet();
        });
      } catch (e) {
        console.log(
          'window.ethereum not present. Not detecting account changes',
        );
      }
      isListening = true;
    } else {
      console.log('already listening for network and account changes');
    }

    const address = await signer.getAddress();
    const providerData: ProviderData = {
      provider: ethersProvider,
      signer: signer,
      address: address,
    };

    ReactGA.event({
      category: 'Connect Wallet',
      action: 'User connect wallet',
    });

    // Load and store contracts
    const contracts = loadContracts(signer);
    batch(() => {
      store.dispatch(connectAccountSuccessAction(providerData));
      store.dispatch(storeContractsDataAction({ contracts }));
    });

    return providerData;
  } catch (error) {
    console.log('connect account error', error);
    store.dispatch(connectAccountError(error));
    return undefined;
  }
}

export function disconnectWallet() {
  web3Modal.clearCachedProvider();
  store.dispatch(connectAccountError('disconnecting'));
  window.location.reload();
}

export interface ProviderData {
  provider: Web3Provider;
  signer: JsonRpcSigner;
  address: address;
}
