import { StoryblokWorldGridLayout } from 'src/typings';
import styled, { keyframes } from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import { MobileProps } from 'src/components';
import { theme } from 'src/theme';
import { Media } from 'src/constants';

type WrapperProps = SpaceProps &
  ColorProps &
  MobileProps & {
    layout?: StoryblokWorldGridLayout;
    noButton?: boolean;
    delay?: number;
  };

type TextProps = {
  layout?: StoryblokWorldGridLayout;
  isCentered?: boolean;
};

export const WorldGridStyle = styled.section<WrapperProps>(
  () => ({}),
  space,
  color,
);

export const GridStyles = {
  [StoryblokWorldGridLayout.largeGrid]: {
    height: '226px',
    imgSize: '288px',
    fontSize: '22px',
    smallFont: '16px',
    lineHeight: '40px',
    padding: '16px',
    imgWidth: 288,
    imgHeight: 162,
  },
  [StoryblokWorldGridLayout.mediumGrid]: {
    height: '147px',
    imgSize: '162px',
    fontSize: '12px',
    smallFont: '12px',
    padding: '8px',
    imgWidth: 162,
    imgHeight: 91,
  },
  [StoryblokWorldGridLayout.smallGrid]: {
    height: '70px',
    imgSize: '120px',
    fontSize: '10px',
    smallFont: '10px',
    padding: '8px',
    imgWidth: 120,
    imgHeight: 67.5,
  },
};

export const GridContainer = styled.div<WrapperProps>(
  () => ({
    padding: '16px 0',
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
    justifyContent: 'center',
    maxWidth: '1024px',
    margin: 'auto',
  }),
  space,
  color,
);

export const animationDuration = 8;

export const backgroundKeyFrame = keyframes`
	0% {background-position: 70% 100%;}
	20% {background-position: 70% 100%;}
	80% { background-position: 0% 30%; }
	100% { background-position: 0% 30%; }
`;

function getMiningClaimGradient(isForSale: boolean) {
  return `background: linear-gradient(
    -45deg,
    ${isForSale ? theme.colors.blue : theme.colors.midGrey} 0
      40%,
    ${isForSale ? theme.colors.purple : theme.colors.lightGrey},
    ${isForSale ? theme.colors.blue : theme.colors.midGrey}
      80% 100%
  );`;
}

// Removed the animation from these, as it was killing performance on transitions
export const GridItem = styled.div<WrapperProps & { isForSale: boolean }>`
  position: relative;
  ${(props) => getMiningClaimGradient(props.isForSale)};
  background-size: 200%;
  color: ${(props) =>
    props.isForSale ? theme.colors.white : theme.colors.darkGrey};
  cursor: pointer;
  width: ${(props) => GridStyles[props.layout].imgSize};
  flex-direction: column;
  align-items: center;
  margin: ${(props) => GridStyles[props.layout].padding};
  display: flex;
  transition: transform 0.2s;
  :hover {
    transform: scale(1.12);
  }
`;

export const GridImageHolder = styled.div<WrapperProps>(
  (props) => ({
    width: GridStyles[props.layout].imgSize,
    height: GridStyles[props.layout].imgHeight,
    position: 'relative',
  }),
  space,
  color,
);

export const GridImage = styled.img<WrapperProps>(
  (props) => ({
    width: GridStyles[props.layout].imgSize,
    height: GridStyles[props.layout].imgHeight,
    objectFit: 'cover',
  }),
  space,
  color,
);

export const GridCenterText = styled.h5<WrapperProps>(
  (props) => ({
    fontSize: GridStyles[props.layout].fontSize,
    lineHeight: GridStyles[props.layout].lineHeight,
    fontWeight: theme.fontWeights.bold,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
  space,
  color,
);

export const GridItemDetails = styled.div<WrapperProps>(
  (props) => ({
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    paddingBottom: GridStyles[props.layout].padding,
  }),
  space,
  color,
);

export const GridItemPrice = styled.div<TextProps>((props) => ({
  margin: props.isCentered && 'auto',
  textAlign: 'center',
  color: theme.colors.red,
  fontSize: GridStyles[props.layout].fontSize,
}));

export const GridItemDescription = styled.div<TextProps>((props) => ({
  margin: props.isCentered && 'auto',
  textAlign: 'center',
  fontSize: GridStyles[props.layout].smallFont,
  h6: {
    fontSize: GridStyles[props.layout].smallFont,
    lineHeight: GridStyles[props.layout].fontSize,
  },
}));

export const CarouselContainer = styled.div<WrapperProps>(
  () => ({
    padding: '16px 0',
    overflowX: 'scroll',
    display: 'flex',
    flexWrap: 'nowrap',
    maxWidth: '100%',
    margin: 'auto',
    scrollbarWidth: 'none' /* Firefox */,
    ['-ms-overflow-style']: 'none' /* Internet Explorer 10+ */,
    ['::-webkit-scrollbar']: {
      /* WebKit */ width: 0,
      height: 0,
    },
  }),
  space,
  color,
);

export const WorldGridFilterContent = styled.div`
  border: 2px solid ${theme.colors.lightGrey};
  border-top: 2px solid ${theme.colors.blue};
  position: absolute;
  left: -2px;
  right: -2px;
  z-index: 10;
  background: white;
`;

export const WorldGridFilterRow = styled.h6`
  display: flex;
  width: 100%;
  padding: 10px 20px;
  color: ${theme.colors.midGrey};
  cursor: pointer;
  font-weight: 700;

  &:hover {
    border: 2px solid ${theme.colors.blue};
    cursor: pointer;
    width: calc(100% + 4px);
    color: ${theme.colors.blue};
    padding: 10px 20px 8px;
    margin: -2px -2px 0px -2px;
  }
`;

export const DropdownFilterWrapper = styled.div(() => ({
  width: '100%',
  margin: 'auto',

  [Media.desktop]: {
    maxWidth: 665,
  },
  [Media.tablet]: {
    maxWidth: 340,
  },
}));
