import React from 'react';
import { FunctionalSectionType } from 'src/typings';
import { FarmGenesisSection } from '../FarmGenesisSection';
import {
  GameTokenDetailsSection,
  GenesisTokenDetailsSection,
} from '../../cmsdriven/TokenDetailsSection';
import { AccountSection } from '../../cmsdriven/AccountSection';
import { GovernanceStakingSection } from '../GovernanceStakingSection';
import { EarnSection } from '../EarnSection';

interface FunctionalSectionProps {
  type: FunctionalSectionType;
}

export function FunctionalSection(props: FunctionalSectionProps) {
  switch (props.type) {
    case FunctionalSectionType.Farming:
      return <FarmGenesisSection />;
    case FunctionalSectionType.World:
      return <FarmGenesisSection />;
    case FunctionalSectionType.Governance:
      return <GovernanceStakingSection />;
    case FunctionalSectionType.Account:
      return <AccountSection />;
    case FunctionalSectionType.GameTokenDetails:
      return <GameTokenDetailsSection />;
    case FunctionalSectionType.GenesisTokenDetails:
      return <GenesisTokenDetailsSection />;
    case FunctionalSectionType.Earn:
      return <EarnSection />;
    default:
      return <></>;
  }
}
