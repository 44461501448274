import React, { useEffect } from 'react';
import { PageFrame } from '../../page';
import { ClaimSingle } from 'src/components/datadriven';
import { ClaimData } from 'src/constants/worlds';
import { useNavigate, useParams } from 'react-router';
import { AllClaimData } from 'src/redux/Account/Reducer';
import { AppState } from 'src/redux/CreateStore';
import { useSelector } from 'react-redux';
import { Routes } from 'src/constants/routes';
import { HelmetProps } from 'src/components/helmet';

function ClaimPage() {
  const navigate = useNavigate();

  const params = useParams();
  const id = +params.id || -1;
  const claims = useSelector((state: AppState) => state.Stories.claims);
  const data = claims[id];
  const globalClaims: AllClaimData = useSelector(
    (state: AppState) => state.Contracts.globalClaims,
  );
  useEffect(() => {
    if (!data && Object.keys(claims).length > 0) {
      navigate(Routes.mining);
    }
  }, [claims, data]);

  const claim = globalClaims[id] || ({} as ClaimData);

  const helmetProps: HelmetProps = {
    title: data?.name,
    description: data?.description,
    image: data?.image,
    alt: data?.name,
  };

  return (
    <PageFrame loading={!claim?.name} helmet={helmetProps}>
      <ClaimSingle id={id} />
    </PageFrame>
  );
}

export default ClaimPage;
