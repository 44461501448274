import React from 'react';
import { Story } from './Story';
import { useSelector } from 'react-redux';
import {
  PageSectionStoryblok,
  SiteHeaderStoryblok,
  StoryblokComponentType,
  StoryStoryblok,
} from 'src/typings';
import { PageFrame } from '../page';
import { useLocation } from 'react-router-dom';
import { AppState } from 'src/redux/CreateStore';
import { HelmetProps } from '../helmet';

export * from './constants';
export * from './ArtCutout';
export * from './PageSection';
export * from './SiteHeader';
export * from './Story';
export * from './media';

function StoryPage() {
  // get the url slug
  // compare it to the storyblok db
  // if it's there, render it
  const location = useLocation();
  let slug = location.pathname.slice(1);
  slug = slug.endsWith('/') ? slug.slice(0, -1) : slug;
  if (slug === '/' || slug === '') {
    slug = 'home';
  }

  const stories: { [slug: string]: StoryStoryblok } = useSelector(
    (state: AppState) => state.Stories.stories,
  );

  let story = stories[slug];
  if (!story?.content?.body && Object.keys(stories).length > 0) {
    console.log('story not found for slug', slug, stories);
    slug = '404';
    story = stories[slug];
  }

  // Helmet at the page level
  let helmetProps = {} as HelmetProps;
  if (story?.content?.body) {
    const firstHeader: SiteHeaderStoryblok = story.content.body.find(
      (section) =>
        section.component == StoryblokComponentType.SiteHeader &&
        !!section.image?.filename,
    ) as SiteHeaderStoryblok;
    const firstSection: PageSectionStoryblok = story.content.body.find(
      (section) =>
        section.component == StoryblokComponentType.PageSection &&
        !!section.image?.filename,
    ) as PageSectionStoryblok;

    const socialImage = firstHeader?.image || firstSection?.image;
    helmetProps = {
      title: story.content.metaTitle,
      description: story.content.metaDescription,
      image: socialImage?.filename,
      alt: socialImage?.alt,
    };
  }
  // handle 404 page here if not found

  return (
    <PageFrame loading={!story} helmet={helmetProps}>
      {!!story && <Story page={story} />}
    </PageFrame>
  );
}

export default StoryPage;
