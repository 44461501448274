// General purpose units of measure
export type seconds = number;

// Ethereum types and units of measure
export type uint256 = string;
export type address = string;
export type wei = string;
export type ether = string;
export type bytes32 = string;
export type attoGC = string;
export type encodedId = string;
export type bigNumber = string;

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
export const ZERO_BYTES =
  '0x0000000000000000000000000000000000000000000000000000000000000000';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function tryParseJSON(jsonString: string): any {
  try {
    return JSON.parse(jsonString) as { [key: string]: string };
  } catch (e) {
    console.log(e.message);
  }
  return {} as { [key: string]: string };
}
