import { parseEther } from 'ethers/lib/utils';
import { store } from 'src';
import { uint256 } from 'src/interfaces/Common';
import { sendTransaction } from './sendTransaction';
import {
  TransactionData,
  TransactionStatus,
  TransactionType,
} from './transactionTypes';

type buyNftwithMaticTx = TransactionData<
  [tokenId: uint256, path: uint256[], buyAmount: uint256]
>;

// Assumption here is that all amount checks have already taken place
export async function buyNFTWithMaticTx(
  tokenId: string,
  path: string[],
  price: number,
  buyAmount: number,
) {
  const { Contracts } = store.getState();
  const { claimGuard } = Contracts.contracts;
  if (!claimGuard) {
    throw 'buyNFTWithMaticTx store failure: no claimGuard contract ';
  }
  const maticWeiPrice = parseEther(price.toString());
  console.log('buyNFTWithMaticTx tokenId', tokenId);
  console.log('buyNFTWithMaticTx price', price, maticWeiPrice);
  console.log('buyNFTWithMaticTx path', path);
  console.log('buyNFTWithMaticTx buyAmount', buyAmount);

  // the callback on a successful receipt
  async function callback(tx: buyNftwithMaticTx) {
    console.log('buyNFTWithMaticTx callback fn', tx.type);
  }

  function onError(error: unknown, tx: buyNftwithMaticTx) {
    console.log('buyNFTWithMaticTx error', error, tx.receipt);
  }

  console.log('buyNFTWithMaticTx starting contract call');
  const transaction: buyNftwithMaticTx = {
    type: TransactionType.BuyNFTWithMATIC,
    status: TransactionStatus.created,
    function: claimGuard.buyNFTwithMatic,
    params: [tokenId, path, buyAmount.toString()],
    overrides: {
      value: maticWeiPrice,
      gasLimit: (500000 + buyAmount * 10000).toString(),
    },
  };
  // The contract call via metamask
  try {
    console.log('buyNFTWithMaticTx sending tx', transaction);
    sendTransaction(transaction, callback, onError);
  } catch (error) {
    console.log(error);
  }
}
