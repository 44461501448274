import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import {
  buttonHeight,
  narrowObjectWidth,
  standardObjectWidth,
} from '../cmsdriven/constants';
import { ButtonType } from 'src/typings';
import { theme } from 'src/theme';

type WrapperProps = SpaceProps &
  ColorProps & {
    $variant: ButtonType;
    $isDisabled?: boolean;
    $isNarrow?: boolean;
    $tight?: boolean;
  };

export const ButtonText = styled.div<SpaceProps & ColorProps>(
  () => {
    return {
      fontWeight: theme.fontWeights.regular,
      margin: 'auto',
      fontSize: theme.fontSizes[2],
    };
  },
  space,
  color,
);

function getLinkStyle(props: WrapperProps) {
  const { $variant, $isDisabled, $isNarrow, $tight } = props;
  let textColor = theme.colors.white;
  let bgColor = theme.colors.darkGrey;
  let boxShadow = '';
  let fontWeight = theme.fontWeights.regular;
  // border, fill, text colour
  let hoverColor = theme.colors.white;
  let hoverBg = theme.colors.midGrey;
  let cursor = '';

  if ($isDisabled) {
    textColor = theme.colors.midGrey;
    bgColor = theme.colors.lightGrey;
    hoverColor = theme.colors.midGrey;
    hoverBg = theme.colors.lightGrey;
  } else {
    cursor = 'pointer';
    switch ($variant) {
      case ButtonType.primary:
        bgColor = theme.colors.blue;
        hoverBg = theme.secondaryColors.blueDark;
        fontWeight = theme.fontWeights.bold;
        break;
      case ButtonType.secondary:
        textColor = theme.colors.blue;
        bgColor = theme.colors.white;
        hoverBg = theme.colors.blue;
        boxShadow = '0 0 0 3px inset';
        break;
      case ButtonType.tertiary:
        textColor = theme.colors.lightGrey;
        bgColor = theme.colors.transparent;
        hoverBg = theme.colors.transparent;
        hoverColor = theme.colors.white;
        boxShadow = '0 0 0 3px inset';
        break;
      case ButtonType.purchase:
        bgColor = theme.colors.red;
        fontWeight = theme.fontWeights.bold;
        hoverBg = theme.secondaryColors.redDark;
        break;
      case ButtonType.information:
        fontWeight = theme.fontWeights.bold;
        break;
      default:
        break;
    }
  }
  return {
    width: $isNarrow ? narrowObjectWidth : standardObjectWidth,
    height: buttonHeight,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    color: textColor,
    backgroundColor: bgColor,
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontSize: theme.fontSizes[2],
    fontWeight: fontWeight,
    margin: $tight ? '0' : 'auto',
    cursor: cursor,
    boxShadow: boxShadow,
    ':link': {
      textDecoration: 'none',
    },
    ':visited': {
      textDecoration: 'none',
      backgroundColor: bgColor,
      color: textColor,
    },
    ':hover': {
      backgroundColor: hoverBg,
      color: hoverColor,
      textDecoration: 'none',
      boxShadow: 'none',
    },
    ':active': {
      textDecoration: 'none',
      backgroundColor: bgColor,
      color: textColor,
    },
  };
}

export const LinkStyle = styled(Link)<WrapperProps>(
  (props) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return getLinkStyle(props) as any;
  },
  space,
  color,
);

export const ButtonStyle = styled.a<WrapperProps>(
  (props) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return getLinkStyle(props) as any;
  },
  space,
  color,
);
