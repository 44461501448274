import { isProduction } from '.';

export const getContractAddresses = (): ContractAddresses => {
  return isProduction() ? productionAddresses : testAddresses;
};

interface ContractAddresses {
  main: {
    genesis: string;
    game: string;
    usdc: string;
    usdt: string;
    matic: string;
    quick: string;
  };
  mainNative: string;
  side: {
    master: string;
    game: string;
    usdc: string;
    usdt: string;
    weth: string;
    wmatic: string;
    quick: string;
    genesis: string;
    governance: string;
    oldGov: string;
    farm: string;
    world: string;
    claimGuard: string;
    quickGame: string;
    ethGame: string;
    claimRecovery: string;
  };
  sideNative: string;
}

const testAddresses: ContractAddresses = {
  main: {
    genesis: '0x2057808b51DaabCa5d4788A5fC42Aa494C055FfE'.toLowerCase(), // TEST FAKE
    game: '0x63f88A2298a5c4AEE3c216Aa6D926B184a4b2437'.toLowerCase(), // REAL
    usdc: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'.toLowerCase(), // REAL
    usdt: '0xdac17f958d2ee523a2206206994597c13d831ec7'.toLowerCase(), // REAL
    matic: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0'.toLowerCase(), // REAL
    quick: '0x6c28AeF8977c9B773996d0e8376d2EE379446F2f'.toLowerCase(), // REAL
  },
  mainNative: 'ETH',
  side: {
    master: ''.toLowerCase(),
    game: '0x0977487F39651c79B998dBF92FE52f40539E63Fc'.toLowerCase(), // TEST FAKE
    usdc: '0x910A3709E71690C4Bf76f8B73bbc3fF2D2040f53'.toLowerCase(), // TEST FAKE
    usdt: '0x9d6d15006526092304Bd3B354173071B1E67EEb9'.toLowerCase(), // TEST FAKE
    weth: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619'.toLowerCase(), // REAL
    wmatic: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'.toLowerCase(), // REAL
    quick: '0x831753dd7087cac61ab5644b308642cc1c33dc13'.toLowerCase(), // REAL
    genesis: '0x2057808b51DaabCa5d4788A5fC42Aa494C055FfE'.toLowerCase(), // TEST FAKE
    governance: '0x66f86e8e3f78f9b6bdc75c4d0b1b96b74ceaf7c7', // TEST FAKE
    oldGov: '0xe2E7D16e692227bCDB45d8b9514446B08FA9f43E'.toLowerCase(), // TEST FAKE
    farm: '0x5e548efbae9aa6a35e9440ee32674a7d52889765', // TEST FAKE
    world: '0x2241E422C9Ef37Ea056135Faf16D01e4075F863C'.toLowerCase(), // TEST FAKE
    claimGuard: '0x09d9a3f7027d8dfc8e457b98c36149be4cc20ab8'.toLowerCase(),
    // was '0x0820c9DdAf5BEbdB09c1CF8218fbca5F3D4E42f8'.toLowerCase(),
    // prev was: '0x6768eBe881F2ede10c4bf435290878ac1465987f'.toLowerCase(),
    // prev was: '0xba672ebd17c406d9bfc4618cbc7e1b20db4db835'.toLowerCase(),
    quickGame: '0xe675ab2d25314a323fc9e8ee219d7fe8fd241706'.toLowerCase(), // TEST FAKE
    ethGame: '0x8f2a831b07b3626822058230e8076b97196e8035'.toLowerCase(), // TEST FAKE
    claimRecovery: '0x30D8dB7B6b2063cE50484Bb8DeFa09D647cdB80E'.toLowerCase(), // TEST
  },
  sideNative: 'MATIC',
};

const productionAddresses: ContractAddresses = {
  main: {
    genesis: '0x6b6F09997EbFa93F4E111c33825da3b938462FF1'.toLowerCase(), // REAL
    game: '0x63f88A2298a5c4AEE3c216Aa6D926B184a4b2437'.toLowerCase(), // REAL
    usdc: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48'.toLowerCase(), // REAL
    usdt: '0xdac17f958d2ee523a2206206994597c13d831ec7'.toLowerCase(), // REAL
    matic: '0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0'.toLowerCase(), // REAL
    quick: '0x6c28AeF8977c9B773996d0e8376d2EE379446F2f'.toLowerCase(), // REAL
  },
  mainNative: 'ETH',
  side: {
    master: '0x2550aa5c84edb92a66125a85527c151923be35e1'.toLowerCase(), // REAL
    game: '0x8d1566569d5b695d44a9a234540f68d393cdc40d'.toLowerCase(), // REAL
    usdc: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'.toLowerCase(), // REAL
    usdt: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f'.toLowerCase(), // REAL
    weth: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619'.toLowerCase(), // REAL
    wmatic: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'.toLowerCase(), // REAL
    quick: '0x831753dd7087cac61ab5644b308642cc1c33dc13'.toLowerCase(), // REAL
    genesis: '0x51869836681BcE74a514625c856aFb697a013797'.toLowerCase(), // REAL
    governance: '0xff362eaF35a0208Cbc6E8a631BC9C5080fdAEBE9'.toLowerCase(), // REAL
    oldGov: '0x50E42A476631172D1D8a8411A8cFa9C9CDC913b3'.toLowerCase(), // REAL
    farm: '0xc452bf6d1655dDa4ab25e1Dac9A03f9622fe43E1'.toLowerCase(), // REAL
    world: '0x88c44b49638eC0fB24C850A5ef07fAD65cCA0D07'.toLowerCase(), // REAL
    claimGuard: '0x0665013b737d8327f44408adc49c797b7bd2b5d3'.toLowerCase(),
    // was: '0x4b0aeda07aCb5D1B3E41ca8327d859A8BdC59602'.toLowerCase(),
    // was prev: '0x58acef09eff6479a56cf5e82f4eb7eff0d17089a'.toLowerCase(),
    // was prev: '0xf4295a1beeef031b04eb75833b25b977b94a891d'.toLowerCase(),
    quickGame: '0x6276969983510b3dfae28fe6b7b8e2a858f0c2bd'.toLowerCase(), // REAL
    ethGame: '0x4b23803040321868fc2eeb6d3e9c353c3237031d'.toLowerCase(), // REAL
    claimRecovery: '0xe90fE31813fcaA79e2824D851bEf12Da8749Ec76'.toLowerCase(), // REAL
  },
  sideNative: 'MATIC',
};
