/* eslint-disable @typescript-eslint/no-explicit-any */
// @flow
import { JsonRpcSigner, Provider } from '@ethersproject/providers';
import { BigNumber, ethers } from 'ethers';
import { ReserveData } from 'src/calls';
import { Contracts } from 'src/contracts/loadContracts';
import {
  AnyTransaction,
  TransactionStatus,
} from 'src/transactions/transactionTypes';
import { ProviderData } from 'src/util';
import { AllClaimData, MiningClaims } from '../Account/Reducer';
import {
  STORE_CONTRACTS,
  CONNECT_ACCOUNT_SUCCESS,
  CONNECT_ACCOUNT_ERROR,
  UPDATE_TRANSACTION_DATA,
  REMOVE_TRANSACTION_DATA,
  UPDATE_TOKEN_PRICES_ACTION,
  RELOAD_ACCOUNT_DATA_RESULT,
} from '../ActionTypes';
import { createReducer } from '../CreateReducer';
import { MulticallPayload, StoreContractsPayload } from './Actions';
import { Contract, Provider as MulticallProvider } from 'ethers-multicall';
import abis from 'src/contracts/abis';
import { ClaimLimits, contract } from 'src/contracts';

export interface ContractBalances {
  farm: {
    [address: string]: BigNumber;
  };
  governance: {
    [address: string]: BigNumber;
  };
}

export interface USDPrices {
  game: number;
  genesis: number;
  eth: number;
  quick: number;
  matic: number;
}

export interface GlobalBalances {
  farmGameBalance: bigint;
  miningClaimTotal: bigint;
  govGenesisBalance: bigint;
  totalVotePower: bigint;
  isLoading: boolean;
}

export interface Multicall {
  multicallProvider: MulticallProvider;
  game: Contract;
  genesis: Contract;
  farm: Contract;
  governance: Contract;
  ethGame: Contract;
  quickGame: Contract;
  world: Contract;
  claimGuard: Contract;
}

export type ContractsState = {
  contracts: Contracts;
  provider?: Provider;
  signer?: JsonRpcSigner;
  transactions: TransactionDictionary;
  mostRecentTx: AnyTransaction;
  globalBalances: GlobalBalances;
  totalClaims: MiningClaims;
  globalClaims: AllClaimData;
  claimLimits: ClaimLimits;
  reserves: ReserveData;
  prices?: USDPrices;
  multicall?: Multicall;
};

type TransactionDictionary = { [txId: string]: AnyTransaction };

const INITIAL_STATE: ContractsState = {
  contracts: {} as Contracts,
  transactions: {} as TransactionDictionary,
  mostRecentTx: {} as AnyTransaction,
  globalBalances: {
    farmGameBalance: BigInt(0),
    miningClaimTotal: BigInt(0),
    govGenesisBalance: BigInt(0),
    totalVotePower: BigInt(0),
    isLoading: true,
  },
  totalClaims: {},
  globalClaims: {},
  claimLimits: {} as ClaimLimits,
  reserves: {} as ReserveData,
  multicall: {
    multicallProvider: new MulticallProvider(
      new ethers.providers.JsonRpcProvider('https://polygon-rpc.com/', 137),
      137,
    ),
    game: new Contract(contract.side.game, abis.erc20),
    genesis: new Contract(contract.side.genesis, abis.erc20),
    farm: new Contract(contract.side.farm, abis.farm),
    governance: new Contract(contract.side.governance, abis.governance),
    ethGame: new Contract(contract.side.ethGame, abis.pair),
    quickGame: new Contract(contract.side.quickGame, abis.pair),
    world: new Contract(contract.side.world, abis.world),
    claimGuard: new Contract(contract.side.claimGuard, abis.claimGuard),
  },
  prices: {
    game: 0,
    genesis: 0,
    eth: 0,
    matic: 0,
    quick: 0,
  },
};

const reducers = {
  [RELOAD_ACCOUNT_DATA_RESULT]: (
    state: ContractsState,
    {
      data,
    }: {
      data: MulticallPayload;
    },
  ) => {
    const { contracts } = data;
    state.globalBalances = contracts.globalBalances;
    state.globalClaims = contracts.globalClaims;
    state.reserves = contracts.reserves;
    state.claimLimits = contracts.claimLimits;
    return state;
  },
  [UPDATE_TOKEN_PRICES_ACTION]: (
    state: ContractsState,
    {
      data,
    }: {
      data: {
        prices: USDPrices;
      };
    },
  ) => {
    state.prices = data.prices;
    return state;
  },
  [STORE_CONTRACTS]: (
    state: ContractsState,
    { data }: { data: StoreContractsPayload },
  ) => {
    state.contracts = data.contracts;
    return state;
  },
  [CONNECT_ACCOUNT_SUCCESS]: (
    state: ContractsState,
    { data }: { data: ProviderData },
  ) => {
    state.provider = data.provider;
    state.signer = data.signer;
    return state;
  },
  [CONNECT_ACCOUNT_ERROR]: (state: ContractsState, { error }) => ({
    ...state,
    provider: undefined,
    signer: undefined,
    error: error,
  }),
  [UPDATE_TRANSACTION_DATA]: (
    state: ContractsState,
    { data }: { data: AnyTransaction },
  ) => {
    const dict = { ...state.transactions };
    const id = data.response?.hash || data.type;
    // we don't have the tx hash until the tx is signed. So we use the type to start with
    // and switch to hash once we have it.
    delete dict[data.type];
    if (data.status !== TransactionStatus.rejected) {
      dict[id] = data;
    }
    state.transactions = dict;
    state.mostRecentTx = data;
    return state;
  },
  [REMOVE_TRANSACTION_DATA]: (
    state: ContractsState,
    { data }: { data: string },
  ) => {
    if (state.transactions[data]) {
      const dict = { ...state.transactions };
      delete dict[data];
      state.transactions = dict;
      return state;
    } else {
      return state;
    }
  },
};

export const reducer = createReducer(INITIAL_STATE, reducers);

export default reducer;
