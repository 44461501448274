import { isProduction } from '.';

export enum Feature {
  WorldsVisible = 'WorldsVisible',
  WorldSale = 'WorldSale',
  GovernanceRewards = 'GovernanceRewards',
}

export interface FeatureToggles {
  [Feature.WorldSale]: boolean;
  [Feature.WorldsVisible]: boolean;
  [Feature.GovernanceRewards]: boolean;
}

const defaultToggles: FeatureToggles = {
  [Feature.WorldSale]: false,
  [Feature.WorldsVisible]: false,
  [Feature.GovernanceRewards]: false,
};

export const getDefaultToggles = (): FeatureToggles => {
  if (isProduction()) {
    return defaultToggles;
  } else {
    const toggles: FeatureToggles = {} as FeatureToggles;
    Object.keys(defaultToggles).map((toggle) => {
      toggles[toggle] = true;
    });
    return toggles;
  }
};
