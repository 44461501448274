import { ClaimStoryblok, StoryStoryblok } from 'src/typings';
import * as ActionTypes from '../ActionTypes';

export const getStoriesAction = () => ({
  type: ActionTypes.GET_STORIES,
});

export const createStoryDataSuccess = (data: {
  stories: StoryStoryblok[];
  claims: ClaimStoryblok[];
}) => ({
  type: ActionTypes.GET_STORIES_SUCCESS,
  data,
});

export const createStoryDataError = (error) => ({
  type: ActionTypes.GET_STORIES_ERROR,
  error,
});

export const removeStoriesData = () => ({});

export const setStoriesLanguage = (lang) => ({
  type: ActionTypes.SET_STORIES_LANGUAGE,
  lang,
});

export const refreshClaimsAction = () => ({
  type: ActionTypes.REFRESH_CLAIMS_ACTION,
});
