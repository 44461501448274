import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  PageSectionParagraphStyle,
  PageSectionTextStyle,
} from 'src/components/cmsdriven/PageSection/style';
import {
  EarnHighlights,
  HighlightArea,
} from 'src/components/cmsdriven/SiteHeader/highlight';
import Input from 'src/components/numericalInput';
import { Routes } from 'src/constants/routes';
import {
  calculatePrice,
  ClaimData,
  getClaimBuyPrice,
} from 'src/constants/worlds';
import { AllClaimData } from 'src/redux/Account/Reducer';
import { AppState } from 'src/redux/CreateStore';
import { StoryblokDesktopLayout } from 'src/typings';
import {
  displayGC,
  displayGenesis,
  displayUSD,
  GenesisCurrency,
} from 'src/util';
import {
  calculateGovernanceApr,
  calculateGovernanceGenesisPerDay,
} from '../GovernanceStakingSection';
import { EarnDiv, EarnLi, EarnP } from './style';

export function EarnSection() {
  // If you were to spend XXX USD (default 10k)
  // You could buy YYY GAME
  // And use that to buy ZZZ claims
  // Each claim would earn you AAA
  // And you could stake that to earn BBB
  // And you'd get LLL premium land
  const { t } = useTranslation();
  const globalBalances = useSelector(
    (state: AppState) => state.Contracts.globalBalances,
  );
  const prices = useSelector((state: AppState) => state.Contracts.prices);
  const { miningClaimTotal, govGenesisBalance } = globalBalances;

  const globalClaims: AllClaimData = useSelector(
    (state: AppState) => state.Contracts.globalClaims,
  );
  const claims = useSelector((state: AppState) => state.Stories.claims);
  const [formAmount, setFormAmount] = useState(10000);

  const governanceStartTime = claims[1].onSale + 604800 * 5; // 5 weeks after claim 1 on sale

  const now = Date.now() / 1000;

  const values = Object.values(globalClaims);
  const cheapestClaim: ClaimData =
    !values || values.length === 0
      ? {}
      : values.reduce(function (prev: ClaimData, curr: ClaimData) {
          return curr.onSale > now && curr.buyPrice < prev.buyPrice
            ? curr
            : prev;
        });
  const { onSale, totalSupply, yearEmission } = cheapestClaim;

  const claimKeys = Object.keys(globalClaims);
  for (let i = 0; i < claimKeys.length; i++) {}

  const usdAmount = formAmount;
  const gamePrice = prices.game;
  const genesisPrice = prices.genesis;
  const gameBought = Math.floor(usdAmount / gamePrice);

  let claimsBought = 0;
  let totalPrice = 0;
  while (totalPrice <= gameBought) {
    claimsBought++;
    totalPrice += calculatePrice(
      onSale,
      getClaimBuyPrice(totalSupply + claimsBought),
    );
    if (totalPrice >= gameBought) {
      claimsBought--;
    }
  }
  const emittedTotal = yearEmission * claimsBought;

  const para1 = t('section.earn.para1').split('%%%');
  const para2 = t('section.earn.para2').split('%%%');
  const para3 = t('section.earn.para3').split('%%%');
  const para4 = t('section.earn.para4').split('%%%');
  const para5 = t('section.earn.para5').split('%%%');

  const governanceApr = calculateGovernanceApr(
    calculateGovernanceGenesisPerDay(governanceStartTime, miningClaimTotal),
    govGenesisBalance,
  );
  console.log('governanceApr', governanceApr);
  const stakingRewards = (emittedTotal * governanceApr) / 100;

  function onFormAmountInput(input: string) {
    console.log('input', input);
    const inputNum = parseInt(input.replace('$', ''));
    console.log('inputNum', inputNum, input);
    if (isNaN(inputNum) || inputNum < 0) {
      setFormAmount(null);
    } else {
      setFormAmount(inputNum);
    }
  }

  return (
    <>
      <HighlightArea highlights={EarnHighlights} />
      <PageSectionTextStyle
        layout={StoryblokDesktopLayout.full}
        isFullWidth={true}
      >
        <PageSectionParagraphStyle
          layout={StoryblokDesktopLayout.full}
          isFullWidth={true}
        >
          <EarnP>
            {t('section.earn.helpMe1')}
            {': '}
          </EarnP>
          <EarnDiv className={'currencyInput'}>
            <Input
              value={formAmount > 0 ? `${formAmount}` : ''}
              onUserInput={onFormAmountInput}
              placeholder={'10000'}
              style={{ width: '120px', margin: 0 }}
            />
          </EarnDiv>
          <EarnP>
            <span style={{ whiteSpace: 'nowrap' }}>
              {' '}
              {t('section.earn.helpMe2')}
            </span>
          </EarnP>
          <ul>
            <EarnLi>
              {para1[0]}
              <b>{displayUSD(usdAmount)}</b>
              {para1[1]}
              <b>{displayGC(gameBought, '', true)}</b>
              {para1[2]}
              {claimsBought}
              {para1[3]}
            </EarnLi>
            <EarnLi>
              {para2[0]}
              {displayGenesis(yearEmission, '', true)}
              {para2[1]}
              {displayGenesis(emittedTotal, '', true)}.
            </EarnLi>
            <EarnLi>
              {para3[0]}
              {GenesisCurrency}
              {para3[1]}
              {`${governanceApr.toFixed(2)}%`}
              {para3[2]}
              {displayGenesis(stakingRewards + emittedTotal, '', true)}
              {para3[3]}
            </EarnLi>
            <EarnLi>
              {para4[0]}
              <b>
                {displayUSD((stakingRewards + emittedTotal) * genesisPrice)}
              </b>
              {para4[1]} {para5[0]}
              <b>{claimsBought}</b>
              {para5[1]}
            </EarnLi>
          </ul>
          <p>{t('section.earn.specialLandText')}</p>
          <ul>
            <EarnLi>
              <Link to={`/${Routes.mining}`}>
                {t('section.earn.buyClaims')}
              </Link>
            </EarnLi>
            <EarnLi>
              <Link to={`/${Routes.staking}`}>
                {t('section.earn.stakeGenesis')}
              </Link>
            </EarnLi>
            <EarnLi>
              <Link to={`/${Routes.land}`}>{t('section.earn.buyLand')}</Link>
            </EarnLi>
          </ul>
        </PageSectionParagraphStyle>
      </PageSectionTextStyle>
    </>
  );
}
