import React, { useEffect } from 'react';

import { PageSection, SiteHeader } from '../';
import { ClaimGrid } from '../../datadriven';
// StatTable = 'StatTable',
import {
  FunctionalSectionStoryblok,
  StoryblokComponentType,
  StoryStoryblok,
} from 'src/typings';
import { FunctionalSection } from '../../datadriven/FunctionalSection';
import { FaqSection } from '../FaqSection';

export function Story(props: { page: StoryStoryblok }) {
  const { page } = props;

  const callback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add('scaleIn');
      } else {
        entry.target.classList.remove('scaleIn');
      }
    });
  };
  const options = {
    root: null,
    threshold: 0.25,
  };

  const myObserver = new IntersectionObserver(callback, options);
  useEffect(() => {
    const psa = document.querySelectorAll('.page-section-fade');
    if (psa) {
      psa.forEach((entry) => {
        myObserver.observe(entry);
      });
    }
  }, [page]);

  return (
    <>
      {page.content.body.map((section, i) => {
        switch (section.component) {
          case StoryblokComponentType.SiteHeader:
            return <SiteHeader data={section} key={section._uid} />;
          case StoryblokComponentType.FunctionalSection:
            return (
              <FunctionalSection
                type={(section as FunctionalSectionStoryblok).type}
                key={section._uid}
              />
            );
          case StoryblokComponentType.PageSection:
            const nextSection =
              i === page.content.body.length - 1
                ? StoryblokComponentType.None
                : page.content.body[i + 1].component;
            return (
              <PageSection
                data={section}
                nextSection={nextSection}
                key={section._uid}
                offset={i}
                slug={page.slug}
              />
            );
          case StoryblokComponentType.FaqSection:
            return <FaqSection data={section} key={section._uid} />;
          case StoryblokComponentType.WorldGrid:
            return <ClaimGrid data={section} key={section._uid} />;
          default:
            console.log(`didn't find Story section for`, section);
            return <p key={i}>Missing Section: {section.component}</p>;
        }
      })}
    </>
  );
}
