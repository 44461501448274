import { BigNumber } from 'ethers';
import { store } from 'src';
import { sendTransaction } from './sendTransaction';
import {
  TransactionData,
  TransactionStatus,
  TransactionType,
} from './transactionTypes';

export interface FarmWithdrawResult {
  gameWithdrawn: BigNumber;
  genesisEarned: BigNumber;
  timestamp: number;
}

// Assumption here is that all amount checks have already taken place
export async function farmingWithdrawTx() {
  const { Contracts } = store.getState();
  const { farm } = Contracts.contracts;
  if (!farm) {
    throw 'farmingWithdrawTx store failure: no farm contract';
  }

  function callback(tx: TransactionData<[]>) {
    try {
      console.log('farmingWithdrawTx completed withdrawal:');
    } catch (err) {
      console.error(
        'farmingWithdrawTx failed to parse receipt',
        tx.receipt,
        err,
      );
    }
  }

  function onError(error: unknown, tx: TransactionData<[]>) {
    console.log('farmingWithdrawTx error', error, tx.receipt);
  }

  console.log('farmingWithdrawTx starting contract call');
  const transaction: TransactionData<[]> = {
    type: TransactionType.FarmWithdrawal,
    status: TransactionStatus.created,
    function: farm.withdraw,
    params: [],
  };
  // The contract call via metamask
  try {
    console.log('farmingWithdrawTx sending tx', transaction);
    sendTransaction(transaction, callback, onError);
  } catch (error) {
    console.log(error);
  }
}
