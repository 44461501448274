import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import { TitleProps } from 'src/components';
import { FontSize } from './constants';
import { Media } from 'src/constants';
import { theme } from 'src/theme';

type WrapperProps = SpaceProps & ColorProps & TitleProps;

export const SiteTitle = styled.h1<WrapperProps>(
  () => {
    return {
      textTransform: 'uppercase',
      boxSizing: 'border-box',
      textShadow: `0px 8px 10px ${theme.colors.black}, 0px -4px 10px ${theme.colors.black}`,
      marginBottom: '16px',
    };
  },
  space,
  color,
);

export const Title = styled.h3<WrapperProps>(
  () => {
    return {
      textTransform: 'uppercase',
      margin: `${theme.spacing.mobileDouble} 0 0`,
    };
  },
  (props) =>
    props.$header
      ? {
          boxSizing: 'border-box',
          textShadow: `0px 8px 10px ${theme.colors.black}, 0px -4px 10px ${theme.colors.black}`,
          marginBottom: '16px',
        }
      : {},
  space,
  color,
);

export const Subtitle = styled.h1<WrapperProps>(
  () => {
    return {
      font: 'Montserrat',
      fontWeight: theme.fontWeights.bold,
      textTransform: 'uppercase',
      fontSize: FontSize.mobileSubHead,
      [Media.desktop]: {
        fontSize: FontSize.desktopSubHead,
      },
    };
  },
  (props) =>
    props.$header
      ? {
          boxSizing: 'border-box',
          textShadow:
            '0px -4px 10px rgba(0, 0, 0, 0.5), 0px 4px 10px rgba(0, 0, 0, 0.5)',
        }
      : {},
  space,
  color,
);

export const SiteCtaButtonStyle = styled.div<WrapperProps>(
  (props) => {
    if (props.$header) {
      return {
        width: '100%',
        padding: theme.spacing.mobileSingle,
        [Media.desktop]: {
          padding: theme.spacing.desktopSingle,
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
    } else {
      return {
        padding: theme.spacing.mobileSingle,
        [Media.desktop]: {
          padding: theme.spacing.desktopSingle,
        },
      };
    }
  },
  space,
  color,
);
