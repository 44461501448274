/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ClaimData } from 'src/constants/worlds';
import { ButtonType } from 'src/typings';
import useModal from 'src/hooks/useModal';
import { ActionButton } from 'src/components/buttons';
import { DataTable, TableDataFormat, TableRowData } from 'src/components/table';
import { BuyClaimModal } from 'src/components/modals/BuyClaimModal';

export const SingleClaimDataTable = (
  claim: ClaimData,
  price: number,
  presaleClaimsRemaining: number,
  apr: number,
  gamePrice: number,
  genesisPrice: number,
  priceInMatic: number,
  canTransact: boolean,
) => {
  const { t } = useTranslation();
  const buyModal = useModal();
  const { myBalance, totalSupply, yearEmission, name } = claim;
  const BuyModal = BuyClaimModal({ ...buyModal, claim: claim });

  const isLoading = !name || !gamePrice || !priceInMatic;
  const worldSingleRows: TableRowData[] = [
    {
      label: t('claims.table.claimsheld'),
      data: totalSupply,
      dataFormat: TableDataFormat.integer,
      isLoading,
    },
    {
      label: t('claims.table.myclaims'),
      data: myBalance,
      dataFormat: TableDataFormat.integer,
      isLoading,
    },
    {
      label: t(
        presaleClaimsRemaining > 0
          ? 'claims.table.launchprice'
          : 'claims.table.marketprice',
      ),
      data: Math.ceil(price),
      dataFormat: TableDataFormat.Game,
      noteData: priceInMatic > 0 ? priceInMatic : null,
      noteFormat: TableDataFormat.Matic,
      isLoading,
    },
    presaleClaimsRemaining > 0 && {
      label: t('claims.table.launchClaimsLeft'),
      data: presaleClaimsRemaining,
      dataFormat: TableDataFormat.integer,
      noteData: null,
      isLoading,
    },
    {
      label: t('claims.table.minedperyear'),
      data: Math.floor(yearEmission),
      dataFormat: TableDataFormat.Genesis,
      usdRatio: genesisPrice,
      isLoading,
    },
    {
      label: t('claims.table.apr'),
      data: apr,
      dataFormat: TableDataFormat.percentage,
      isLoading,
    },
  ];

  const buyButton = (
    <ActionButton
      type={ButtonType.purchase}
      onClick={() => {
        buyModal.show(true);
      }}
    >
      {t('claims.table.buy')}
    </ActionButton>
  );

  return (
    <>
      <DataTable
        rows={worldSingleRows}
        actionButton={canTransact ? buyButton : null}
      />
      {BuyModal}
    </>
  );
};
