import { store } from 'src';
import { address } from 'src/interfaces/Common';
import { sendTransaction } from './sendTransaction';
import {
  TransactionData,
  TransactionStatus,
  TransactionType,
} from './transactionTypes';

type ApproveNFTtx = TransactionData<[operator: address, approved: boolean]>;

// Assumption here is that all amount checks have already taken place
export async function approveNFTBurningTx() {
  const { Contracts } = store.getState();
  const { world, claimGuard } = Contracts.contracts;
  if (!world) {
    throw 'approveNFTBurningTx store failure: no world contract ';
  }

  // the callback on a successful receipt
  async function callback(tx: ApproveNFTtx) {
    console.log('approveNFTBurningTx callback fn', tx.type);
  }

  function onError(error: unknown, tx: ApproveNFTtx) {
    console.log('approveNFTBurningTx error', error, tx.receipt);
  }

  console.log('approveNFTBurningTx starting contract call');
  const transaction: ApproveNFTtx = {
    type: TransactionType.ApproveNFT,
    status: TransactionStatus.created,
    function: world.setApprovalForAll,
    params: [claimGuard.address, true],
    overrides: {
      gasLimit: '100000',
    },
  };
  // The contract call via metamask
  try {
    console.log('approveNFTBurningTx sending tx', transaction);
    sendTransaction(transaction, callback, onError);
  } catch (error) {
    console.log(error);
  }
}
