import React, { useState } from 'react';

// Constants
import { SocialLinks } from 'src/constants/social';

// components
import {
  FooterStyle,
  Social,
  SocialSection,
  Links,
  FooterLinkInternal,
  FooterLinkExternal,
  LinkContent,
  ContactSection,
  StayUpToDate,
  EmailSignUp,
} from './style';
import { useTranslation } from 'react-i18next';
import { Routes } from 'src/constants/routes';
import { submitHubspotForm } from 'src/util';
import { EmailInput } from '../newsletter/style';
import { ButtonType } from 'src/typings';
import { ButtonStyle, ButtonText } from '../buttons/style';
import { VerticalSpacer } from '..';

export const Footer = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);

  async function submit(event: React.MouseEvent) {
    event.preventDefault();
    setIsError(false);
    const result = await submitHubspotForm(email);
    if (!result) {
      setIsError(true);
    } else {
      setEmail('');
      setIsSubmitted(true);
    }
  }

  return (
    <FooterStyle>
      <ContactSection>
        <StayUpToDate>
          <h2>{t('footer.stayUpToDate')}</h2>
          <h6>{t('footer.firstToHear')}</h6>
        </StayUpToDate>

        {isSubmitted ? (
          <EmailSignUp isError={false}>
            <h6>{t('footer.thanks')}</h6>
          </EmailSignUp>
        ) : (
          <EmailSignUp isError={isError}>
            <EmailInput
              type="email"
              placeholder={t('footer.enterEmail')}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setIsError(false);
              }}
              disabled={isSubmitted}
              isError={isError}
            />
            <VerticalSpacer />
            <ButtonStyle
              onClick={(event) => submit(event)}
              className={`emailSignupButton`}
              $variant={ButtonType.primary}
            >
              <ButtonText>{t('footer.signMeUp')}</ButtonText>
            </ButtonStyle>
          </EmailSignUp>
        )}
      </ContactSection>
      <Social>
        <SocialSection>
          <FooterLinkInternal to={`/${Routes.farming}`}>
            <h1>{t(`menu.${Routes.farming}`)}</h1>
          </FooterLinkInternal>
          <FooterLinkInternal to={`/${Routes.mining}`}>
            <h1>{t(`menu.${Routes.mining}`)}</h1>
          </FooterLinkInternal>
          <FooterLinkInternal to={`/${Routes.tokens}`}>
            <h1>{t(`menu.${Routes.tokens}`)}</h1>
          </FooterLinkInternal>
          <FooterLinkInternal to={`/${Routes.metaverse}`}>
            <h1>{t(`menu.${Routes.metaverse}`)}</h1>
          </FooterLinkInternal>
          <FooterLinkInternal to={`/${Routes.community}`}>
            <h1>{t(`menu.${Routes.community}`)}</h1>
          </FooterLinkInternal>
          <FooterLinkExternal
            href={SocialLinks.WHITEPAPER}
            target="_blank"
            rel="noopener noreferrer"
          >
            <h1>{t(`menu.whitepaper`)}</h1>
          </FooterLinkExternal>
        </SocialSection>
        <SocialSection>
          {Object.keys(SocialLinks).map((key, index) => {
            if (SocialLinks[key] != SocialLinks.WHITEPAPER) {
              return (
                <FooterLinkExternal
                  key={`footer-item-${key}-${index}`}
                  href={SocialLinks[key]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h1>{key}</h1>
                </FooterLinkExternal>
              );
            } else {
              return null;
            }
          })}
        </SocialSection>
      </Social>

      <Links>
        <p>(c) 2021 NFT Platform Ltd</p>
        <LinkContent>
          <FooterLinkExternal
            href="https://hub.genesis.game/resources/terms"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.terms')}
          </FooterLinkExternal>{' '}
          <span>|</span>
          <FooterLinkExternal
            href="https://hub.genesis.game/resources/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.privacy')}
          </FooterLinkExternal>{' '}
          <span>|</span>
          <FooterLinkExternal
            href="https://hub.genesis.game/resources/contentpolicy"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.content')}
          </FooterLinkExternal>{' '}
          <span>|</span>
          <FooterLinkExternal
            href="https://hub.genesis.game/resources/codeofethics"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.ethics')}
          </FooterLinkExternal>
          <span>|</span>
          <FooterLinkExternal
            href="https://hub.genesis.game/resources/legal"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('footer.legal')}
          </FooterLinkExternal>
        </LinkContent>
      </Links>
    </FooterStyle>
  );
};
