// @flow

import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { ContractsState } from './Contracts/Reducer';
import { AccountState } from './Account/Reducer';
import { StoriesState } from './Stories/Reducer';
import { SettingsState } from './Settings/Reducer';
import { batchedSubscribe } from 'redux-batched-subscribe';
import { debounce } from 'lodash';

export type AppState = {
  Account: AccountState;
  Contracts: ContractsState;
  Settings: SettingsState;
  Stories: StoriesState;
};

export default (rootReducer, rootSaga) => {
  /* ------------- Redux Configuration ------------- */
  let middleware = [];
  const enhancers = [];

  /* ------------- Saga Middleware ------------- */
  const sagaMiddleware = createSagaMiddleware();
  middleware = [sagaMiddleware];

  /* ------------ Logger Middleware ------------- */
  // if (DebugSetting.reduxLogging) {
  // middleware.push(createLogger());
  // }

  /* ------------- Assemble Middleware ------------- */
  enhancers.push(applyMiddleware(...middleware));
  enhancers.push(
    batchedSubscribe(
      debounce((notify) => {
        notify();
      }),
    ),
  );

  /* ------------- AutoRehydrate Enhancer ------------- */

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['Secrets'],
  };

  const persistedReducer = persistReducer<AppState>(persistConfig, rootReducer);

  // in dev mode, we'll create the store through Reactotron
  const store = createStore(
    persistedReducer,
    compose(applyMiddleware(...middleware), (nope) => nope),
  );
  sagaMiddleware.run(rootSaga);
  const persistor = persistStore(store);

  return { store, persistor };
};
