import { store } from 'src';
import { uint256 } from 'src/interfaces/Common';
import { sendTransaction } from './sendTransaction';
import {
  TransactionData,
  TransactionStatus,
  TransactionType,
} from './transactionTypes';

type GenesisDepositTx = TransactionData<[amount: uint256]>;

// Assumption here is that all amount checks have already taken place
export async function genesisDepositTx(amount: string) {
  const { Contracts } = store.getState();
  const { governance } = Contracts.contracts;
  if (!governance) {
    throw 'GovernanceDepositTx store failure: no governance contract';
  }

  function callback(tx: GenesisDepositTx) {
    console.log('GovernanceDepositTx success', tx.receipt);
  }

  function onError(error: unknown, tx: GenesisDepositTx) {
    console.log('GovernanceDepositTx error', error, tx.receipt);
  }

  const transaction: GenesisDepositTx = {
    type: TransactionType.GenesisDeposit,
    status: TransactionStatus.created,
    function: governance.deposit,
    params: [amount],
  };
  // The contract call via metamask
  try {
    sendTransaction(transaction, callback, onError);
  } catch (error) {
    console.log('at error failed', error.message);
    console.log(error);
  }
}
