import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import { Media } from 'src/constants';
import { theme } from 'src/theme';

type WrapperProps = SpaceProps & ColorProps;

export const TokenDetailsSectionStyle = styled.section<WrapperProps>(
  () => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [Media.desktop]: {
      flexDirection: 'row',
    },
    margin: 'auto',
    maxWidth: theme.spacing.maxContentWidth,
  }),
  space,
  color,
);

export const TokenDetailsSectionTextStyle = styled.div<WrapperProps>(
  () => {
    return {
      padding: theme.spacing.mobileSingle,
      [Media.desktop]: {
        padding: theme.spacing.desktopSingle,
      },
      margin: 'auto',
      maxWidth: theme.spacing.maxContentWidth,
      textAlign: 'left',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    };
  },
  space,
  color,
);

export const TokenDetailsImage = styled.img<WrapperProps>(
  () => {
    return {
      height: theme.spacing.mobileDouble,
      width: theme.spacing.mobileDouble,
      marginRight: theme.spacing.mobileSingle,
    };
  },
  space,
  color,
);

export const NbspLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  white-space: nowrap;
`;
