import { store } from 'src';
import { uint256 } from 'src/interfaces/Common';
import { sendTransaction } from './sendTransaction';
import {
  TransactionData,
  TransactionStatus,
  TransactionType,
} from './transactionTypes';

type BurnNFTtx = TransactionData<[tokenId: uint256, amount: number]>;

// Assumption here is that all amount checks have already taken place
export async function burnNFTForGameTx(tokenId: string, amount: number) {
  const { Contracts } = store.getState();
  const { claimGuard } = Contracts.contracts;
  if (!claimGuard) {
    throw 'burnNFTForGameTx store failure: no claimGuard contract ';
  }

  // the callback on a successful receipt
  async function callback(tx: BurnNFTtx) {
    console.log('burnNFTForGameTx callback fn', tx.type);
  }

  function onError(error: unknown, tx: BurnNFTtx) {
    console.log('burnNFTForGameTx error', error, tx.receipt);
  }

  console.log('burnNFTForGameTx starting contract call');
  const transaction: BurnNFTtx = {
    type: TransactionType.BurnNFT,
    status: TransactionStatus.created,
    function: claimGuard.burn,
    params: [tokenId, amount],
    overrides: {
      gasLimit: '500000',
    },
  };
  // The contract call via metamask
  try {
    console.log('worldTx sending tx', transaction);
    sendTransaction(transaction, callback, onError);
  } catch (error) {
    console.log(error);
  }
}
