export const narrowObjectWidth = '180px';
export const standardObjectWidth = '280px';
export const largeCardWidth = standardObjectWidth;
export const smallCardWidth = '164px';
export const buttonHeight = '40px';

export enum FontSize {
  mobileHeading = '24px',
  mobileSubHead = '16px',
  mobileBody = '16px',
  desktopHeading = '28px',
  desktopSubHead = '20px',
  desktopBody = '20px',
  title = '48px',
}

export enum DesktopLayout {
  Full = 'full',
  Left = 'left',
  Right = 'right',
}
