import { BigNumber } from 'ethers';
import { store } from 'src';

export const getGameBalanceFromContract = async (address: string) => {
  const { Contracts } = store.getState();
  const game = Contracts?.contracts?.game;
  if (!game) {
    console.log('no game contract', Contracts.contracts);
    return BigNumber.from(0);
  }
  const contractReturn = await game.balanceOf(address);
  return contractReturn;
};
