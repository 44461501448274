import React from 'react';
import { Modal } from 'rsuite';

export interface ModalProps {
  size?: ModalSize;
  visible: boolean;
  show: (isShowing: boolean) => void;
}

type ModalSize = 'lg' | 'md' | 'sm' | 'xs';

export function ModalBase(props: ModalProps & { children: JSX.Element }) {
  const { visible, show, children, size } = props;

  const onHide = () => {
    show(false);
  };

  return (
    <ModalStyle
      show={visible}
      backdrop
      keyboard
      autoFocus
      enforceFocus
      onHide={onHide}
      size={size || 'md'}
    >
      {children}
    </ModalStyle>
  );
}

import { theme } from 'src/theme';
import styled from 'styled-components';
import { space, color } from 'styled-system';
import { Media } from 'src/constants';

export const ModalStyle = styled(Modal)(
  () => {
    return {
      marginTop: 0,
      maxWidth: '100%',
      width: '100%',
      [Media.desktop]: {
        marginTop: '90px',
        maxWidth: '672px',
      },
      borderRadius: 0,
    };
  },
  space,
  color,
);

export const ModalHeader = styled(Modal.Header)(
  () => {
    return {
      margin: `0 ${theme.spacing.mobileDouble}`,
      padding: `${theme.spacing.mobileSingle} ${theme.spacing.mobileDouble}`,
      '.rs-modal-header-close': {
        fontSize: '20px',
        right: '28px',
      },
    };
  },
  space,
  color,
);

export const ModalBody = styled(Modal.Body)(
  () => {
    return {
      maxHeight: 'unset !important',
      overflow: 'unset !important',
      margin: '0 !important',
      padding: `0 ${theme.spacing.mobileSingle} ${theme.spacing.mobileSingle} !important`,

      [Media.desktop]: {
        padding: `0 ${theme.spacing.mobileDouble} ${theme.spacing.mobileDouble} !important`,
      },
    };
  },
  space,
  color,
);
export const ModalFooter = styled(Modal.Footer)(
  () => {
    return {
      padding: `0 ${theme.spacing.mobileDouble} ${theme.spacing.mobileDouble}`,
      display: 'flex',
      flexDirection: 'column',
      [Media.tablet]: {
        flexDirection: 'row',
        justifyContent: 'space-around',
      },
    };
  },
  space,
  color,
);

export const ModalButtonHolder = styled.div({
  padding: `${theme.spacing.mobileSingle} 0`,
  textAlign: 'center',
  [Media.tablet]: {
    padding: `0 ${theme.spacing.desktopSingle}`,
  },
});
