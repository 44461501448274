export enum Media {
  tablet = '@media (min-width: 672px)',
  desktop = '@media (min-width: 800px)',
  widescreen = '@media (min-width: 1296px)',
}

export enum WindowSize {
  tablet = 672,
  desktop = 800,
  widescreen = 1296,
}

export const mobileHeaderHeight = '48px';
export const desktopHeaderHeight = '72px';
