import React from 'react';
import { theme } from 'src/theme';

export function signingSvg() {
  return (
    <svg
      id={'svg'}
      version={'1.1'}
      xmlns={'http://www.w3.org/2000/svg'}
      width="100"
      height="100"
      viewBox="0, 0, 400,400"
    >
      <g id="svgg">
        <path
          id="path0"
          d="M311.986 4.233 C 299.755 6.557,288.357 11.746,279.117 19.198 C 269.375 27.055,136.889 160.282,131.465 167.676 C 115.525 189.402,111.719 204.764,111.719 247.362 C 111.719 278.705,111.823 279.460,116.966 285.180 C 122.755 291.618,122.365 291.562,159.047 291.211 C 202.882 290.791,215.521 287.369,237.891 269.865 C 246.622 263.033,384.164 124.976,387.664 119.531 C 407.258 89.048,403.427 49.690,378.431 24.694 C 361.624 7.888,335.346 -0.204,311.986 4.233 M45.703 28.515 C 24.098 33.341,6.921 50.031,1.552 71.414 C -0.880 81.097,-0.880 343.122,1.552 352.805 C 6.975 374.404,24.429 391.123,46.262 395.632 C 55.281 397.495,274.989 397.521,284.213 395.660 C 306.168 391.230,324.689 372.837,329.355 350.831 C 330.729 344.347,330.960 275.630,329.624 270.817 C 326.015 257.818,306.898 253.378,297.030 263.246 C 291.616 268.660,291.838 266.950,291.406 306.641 C 290.941 349.412,290.774 350.130,280.078 355.174 L 276.172 357.016 165.014 357.024 C 43.136 357.032,51.546 357.369,45.711 352.246 C 38.857 346.228,39.480 359.592,39.246 213.716 C 39.016 70.098,38.669 79.606,44.367 73.280 C 50.540 66.426,46.141 66.848,116.016 66.406 C 172.474 66.049,177.944 65.901,180.191 64.671 C 194.587 56.791,194.067 35.507,179.314 28.806 C 175.173 26.925,54.002 26.661,45.703 28.515 M334.766 43.901 C 351.133 47.953,362.748 65.793,360.099 82.813 C 358.658 92.071,355.920 96.481,344.217 108.394 L 333.661 119.141 308.823 94.293 L 283.984 69.445 294.437 59.246 C 310.337 43.729,319.694 40.169,334.766 43.901 M261.524 191.277 C 200.852 252.048,202.118 251.230,167.383 252.132 L 150.781 252.563 150.781 241.989 C 150.781 217.660,153.976 204.667,163.122 191.797 C 164.649 189.648,186.134 167.584,210.866 142.765 L 255.833 97.639 280.652 122.449 L 305.471 147.258 261.524 191.277 "
          stroke="none"
          fill={theme.colors.purple}
          fillRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
