import React from 'react';
import { ButtonType } from 'src/typings';
import { ModalBase, ModalProps, ModalHeader, ModalFooter, ModalBody } from '.';
import { ActionButton } from 'src/components/buttons';
import { useTranslation } from 'react-i18next';
import { genesisWithdrawTx } from 'src/transactions/genesisWithdrawTx';
import { VerticalSpacer } from '..';
import {
  GovernanceData,
  withdrawStakeTable,
} from '../datadriven/GovernanceStakingSection';

export function GenesisWithdrawModal(
  props: ModalProps & { governanceData: GovernanceData },
) {
  const { visible, show, governanceData } = props;
  const { myStakedGenesis } = governanceData;
  const { t } = useTranslation();

  const sendWithdrawTx = async () => {
    await genesisWithdrawTx();
    show(false);
  };

  return (
    <ModalBase visible={visible} show={show}>
      <>
        <ModalHeader>
          <h3>{t('modal.stakingWithdraw.title')}</h3>
        </ModalHeader>
        <ModalBody>
          <p>{t('modal.stakingWithdraw.collect')}</p>
          <VerticalSpacer />
          {withdrawStakeTable(governanceData)}
        </ModalBody>
        <ModalFooter>
          <ActionButton
            type={ButtonType.primary}
            onClick={sendWithdrawTx}
            isDisabled={myStakedGenesis === 0}
          >
            {t('modal.stakingWithdraw.button')}
          </ActionButton>
        </ModalFooter>
      </>
    </ModalBase>
  );
}
