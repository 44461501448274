import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { store } from 'src';
import { reloadAccountDataAction } from 'src/redux/Account/Actions';

export function MulticallHandler() {
  const location = useLocation();

  useEffect(() => {
    console.log('dispatching multicall', location);
    store.dispatch(reloadAccountDataAction());
  }, [location]);

  return null;
}
