import React from 'react';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { SiteHeaderStoryblok, StoryblokDesktopLayout } from 'src/typings';
import { ArtCutout, CutoutLocation } from '../ArtCutout';
import { CtaButton } from 'src/components/buttons';
import { Title, SiteCtaButtonStyle } from '../style';
import {
  CornerRibbon,
  LogoStyle,
  SilentLink,
  SiteHeaderStyle,
  SubHeaderStyle,
  SubLogoStyle,
} from './style';
import {
  PageSectionParagraphStyle,
  PageSectionTextStyle,
} from '../PageSection/style';
import { parseRichText } from 'src/util';

import GCLogo from 'src/img/GCHorizontalWhiteTransparent-01.png';
import ReversedLogo from 'src/img/svglogo/Logotype-Stacked-Reversed.svg';
import { imageByWidthHeight } from '../imageSize';
import { useTranslation } from 'react-i18next';
import { Video } from 'src/components/datadriven/ClaimGrid/hoverVideo';
import { HighlightArea, MainHighlights } from './highlight';

interface SiteHeaderProps {
  data: SiteHeaderStoryblok;
}

export function SiteHeader(props: SiteHeaderProps) {
  const { isMobile, windowSize } = useWindowSize();
  const { t } = useTranslation();
  const { data } = props;
  const cutout = ArtCutout(CutoutLocation.bottom, isMobile);
  const videoHeight = isMobile
    ? 460
    : Math.max((windowSize.width * 9) / 16, 500);
  const videoWidth = (videoHeight * 16) / 9;
  return (
    <div>
      <SiteHeaderStyle
        key={data._uid}
        imageUrl={
          data?.image?.filename &&
          (isMobile
            ? imageByWidthHeight(data.image.filename, windowSize.width, 500)
            : data.image.filename)
        }
      >
        <Video
          imgHeight={videoHeight}
          imgWidth={videoWidth}
          videoUrl="https://player.vimeo.com/video/666111859?h=33185a20b4"
          autoplay
        />
        <SilentLink to="/mining">
          <CornerRibbon>
            <h6>{t('section.siteHeader.miningClaimSale')}</h6>
            <h6>{t('section.siteHeader.nowOnSale')}</h6>
          </CornerRibbon>
        </SilentLink>
        <SilentLink to="/mining">
          <LogoStyle src={ReversedLogo} />
        </SilentLink>
        <SilentLink to="/about/get-game">
          <SubHeaderStyle>{t('section.siteHeader.poweredBy')}</SubHeaderStyle>
          <SubLogoStyle src={GCLogo} />
        </SilentLink>
        {cutout}
      </SiteHeaderStyle>
      <HighlightArea highlights={MainHighlights} />
      <PageSectionTextStyle
        layout={StoryblokDesktopLayout.full}
        isFullWidth={true}
        hasCta={data?.cta?.length > 0}
      >
        {data.section_title && <Title>{data.section_title}</Title>}
        {data.text_area && (
          <PageSectionParagraphStyle
            layout={StoryblokDesktopLayout.full}
            isFullWidth={true}
          >
            {parseRichText(data.text_area)}
          </PageSectionParagraphStyle>
        )}
        {data.cta && data.cta.length > 0 && (
          <SiteCtaButtonStyle $header>
            {data.cta &&
              data.cta.map((button) => (
                <CtaButton data={button} key={button._uid} />
              ))}
          </SiteCtaButtonStyle>
        )}
      </PageSectionTextStyle>
    </div>
  );
}
