import { theme } from 'src/theme';
import styled from 'styled-components';

export const EarnLi = styled.li({
  textAlign: 'left',
  width: '100%',
  fontWeight: theme.fontWeights.light,
  fontSize: theme.fontSizes[1],
});

export const EarnP = styled.p({ display: 'inline' });

export const EarnDiv = styled.div({ display: 'inline' });
