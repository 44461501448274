import React from 'react';
import { theme } from 'src/theme';
import {
  ButtonType,
  StoryblokComponentType,
  StoryblokLinkType,
} from 'src/typings';
import styled from 'styled-components';
import { CtaButton } from '../CtaButton';

export interface NavButtonProps {
  text: string;
  type: ButtonType;
  link: string;
  padded?: boolean;
  isNarrow?: boolean;
  tight?: boolean;
  onClick?: () => void;
}

export function NavButton(props: NavButtonProps) {
  const { text, type, link, isNarrow, padded, tight, onClick } = props;
  const ctaButton = (
    <CtaButton
      data={{
        _uid: 'get-game-cta-button',
        text: text,
        type: type,
        link: {
          cached_url: link,
          linktype: StoryblokLinkType.story,
        },
        component: StoryblokComponentType.NavButton,
      }}
      isNarrow={isNarrow}
      tight={tight}
      onClick={onClick}
    />
  );
  return padded ? <NavButtonHolder>{ctaButton}</NavButtonHolder> : ctaButton;
}

const NavButtonHolder = styled.div({
  margin: `${theme.spacing.mobileSingle} auto`,
});
