import { JsonRpcSigner } from '@ethersproject/providers';

export const switchToPolygon = async (
  signer: JsonRpcSigner,
): Promise<boolean> => {
  if (window.ethereum) {
    try {
      console.log('switch to polygon');
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x89',
            chainName: 'Matic(Polygon) Mainnet',
            rpcUrls: ['https://polygon-rpc.com'],
            iconUrls: [
              'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0/logo.png',
            ],
            blockExplorerUrls: ['https://polygonscan.com/'],
            nativeCurrency: {
              name: 'Matic Token',
              symbol: 'MATIC',
              decimals: 18,
            },
          },
        ], // you must have access to the specified account
      });
      const chainId = await signer.getChainId();
      return chainId === 137;
    } catch (error) {
      if (error.code === 4001) {
        // EIP-1193 userRejectedRequest error
        console.log('We can encrypt anything without the key.');
      } else {
        console.error(error);
      }
      return false;
    }
  }
  return false;
};
