import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reloadAccountDataAction } from 'src/redux/Account/Actions';
import { AppState } from 'src/redux/CreateStore';
import { refreshClaimsAction } from 'src/redux/Stories/Actions';
import { ClaimStoryblok } from 'src/typings';
import { useInterval } from 'src/util';

// The Cron runs timer(s), every second. If it finds something it needs to update, it fires off an event.
export function Cron() {
  const dispatch = useDispatch();
  const globalClaims = useSelector((state: AppState) => state.Stories.claims);
  const [nextClaimTime, setNextClaimTime] = useState<number>(
    Number.MAX_SAFE_INTEGER,
  );
  const [lastClaimTime, setLastClaimTime] = useState<number>(
    Number.MAX_SAFE_INTEGER,
  );
  const [lastCronMulticall, setLastCronMulticall] = useState<number>(0);
  const interval = Math.random() * 200 + 900;

  function cronTests() {
    const now = Date.now() / 1000;
    if (nextClaimTime <= now) {
      setNextClaimTime(Number.MAX_SAFE_INTEGER);
      dispatch(refreshClaimsAction());
    }
    setLastCronMulticall(lastCronMulticall + interval);

    const multicallThreshold = lastClaimTime > now - 90000 ? 3000 : 10000;

    // Don't multicall more than once every 10s
    if (lastCronMulticall > multicallThreshold) {
      setLastCronMulticall(0);
      dispatch(reloadAccountDataAction());
    }
  }

  // We add a small amount of randomness here so that we don't have every client updating in lockstep
  useInterval(() => {
    cronTests();
  }, interval);

  useEffect(() => {
    const claims = Object.values(globalClaims);
    if (claims?.length > 0) {
      const now = Date.now() / 1000;
      let prevOnSale = 0;
      const nextClaim: ClaimStoryblok = Object.values(globalClaims).reduce(
        function (prev: ClaimStoryblok, curr: ClaimStoryblok) {
          if (curr.onSale < now && curr.onSale > prevOnSale) {
            prevOnSale = curr.onSale;
          }
          return curr.onSale > now &&
            (curr.onSale < prev.onSale || prev.onSale < now)
            ? curr
            : prev;
        },
      );
      if (nextClaimTime != nextClaim.onSale && nextClaim.onSale > now) {
        const newClaimTime = nextClaim.onSale;
        console.log(
          'setting next claim on sale',
          nextClaim.name,
          nextClaim.onSale,
          nextClaimTime,
          newClaimTime,
          now,
        );
        setNextClaimTime(Math.floor(newClaimTime));
      }
      setLastClaimTime(Math.floor(prevOnSale));
      console.log('setting last claim on sale', prevOnSale, now);
    }
  }, [globalClaims]);

  return <> </>;
}
