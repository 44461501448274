import { store } from 'src';
import { sendTransaction } from './sendTransaction';
import {
  TransactionData,
  TransactionStatus,
  TransactionType,
} from './transactionTypes';

type collectEmissionsTx = TransactionData<[]>;

// Assumption here is that all amount checks have already taken place
export async function collectMiningEmissionsTx() {
  const { Contracts } = store.getState();
  const { world } = Contracts.contracts;
  if (!world) {
    throw 'worldTx store failure: no world contract ';
  }

  // the callback on a successful receipt
  async function callback(tx: collectEmissionsTx) {
    console.log('callback fn', tx.type);
  }

  function onError(error: unknown, tx: collectEmissionsTx) {
    console.log('worldTx error', error, tx.receipt);
  }

  console.log('buyNFTWithGame starting contract call');
  const transaction: collectEmissionsTx = {
    type: TransactionType.CollectMiningEmissions,
    status: TransactionStatus.created,
    function: world.collectEmissions,
    params: [],
  };
  // The contract call via metamask
  try {
    console.log('worldTx sending tx', transaction);
    sendTransaction(transaction, callback, onError);
  } catch (error) {
    console.log(error);
  }
}
