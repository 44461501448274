// External
import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

// Views
import { Helmet } from 'src/components/helmet';
import { theme } from 'src/theme';
import StoryPage from 'src/components/cmsdriven';
import { ScrollToTop } from 'src/util';
import { EmailSignupModal } from 'src/components/modals';
import { useWindowSize } from 'src/hooks/useWindowSize';
import useModal from 'src/hooks/useModal';
import ClaimPage from 'src/components/page/ClaimPage.tsx';
import { Routes, Route, useLocation } from 'react-router-dom';
import { MulticallHandler } from 'src/util/MulticallHandler';

export const AppRouter: React.FC = () => {
  const location = useLocation();
  const { isMobile } = useWindowSize();
  const [showNewsLetter, setShowNewsLetter] = useState(
    !localStorage.getItem('newsletter') && !isMobile,
  );
  const { visible: newsLetterVisible, show: newsLetterShow } = useModal();

  useEffect(() => {
    if (location.hash === '#signup') {
      setShowNewsLetter(true);
      newsLetterShow(true);
    }
  }, []);

  const showNewsLetterFunc = () => {
    if (localStorage.getItem('newsletter')) {
    } else {
      setShowNewsLetter(!localStorage.getItem('newsletter'));
      localStorage.setItem('newsletter', 'true');
      newsLetterShow(true);
    }
  };

  useEffect(() => {
    let lastScrollTop = 0;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const scrollEvent: any = window.addEventListener('scroll', () => {
      if (lastScrollTop > document.getElementsByTagName('html')[0].scrollTop) {
        if (isMobile) showNewsLetterFunc();
      }
      lastScrollTop = document.getElementsByTagName('html')[0].scrollTop;
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const exitContentEvent: any = document.addEventListener('mouseout', (e) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!(e as any).toElement && !e.relatedTarget) {
        if (!isMobile) showNewsLetterFunc();
      }
    });

    return () => {
      document.removeEventListener('mouseout', exitContentEvent);
      window.removeEventListener('scroll', scrollEvent);
    };
  }, []);

  // Note to devs: <Helmet> and other <head> tags must NOT be run through i18n localization
  // All <meta>, <title> etc tags must be in English.
  return (
    <ThemeProvider theme={theme}>
      <Helmet
        url={`https://genesis.game${location.pathname}`}
        title={'The 100 year Metaverse'}
        description={
          'Welcome to a metaverse where everyone belongs. Discover endless Worlds. Design an epic quest. Create the universe of your dreams.'
        }
        image={
          'https://a.storyblok.com/f/122154/3840x2160/3bdd8cbff3/bitcoin_01.jpg'
        }
        alt={''}
      />
      {showNewsLetter && (
        <EmailSignupModal
          visible={newsLetterVisible}
          show={newsLetterShow}
          $mobile={isMobile}
        />
      )}
      <ScrollToTop />
      <MulticallHandler />
      <Routes>
        <Route path="/" element={<StoryPage />} />
        <Route path="mining/claim/:id" element={<ClaimPage />} />
        <Route path="*" element={<StoryPage />} />
      </Routes>
    </ThemeProvider>
  );
};
