export enum Routes {
  account = 'account',
  community = 'community',
  earn = 'earn',
  play = 'play',
  build = 'build',
  learn = 'learn',
  farming = 'farming',
  home = '',
  metaverse = 'metaverse',
  mining = 'mining',
  staking = 'staking',
  tokens = 'tokens',
  characters = 'characters',
  explore = 'explore',
  land = 'land',
  crafting = 'crafting',
  getGenesis = 'about/get-genesis',
  getGame = 'about/get-game',
  aboutWorlds = 'about/mining-claims',
}

export const MenuRoutes = [
  Routes.home,
  Routes.account,
  Routes.earn,
  // Routes.build,
  // Routes.play,
  // Routes.learn,
  Routes.farming,
  Routes.mining,
  Routes.staking,
  Routes.tokens,
  Routes.metaverse,
  Routes.community,
];

export const MainRoutes = [
  Routes.earn,
  // Routes.build,
  // Routes.play,
  // Routes.learn,
  Routes.farming,
  Routes.mining,
  Routes.staking,
  Routes.tokens,
  Routes.metaverse,
  Routes.community,
];

export const MainSubRoutes = {
  // [Routes.earn]: [Routes.farming, Routes.mining, Routes.staking],
  // [Routes.build]: [Routes.build, Routes.land, Routes.crafting],
  [Routes.play]: [Routes.characters, Routes.explore],
  // [Routes.learn]: [Routes.tokens, Routes.metaverse, Routes.community],
};

export const ReverseSubRoutes = {};
Object.keys(MainSubRoutes).map((route) => {
  const subRoutes = MainSubRoutes[route];
  subRoutes.map((subRoute) => (ReverseSubRoutes[subRoute] = route));
});

export const MainSubRoutesVisible = {
  [Routes.earn]: false,
  [Routes.build]: false,
  [Routes.play]: false,
  [Routes.learn]: false,
};

export const HeaderRoutes = [
  Routes.home,
  Routes.account,
  Routes.farming,
  Routes.mining,
  Routes.staking,
  Routes.metaverse,
  Routes.tokens,
  Routes.community,
];

export const headerFirst = Routes.farming;
