import erc20Abi from './erc20.json';
import farmAbi from './farm.json';
import governanceAbi from './governance.json';
import worldAbi from './world.json';
import pairAbi from './pair.json';
import claimGuardAbi from './claimGuard.json';

const abis = {
  erc20: erc20Abi,
  farm: farmAbi,
  governance: governanceAbi,
  world: worldAbi,
  pair: pairAbi,
  claimGuard: claimGuardAbi,
};

export default abis;
