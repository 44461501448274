import styled from 'styled-components';
import { theme } from 'src/theme';

export interface MenuOpenProps {
  $menuOpen?: boolean;
}

export interface MobileProps {
  $mobile?: boolean;
}

export interface TitleProps {
  $header?: boolean;
}

export interface MainProps {
  $main?: boolean;
}

export const Results = styled.div<
  MobileProps & { full?: boolean; card?: boolean }
>(
  (props) => ({
    flex: '1 0 0%',
    width: '100%',
    height: props.full
      ? '100%'
      : props.$mobile
      ? 'calc(100vh - 48px)'
      : 'calc(100vh - 72px)',
  }),
  (props) =>
    props.full
      ? props.card
        ? {
            display: 'flex',
            flexDirection: props.$mobile ? 'column' : 'row',
            padding: props.$mobile ? '16px' : '24px 0 40px',
          }
        : {}
      : {
          display: 'flex',
          flexDirection: 'column',
          fontFamily: 'Montserrat',
          fontStyle: 'normal',
        },
);

export const TagRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const TagContainer = styled.div`
  height: 32px;
  background-color: ${theme.colors.darkGrey};
  padding: 0 8px;
  display: flex;
  align-items: center;
  margin: 4px 8px 4px 0;

  font-size: 14px;
  line-height: 16px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.white};
`;

export const VerticalSpacer = styled.div`
  width: 100%;
  height: ${theme.spacing.mobileSingle};
`;

export const HorizontalSpacer = styled.div`
  height: 100%;
  width: ${theme.spacing.mobileSingle};
`;
