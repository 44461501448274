import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import { Media } from 'src/constants';
import { theme } from 'src/theme';

type WrapperProps = SpaceProps &
  ColorProps & { expanded?: boolean; first?: boolean };

export const FaqSectionStyle = styled.div<WrapperProps>(
  () => ({
    margin: 'auto',
    cursor: 'pointer',
    maxWidth: theme.spacing.maxContentWidth,
    padding: `${theme.spacing.mobileSingle}`,
    [Media.desktop]: {
      padding: `${theme.spacing.desktopSingle} ${theme.spacing.desktopDouble}`,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }),
  space,
  color,
);

export const FaqEntryStyle = styled.div<WrapperProps>(
  (props) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderTop: props.first && '1px solid',
    borderBottom: '1px solid',
    padding: `${theme.spacing.mobileSingle} 0`,
  }),
  space,
  color,
);

export const FaqEntryHeader = styled.h4<WrapperProps>(() => ({}), space, color);

export const FaqEntryText = styled.div<WrapperProps>(
  (props) => ({
    transition: 'max-height 0.3s ease-in-out',
    maxHeight: props.expanded ? '500px' : 0,
    overflow: 'hidden',
  }),
  space,
  color,
);

export const FaqButton = styled.div<WrapperProps>(
  () => ({
    padding: `${theme.spacing.mobileSingle} 0`,
    margin: 'auto',
  }),
  space,
  color,
);
