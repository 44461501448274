import { BigNumber } from 'ethers';

export * from './balances';
export * from './calculateFarmApr';
export * from './connectWallet';
export * from './Enums';
export * from './hubspot';
export * from './MarketingUtils';
export * from './numberFormat';
export * from './promises';
export * from './ScrollToTop';
export * from './storyblok';
export * from './switchToPolygon';
export * from './useInterval';

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const accountDisplayName = (accountName, myAccount) => {
  return accountName === myAccount
    ? 'Me'
    : accountName.substring(0, 14) + '...';
};

// Util function to update items in an array that match a predicate
// Often used when we have keyed items stored in an array rather than a hash, and we just want to update one of them
export function updateIf<T>(
  items: ReadonlyArray<T>,
  predicate: (item: T) => boolean,
  map: (item: T) => T,
): ReadonlyArray<T> {
  return items.map((item) => (predicate(item) ? map(item) : item));
}

export const floatToWei = (amount: string) => {
  const parts = amount.split('.');
  const numberString = BigNumber.from(parts[0]).mul(BigNumber.from(10).pow(18));
  const decString =
    parts.length === 1
      ? BigNumber.from(0)
      : BigNumber.from(parts[1] || '0').mul(
          BigNumber.from(10).pow(18 - (parts.length > 1 ? parts[1].length : 0)),
        );
  return numberString.add(decString).toString();
};
