import React from 'react';
import PropTypes from 'prop-types';

import { LoadingIndicator } from '../loading';
import { PageStyle } from './style';
import { Helmet, HelmetProps } from 'src/components/helmet';
import { isHeadless } from 'src/redux';
import { Footer } from '../footer';
import { Header } from '../header';

export const PageFrame = ({ loading, children, helmet }) => {
  const helm = helmet as HelmetProps;

  // In headless mode, with react-snap, only render the loading indicator
  return (
    <>
      <Helmet
        title={helm.title}
        description={helm.description}
        image={helm.image}
        alt={helm.alt}
        url={helm.url}
      />
      {loading || isHeadless ? (
        <LoadingIndicator />
      ) : (
        <>
          <Header />
          <PageStyle>{children}</PageStyle>
          <Footer />
        </>
      )}
    </>
  );
};

PageFrame.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  helmet: PropTypes.object,
};
