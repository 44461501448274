import React from 'react';
import { Helmet as ReactHelmet } from 'react-helmet';

export interface HelmetProps {
  url?: string;
  image?: string;
  alt?: string;
  title?: string;
  description?: string;
}

const defaultTitle = 'Genesis Worlds';

export const Helmet = (props: HelmetProps) => {
  const { url, image, alt, title, description } = props;
  const fullTitle = title ? `${defaultTitle}: ${title}` : '';
  return (
    <ReactHelmet>
      {fullTitle && <title>{fullTitle}</title>}
      {description && <meta name="description" content={description} />}

      {fullTitle && <meta name="twitter:card" content="summary_large_image" />}
      {url && <meta name="twitter:url" content={url} />}
      {fullTitle && <meta name="twitter:title" content={fullTitle} />}
      {description && <meta name="twitter:description" content={description} />}
      {image && <meta name="twitter:image" content={image} />}
      {image && <meta property="og:image" content={image} />}
      {alt && <meta property="og:image:alt" content={alt} />}
      {url && <meta property="og:url" content={url} />}
      {description && <meta property="og:description" content={description} />}
      {fullTitle && <meta property="og:title" content={fullTitle} />}
    </ReactHelmet>
  );
};
