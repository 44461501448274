import React from 'react';
import styled from 'styled-components';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { theme } from 'src/theme';

const Container = styled.div({
  position: 'relative',
  width: '100%',
  border: `2px solid ${theme.colors.lightGrey}`,

  '&:hover': {
    border: `2px solid ${theme.colors.blue}`,
    color: theme.colors.blue,
    cursor: 'pointer',
  },
});

const Toggle = styled.h6({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
  fontWeight: theme.fontWeights.bold,
  cursor: 'pointer',
  userSelect: 'none',
});

const TitleContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const ToggleIcon = styled.div({
  pointerEvents: 'none',
});

const Title = styled.div({
  marginLeft: '10px',
});

interface ContentProps {
  expanded: boolean;
}

const Content = styled.div<ContentProps>((props) => ({
  position: 'relative',
  zIndex: 10,
  display: props.expanded ? 'flex' : 'none',
  flexRow: 'wrap',
  transition: 'transform 0.3s ease-in-out',
  transform: props.expanded ? 'translateX(0)' : 'translateX(-100%)',
}));

const ToggleClose = styled.div({
  position: 'absolute',
  width: '200vw',
  height: '200vh',
  top: '-100vh',
  left: '-100vw',
});

const DropdownFilter = ({ icon, caption, content, expanded, onClick }) => {
  return (
    <Container>
      <Toggle onClick={onClick}>
        <TitleContainer>
          {icon}
          <Title>{caption}</Title>
        </TitleContainer>
        <ToggleIcon>{expanded ? <FaAngleUp /> : <FaAngleDown />}</ToggleIcon>
      </Toggle>
      <Content expanded={expanded}>
        {content}
        <ToggleClose onClick={onClick}></ToggleClose>
      </Content>
    </Container>
  );
};

DropdownFilter.propTypes = {
  icon: PropTypes.node,
  caption: PropTypes.string,
  content: PropTypes.node,
  expanded: PropTypes.bool,
  onClick: PropTypes.func,
};

export default DropdownFilter;
