import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import { theme } from 'src/theme';
import { Media } from 'src/constants';
import { Link } from 'react-router-dom';

type WrapperProps = SpaceProps & ColorProps;

export const FooterStyle = styled.footer<WrapperProps>(
  () => ({
    background: theme.colors.midGrey,
    width: '100vw',
    paddingBottom: '80px',
    [Media.desktop]: {
      paddingBottom: '0px',
    },
  }),
  space,
  color,
);

export const Social = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;

  h1 {
    color: ${theme.colors.lightGrey};
    font-family: Montserrat;
    font-style: normal;
    font-weight: ${theme.fontWeights.bold};
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
`;

export const SocialSection = styled.div`
  width: 45%;
  max-width: 168px;
  ${Media.tablet} {
    width: 40%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 120px;
    max-width: 400px;
  }
`;

export const Disclaimer = styled.div`
  padding: ${theme.spacing.mobileSingle};
  margin: auto;
  color: ${theme.colors.lightGrey};
  p {
    font-size: ${theme.fontSizes[0]};
    line-height: ${theme.fontSizes[0]};
  }

  ${Media.desktop} {
    padding: ${theme.spacing.mobileSingle} ${theme.spacing.desktopDouble};
  }
`;

export const Links = styled.div`
  position: relative;
  margin-top: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 16px;

  p {
    font-family: Montserrat;
    font-size: ${theme.fontSizes[0]};
    line-height: ${theme.fontSizes[1]};

    display: flex;
    align-items: center;
    text-align: center;

    color: ${theme.colors.lightGrey};
    margin: auto;
    width: fit-content;
  }
`;

export const FooterLinkInternal = styled(Link)`
  font-family: Montserrat;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;

  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: underline;

  color: ${theme.colors.lightGrey};
  text-decoration: none;
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
    color: ${theme.colors.white} !important;

    h1 {
      color: ${theme.colors.white} !important;
    }
  }
  &:active {
    text-decoration: none;
  }
  h1 {
    margin-top: 8px;
  }
`;

export const FooterLinkExternal = styled.a`
  font-family: Montserrat;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;

  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: underline;

  color: ${theme.colors.lightGrey};
  text-decoration: none;
  &:link {
    text-decoration: none;
  }
  &:visited {
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
    text-underline-offset: 8px;
    color: ${theme.colors.white} !important;

    h1 {
      color: ${theme.colors.white} !important;
    }
  }
  h1 {
    margin-top: 8px;
  }
  &:active {
    text-decoration: none;
  }
`;

export const LinkContent = styled.p`
  display: flex;
  color: ${theme.colors.lightGrey};

  span {
    margin: 0 4px;
  }
`;

export const ContactSection = styled.div`
  background: ${theme.colors.lightGrey};
  padding: ${theme.spacing.mobileSingle};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${Media.tablet} {
    flex-direction: row;
  }
`;

export const StayUpToDate = styled.div`
  width: 100%;
  padding: ${theme.spacing.mobileSingle};

  ${Media.tablet} {
    width: 50%;
    max-width: 400px;
  }
`;

interface EmailSignupProps {
  isError: boolean;
}

export const EmailSignUp = styled.div<EmailSignupProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 ${theme.spacing.mobileSingle} ${theme.spacing.mobileSingle};

  ${Media.tablet} {
    width: 50%;
    max-width: 400px;
    padding: ${theme.spacing.mobileSingle};
  }
`;
