import React, { useEffect, useState } from 'react';
import useModal from 'src/hooks/useModal';
import { ButtonType } from 'src/typings';
import { FarmDepositModal, FarmHarvestModal } from '../../modals';
import { ActionButton } from 'src/components/buttons';
import { useSelector } from 'react-redux';
import { AppState } from 'src/redux/CreateStore';
import { DataTable, TableDataFormat, TableRowData } from '../../table';
import { GenesisTicker, TickerCurrency, TickerHolder } from './style';
import { FunctionalSectionStyle, TextSectionStyle } from '../style';
import { useTranslation } from 'react-i18next';
import { VerticalSpacer } from 'src/components';
import { FarmWithdrawModal } from 'src/components/modals/FarmWithdrawModal';
import {
  calculateCurrentGenesisEarned,
  calculateTotalGenesisEarned,
  ethToDecimal,
  useInterval,
  GenesisCurrencyLogo,
  calculateFarmApr,
} from 'src/util';
import { useSpring } from '@react-spring/core';

export interface MoralisBalances {
  gameBalance: string;
  farmBalance: string;
  genesisBalance: string;
}

export function FarmGenesisSection(props: { noHeader?: boolean }) {
  const { noHeader } = props;
  const { t } = useTranslation();
  const { visible: farmVisible, show: farmShow } = useModal();
  const { visible: harvestVisible, show: harvestShow } = useModal();
  const { visible: withdrawVisible, show: withdrawShow } = useModal();

  const signer = useSelector((state: AppState) => state.Contracts.signer);

  const myBalances = useSelector((state: AppState) => state.Account.myBalances);
  const globalBalances = useSelector(
    (state: AppState) => state.Contracts.globalBalances,
  );
  const prices = useSelector((state: AppState) => state.Contracts.prices);

  const farmData = useSelector((state: AppState) => state.Account.farmData);

  const farmApr = calculateFarmApr(
    globalBalances.farmGameBalance || '0',
    prices,
  );

  // Calculate friendly balances from Moralis; fallback is our stored data, so if Moralis is down, we still have numbers
  const friendlyGameBalance = ethToDecimal(myBalances.game);
  const friendlyFarmBalance = ethToDecimal(myBalances.farm);

  const friendlyGenesisPerDay =
    (farmApr.genesisPerDay * friendlyFarmBalance) /
    Math.max(farmApr.gameLocked, 1);

  const initialEarning = calculateTotalGenesisEarned();
  const [earnedBalance, setEarnedBalance] = useState(
    calculateCurrentGenesisEarned(farmData, friendlyGenesisPerDay),
  );
  const [totalEarned, setTotalEarnedTarget] = useState(initialEarning);
  const [totalEarnedInitialized, setTotalEarnedInitialized] = useState(false);

  useEffect(() => {
    setEarnedBalance(
      calculateCurrentGenesisEarned(farmData, friendlyGenesisPerDay),
    );
    setTotalEarnedInitialized(true);
  }, [farmData.isLoading]);

  useInterval(() => {
    setEarnedBalance(
      calculateCurrentGenesisEarned(farmData, friendlyGenesisPerDay),
    );
    setTotalEarnedTarget(calculateTotalGenesisEarned());
  }, 1000);

  const spring = useSpring({
    from: { val: initialEarning },
    val: totalEarned || initialEarning,
    config: { duration: 5000 },
  });

  const farmTitle = t('section.farm.farmtable.title');
  const isLoading = myBalances.isLoading;
  const farmRows: TableRowData[] = [
    {
      label: t('section.farm.farmtable.totalStaked'),
      data: farmApr.gameLocked,
      dataFormat: TableDataFormat.Game,
      usdRatio: farmApr.gamePrice,
      isLoading,
    },
    {
      label: t('section.farm.farmtable.genesisPerDay'),
      data: farmApr.genesisPerDay,
      dataFormat: TableDataFormat.Genesis,
      usdRatio: farmApr.genesisPrice,
      isLoading,
    },
    {
      label: t('section.farm.farmtable.apr'),
      data: farmApr.apr,
      dataFormat: TableDataFormat.percentage,
      isLoading: !farmApr.apr || farmApr.apr == 0,
    },
    {
      label: t('section.farm.farmtable.myGameToStake'),
      data: Math.floor(friendlyGameBalance),
      dataFormat: TableDataFormat.Game,
      usdRatio: farmApr.gamePrice,
      isLoading,
    },
  ];
  const farmButton = (
    <ActionButton
      type={ButtonType.primary}
      onClick={() => farmShow(true)}
      isDisabled={friendlyGameBalance == 0}
    >
      {t('button.farm')}
    </ActionButton>
  );
  const harvestTitle = t('section.farm.harvesttable.title');
  const harvestRows: TableRowData[] = [
    {
      label: t('section.farm.harvesttable.myGameStaked'),
      data: friendlyFarmBalance,
      dataFormat: TableDataFormat.Game,
      usdRatio: farmApr.gamePrice,
      isLoading,
    },
    {
      label: t('section.farm.harvesttable.myGenesisPerDay'),
      data: friendlyGenesisPerDay,
      dataFormat: TableDataFormat.Genesis,
      usdRatio: farmApr.genesisPrice,
      isLoading,
    },
    {
      label: t('section.farm.harvesttable.myFarmedGenesis'),
      data: earnedBalance,
      dataFormat: TableDataFormat.Genesis,
      usdRatio: farmApr.genesisPrice,
      isLoading: isLoading || farmData.isLoading || !totalEarnedInitialized,
    },
  ];
  const harvestButton = (
    <ActionButton
      type={ButtonType.secondary}
      onClick={() => harvestShow(true)}
      isDisabled={friendlyFarmBalance <= 0}
    >
      {t('button.harvest')}
    </ActionButton>
  );

  const withdrawButton = (
    <ActionButton
      type={ButtonType.secondary}
      onClick={() => withdrawShow(true)}
      isDisabled={friendlyFarmBalance <= 0}
    >
      {t('button.withdraw')}
    </ActionButton>
  );

  const farmHeader = (
    <TextSectionStyle>
      <h3>{t('section.farm.title')}</h3>
      <p>{t('section.farm.active', { apr: farmApr.apr.toFixed(2) })}</p>
    </TextSectionStyle>
  );

  const numberFormat = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <>
      {!noHeader && (
        <>
          {farmHeader}
          <h5>{t('section.farm.totalFarmed')}</h5>
          <TickerHolder>
            <GenesisTicker>
              {spring.val.to((val) => numberFormat.format(val))}
            </GenesisTicker>
            <TickerCurrency>{GenesisCurrencyLogo}</TickerCurrency>
          </TickerHolder>
          <VerticalSpacer />
        </>
      )}
      <FunctionalSectionStyle>
        <DataTable
          title={farmTitle}
          rows={farmRows}
          actionButton={farmButton}
        />
        {signer && (
          <DataTable
            title={harvestTitle}
            rows={harvestRows}
            actionButton={harvestButton}
            secondbutton={withdrawButton}
          />
        )}
      </FunctionalSectionStyle>
      <VerticalSpacer />
      <VerticalSpacer />

      <FarmDepositModal
        visible={farmVisible}
        show={farmShow}
        farmApr={farmApr}
      />
      <FarmHarvestModal
        visible={harvestVisible}
        show={harvestShow}
        farmApr={farmApr}
      />
      <FarmWithdrawModal
        visible={withdrawVisible}
        show={withdrawShow}
        farmApr={farmApr}
      />
    </>
  );
}
