import React, { useEffect } from 'react';
import { useMoralisQuery } from 'react-moralis';
import { batch } from 'react-redux';
import { store } from 'src';
import { ZERO_ADDRESS } from 'src/interfaces/Common';
import { updateTokenPricesAction } from 'src/redux/Contracts/Actions';
import { USDPrices } from 'src/redux/Contracts/Reducer';

declare global {
  interface Window {
    moralisBalances: { [address: string]: MoralisBalances };
  }
}

export interface MoralisBalances {
  address: string;
  gameBalance: string;
  farmBalance: string;
  genesisBalance: string;
  miningClaimBalance: number;
  miningBalances: string;
  votePower: string;
  votePowerTimestamp: number;
  createdAt: Date;
  updatedAt: Date;
  tokenPrices?: string;
  reserves?: string;
  worldBalances: string;
}

export const UnmemoizedDataManager = () => {
  const systemMoralisReturn = useMoralisQuery(
    'UserData',
    (query) => query.containedIn('address', [ZERO_ADDRESS]),
    [],
    {
      live: true,
    },
  );

  const { data: systemQueryData } = systemMoralisReturn as undefined as {
    data: { attributes: MoralisBalances }[];
  };

  useEffect(() => {
    if (systemQueryData?.length > 0) {
      onMoralisData(systemQueryData);
    }
  }, [systemQueryData]);

  function onMoralisData(systemData: { attributes: MoralisBalances }[]) {
    const zeroMoralisBalances = getBalanceForAddress(systemData, ZERO_ADDRESS);

    const prices: USDPrices = JSON.parse(
      zeroMoralisBalances.tokenPrices || '{}',
    );

    batch(() => {
      store.dispatch(
        updateTokenPricesAction({
          prices: prices,
        }),
      );
    });
  }

  return <div></div>;
};

function getBalanceForAddress(
  data: { attributes: MoralisBalances }[],
  address: string,
): MoralisBalances {
  return (data.find((bal) => bal.attributes.address == address)?.attributes ||
    {}) as unknown as MoralisBalances;
}

export const DataManager = React.memo(UnmemoizedDataManager);
