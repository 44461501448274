import React, { useEffect } from 'react';
import { ButtonType } from 'src/typings';
import { ModalBase, ModalProps, ModalHeader, ModalBody } from '.';
import { ActionButton, NavButton } from 'src/components/buttons';
import { useSelector } from 'react-redux';
import { AppState } from 'src/redux/CreateStore';
import { useState } from 'react';
import Input from '../numericalInput';
import { genesisDepositTx } from 'src/transactions/genesisDepositTx';
import { floatToWei, ethToDecimal } from 'src/util';
import { useTranslation } from 'react-i18next';
import { VerticalSpacer } from '..';
import { getGenesisBalanceFromContract } from 'src/calls';
import { Routes } from 'src/constants/routes';
import {
  depositStakeTable,
  GovernanceData,
} from '../datadriven/GovernanceStakingSection';

export function GenesisDepositModal(
  props: ModalProps & { governanceData: GovernanceData },
) {
  const { visible, show, governanceData } = props;
  const { t } = useTranslation();

  // Selectors
  const myBalances = useSelector((state: AppState) => state.Account.myBalances);
  const addy = useSelector((state: AppState) => state.Account.address);

  // State
  const [formAmount, setFormAmount] = useState('0.0');
  const [rpcGenesisBalance, setRpcGenesisBalance] = useState(BigInt(-1));

  // Getting the user's up-to-date Genesis balance, from the RPC node
  // This is used to cover the cases where the Moralis node doesn't update correctly
  // It's used here because Farm Deposit is a critical user function.
  const friendlyGenesisBalance =
    rpcGenesisBalance >= 0
      ? ethToDecimal(rpcGenesisBalance)
      : ethToDecimal(myBalances.genesis);

  // Update form and Genesis balance when the moralis Genesis balance changes
  // AND when the modal opens
  async function getGenesisBal() {
    const bal = await getGenesisBalanceFromContract(addy);
    console.log('got Genesis bal', bal.toString());
    setRpcGenesisBalance(BigInt(bal.toString()));
  }
  useEffect(() => {
    if (visible) {
      getGenesisBal();
    }
  }, [friendlyGenesisBalance, visible]);
  useEffect(() => {
    setFormAmount(friendlyGenesisBalance.toString());
  }, [friendlyGenesisBalance]);

  // Handler for sending the farm transaction.
  // Limit to the RPC Genesis balance, if known, or the Moralis Genesis balance otherwise
  const sendGenesisDepositTx = async () => {
    const maxGenesisBalance =
      rpcGenesisBalance >= 0 ? rpcGenesisBalance : myBalances.genesis;
    const formGenesisBalance = BigInt(floatToWei(formAmount));
    const depositAmount =
      maxGenesisBalance < formGenesisBalance
        ? maxGenesisBalance
        : formGenesisBalance;
    await genesisDepositTx(depositAmount.toString());
    show(false);
  };

  // Handler for form input
  function onFormAmountInput(input: string) {
    const endPoint = input.endsWith('.') ? '.' : '';
    if (isNaN(parseFloat(input))) {
      console.log('setting form amount nan');
      setFormAmount(`0${endPoint}`);
    } else {
      let inputNum = parseFloat(input);
      inputNum = inputNum <= 0.0 ? 0.0 : inputNum;
      if (inputNum > friendlyGenesisBalance) {
        setFormAmount(`${friendlyGenesisBalance}`);
      } else {
        setFormAmount(`${inputNum}${endPoint}`);
      }
    }
  }

  return (
    <ModalBase visible={visible} show={show}>
      <>
        <ModalHeader>
          <h3>{t('modal.stakingDeposit.title')}</h3>
        </ModalHeader>
        <ModalBody>
          {depositStakeTable(governanceData)}
          <VerticalSpacer />
          <p>{t('modal.stakingDeposit.choose')}</p>
          <Input value={formAmount} onUserInput={onFormAmountInput} />
          <ActionButton
            type={ButtonType.primary}
            onClick={sendGenesisDepositTx}
            isDisabled={parseFloat(formAmount) === 0}
          >
            {t('modal.stakingDeposit.button')}
          </ActionButton>
        </ModalBody>
        <ModalBody>
          <p>{t('modal.stakingDeposit.getGenesis')}</p>
          <NavButton
            text={t('button.getGenesis')}
            type={ButtonType.primary}
            link={Routes.getGenesis}
          />
        </ModalBody>
      </>
    </ModalBase>
  );
}
