import {
  TransactionReceipt,
  TransactionRequest,
  TransactionResponse,
} from '@ethersproject/providers';
import { PayableOverrides } from 'ethers';

export enum TransactionType {
  Approval = 'Approval',
  FarmDeposit = 'FarmDeposit',
  GenesisDeposit = 'GenesisDeposit',
  FarmHarvest = 'FarmHarvest',
  FarmWithdrawal = 'FarmWithdrawal',
  GenesisWithdrawal = 'GenesisWithdrawal',
  BuyNFTWithGAME = 'BuyNFTWithGAME',
  BuyNFTWithMATIC = 'BuyNFTWithMATIC',
  CollectMiningEmissions = 'CollectMiningEmissions',
  BurnNFT = 'BurnNFT',
  NFTBalance = 'NFTBalance',
  ApproveNFT = 'ApproveNFT',
}

export enum TransactionStatus {
  created = 0, // assembled, but not yet sent to Metamask
  signing = 1, // waiting for Metamask signature
  pending = 2, // sent to blockchain for processing
  success = 3,
  failed = 4,
  rejected = 5, // rejected by the user
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface TransactionData<T extends any[]> {
  type: TransactionType;
  status: TransactionStatus;
  function: (...params: T) => Promise<TransactionResponse>;
  params: T;
  overrides?: PayableOverrides;
  request?: TransactionRequest;
  response?: TransactionResponse;
  receipt?: TransactionReceipt;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TransactionError = any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyTransaction = TransactionData<any[]>;
