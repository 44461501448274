import { utils } from 'ethers';
import {
  AccountState,
  AllClaimData,
  MiningClaims,
} from 'src/redux/Account/Reducer';
import { ContractsState } from 'src/redux/Contracts/Reducer';
import { AnyTransaction } from 'src/transactions/transactionTypes';
import { ethToDecimal } from 'src/util';
import { ExtendedFarmData } from './ContractTypes';
import { Contracts } from './loadContracts';
import { store } from 'src';
import { MulticallPayload } from 'src/redux/Contracts/Actions';
import { ClaimStoryblok } from 'src/typings';
import {
  claimBurnPrices,
  ClaimData,
  claimTotalLocked,
  getClaimBuyPrice,
} from 'src/constants/worlds';

/*
  Parse claim balances into actual claim data (stories?)
  Figure out plan to get prices
*/
export async function multicallAllData() {
  const { Account, Contracts, Stories } = store.getState();
  const { claims } = Stories;
  const { address } = Account;
  const { multicall } = Contracts;
  const {
    multicallProvider,
    game,
    genesis,
    farm,
    governance,
    ethGame,
    quickGame,
    world,
    claimGuard,
  } = multicall;
  // await ethcallProvider.init(); // Only required when `chainId` is not provided in the `Provider` constructor

  const worldBalanceCalls = [];
  const worldSupplyCalls = [];
  for (let i = 1; i <= 35; i++) {
    worldBalanceCalls.push(world.balanceOf(address, i));
    worldSupplyCalls.push(world.tokenSupply(i));
  }

  const calls = [
    multicallProvider.getEthBalance(address),
    game.balanceOf(address),
    genesis.balanceOf(address),
    farm.balanceOf(address),
    farm.getBalances(address),
    game.balanceOf(farm.address),
    genesis.balanceOf(governance.address),
    governance.votePower(address),
    governance.totalVotePower(),
    ethGame.getReserves(),
    quickGame.getReserves(),
    world.userUpdateTimes(address),
    world.userEmissions(address),
    claimGuard.getLimits(),
    claimGuard.isAllowedToTransact(address),
  ];

  const [callResults, balanceRes, supplyRes] = await Promise.all([
    multicallProvider.all(calls),
    multicallProvider.all(worldBalanceCalls),
    multicallProvider.all(worldSupplyCalls),
  ]);

  const [
    maticBal,
    gameBal,
    genesisBal,
    farmBal,
    farmBalances,
    farmGameBalance,
    govGenesisBalance,
    votePower,
    totalVotePower,
    ethGameRes,
    quickGameRes,
    userUpdateTime,
    userEmissions,
    claimLimits,
    isAllowedToTransact,
  ] = callResults;

  const worldData = {
    myClaims: {},
    supply: {},
    myTotal: 0,
    globalTotal: 0,
  };
  balanceRes.map((bal, i) => {
    const balance = parseInt(bal);
    worldData.myClaims[i + 1] = balance;
    worldData.myTotal += balance;
  });
  supplyRes.map((bal, i) => {
    const balance = parseInt(bal);
    worldData.supply[i + 1] = balance;
    worldData.globalTotal += balance;
  });

  // Both pairs we currently have are listed with game as the second currency
  const reserves = {
    ethGame: {
      game: parseFloat(utils.formatEther(ethGameRes[1])),
      other: parseFloat(utils.formatEther(ethGameRes[0])),
      k: 0,
    },
    quickGame: {
      game: parseFloat(utils.formatEther(quickGameRes[1])),
      other: parseFloat(utils.formatEther(quickGameRes[0])),
      k: 0,
    },
  };
  reserves.ethGame.k = reserves.ethGame.game * reserves.ethGame.other;
  reserves.quickGame.k = reserves.quickGame.game * reserves.quickGame.other;

  const extendedFarmData: ExtendedFarmData = {
    gameBalance: farmBalances[0],
    genesisEarned: farmBalances[1],
    genesisPerGame: farmBalances[2],
    debt: farmBalances[3],
    friendlyGameBalance: ethToDecimal(farmBalances[0] || '0'),
    friendlyGenesisEarned: ethToDecimal(farmBalances[1] || '0') || 0,
    timestamp: Date.now().valueOf(),
    isLoading: false,
  };

  const accountData: AccountState = {
    address: address,
    allowances: {},
    myClaims: worldData.myClaims,
    maticBalance: BigInt(maticBal),
    myBalances: {
      game: BigInt(gameBal),
      genesis: BigInt(genesisBal),
      farm: BigInt(farmBal),
      governance: BigInt(votePower),
      world: BigInt(worldData.myTotal),
      isLoading: false,
    },
    lastEmission: userUpdateTime.toNumber(),
    worldEmission: BigInt(userEmissions),
    worldEmissionUpdated: Date.now(),
    farmData: extendedFarmData,
    isAllowedToTransact: {
      isAllowedByAllowList: isAllowedToTransact.isAllowedByAllowList,
      isAllowedByBlockList: isAllowedToTransact.isAllowedByBlockList,
      isAllowedToSell: isAllowedToTransact.isAllowedToSell,
    },
    error: null,
  };

  const contractsData: ContractsState = {
    contracts: {} as Contracts,
    totalClaims: worldData.supply,
    globalClaims: {},
    claimLimits: {
      first500: claimLimits?.first500?.toNumber() || 0,
      first1000: claimLimits?.first1000?.toNumber() || 0,
      first1500: claimLimits?.first1500?.toNumber() || 0,
      first2000: claimLimits?.first2000?.toNumber() || 0,
      timeBetween: claimLimits?.timeBetween?.toNumber() || 0,
    },
    reserves: reserves,
    globalBalances: {
      miningClaimTotal: BigInt(worldData.globalTotal),
      farmGameBalance: BigInt(farmGameBalance),
      govGenesisBalance: BigInt(govGenesisBalance),
      totalVotePower: BigInt(totalVotePower),
      isLoading: false,
    },
    mostRecentTx: {} as AnyTransaction,
    transactions: {},
  };

  const procesedClaims = processWorldData(
    claims,
    accountData.myClaims,
    worldData.supply,
  );
  contractsData.globalClaims = procesedClaims;

  const payload: MulticallPayload = {
    account: accountData,
    contracts: contractsData,
  };
  return payload;
}

export function processWorldData(
  claims: { [id: number]: ClaimStoryblok },
  myClaims: MiningClaims,
  globalClaims: MiningClaims,
): AllClaimData {
  // If we don't have both claim data and actual data, return
  if (!claims || !globalClaims || !claims[1] || !globalClaims[1]) {
    return {};
  }

  const now = Date.now() / 1000;
  if (!claims || !claims[1]) {
    // If we don't have the claim data yet, wait until we do.
    return {};
  }
  const startTime = claims[1].onSale + 604800;

  // Start time is 7 days after world #1 goes on sale.
  // We'll need to communicate this; mining doesn't start immediately on the first sale
  // This sets the reward time period to the start of the next LOCAL day
  const rewardPeriodStart =
    new Date(Math.max(0, now - startTime) * 1000).setHours(0, 0, 0, 0) / 1000 +
    60 * 60 * 24;
  const yearEnd = rewardPeriodStart + 365 * 60 * 60 * 24;
  const dayEnd = rewardPeriodStart + 60 * 60 * 24;
  const yearEmission =
    10000 *
    (yearEnd / (yearEnd + 63113904) -
      rewardPeriodStart / (rewardPeriodStart + 63113904));
  const dayEmission =
    10000 *
    (dayEnd / (dayEnd + 63113904) -
      rewardPeriodStart / (rewardPeriodStart + 63113904));
  const worldData: AllClaimData = {};
  Object.keys(claims).map((idstring) => {
    // TODO: Get the APR, PRICE, and totalSupply of each World.
    const id = +idstring;
    if (isNaN(id)) {
      return;
    }
    const { name, description, onSale, image, video } = claims[id];
    const totalSupply = globalClaims[id] || 0;
    const data: ClaimData = {
      video,
      name,
      description,
      art: image,
      onSale,
      id,
      yearEmission,
      dayEmission,
      totalSupply,
      buyPrice: getClaimBuyPrice(totalSupply),
      burnPrice: claimBurnPrices[totalSupply],
      myBalance: myClaims[id] || 0,
      gameLocked: claimTotalLocked[totalSupply],
    };
    worldData[id] = data;
  });
  return worldData;
}
