export const STORE_CONTRACTS = 'STORE_CONTRACTS';

export const GET_STORIES = 'GET_STORIES';
export const GET_STORIES_SUCCESS = 'GET_STORIES_SUCCESS';
export const GET_STORIES_ERROR = 'GET_STORIES_ERROR';

export const REFRESH_CLAIMS_ACTION = 'REFRESH_CLAIMS_ACTION';

export const SET_STORIES_LANGUAGE = 'SET_STORIES_LANGUAGE';

export const RELOAD_ACCOUNT_DATA = 'RELOAD_ACCOUNT_DATA';
export const RELOAD_ACCOUNT_DATA_RESULT = 'RELOAD_ACCOUNT_DATA_RESULT';

export const GET_ALLOWANCE = 'GET_ALLOWANCE';
export const GET_ALLOWANCE_SUCCESS = 'GET_ALLOWANCE_SUCCESS';
export const GET_ALLOWANCE_ERROR = 'GET_ALLOWANCE_ERROR';

export const CONNECT_ACCOUNT_SUCCESS = 'CONNECT_ACCOUNT_SUCCESS';
export const CONNECT_ACCOUNT_ERROR = 'CONNECT_ACCOUNT_ERROR';
export const DISCONNNECT_ACCOUNT = 'DISCONNNECT_ACCOUNT';

export const SET_FEATURE_TOGGLES = 'SET_FEATURE_TOGGLES';

export const UPDATE_TRANSACTION_DATA = 'UPDATE_TRANSACTION_DATA';
export const REMOVE_TRANSACTION_DATA = 'REMOVE_TRANSACTION_DATA';

export const UPDATE_TOKEN_PRICES_ACTION = 'UPDATE_TOKEN_PRICES_ACTION';
