import { useState } from 'react';

const useModal = () => {
  const [visible, setIsShowing] = useState(false);

  function show(isVisible: boolean) {
    setIsShowing(isVisible);
  }

  return { visible, show };
};

export default useModal;
