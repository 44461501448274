import ReactGA from 'react-ga';
import { store } from 'src';
import { uint256 } from 'src/interfaces/Common';
import { sendTransaction } from './sendTransaction';
import {
  TransactionData,
  TransactionStatus,
  TransactionType,
} from './transactionTypes';

type buyNftwithGameTx = TransactionData<
  [tokenId: uint256, maxPrice: uint256, buyAmount: uint256]
>;

// Assumption here is that all amount checks have already taken place
export async function buyNFTWithGameTx(
  tokenId: string,
  price: string,
  buyAmount: number,
) {
  // Add GA Event
  ReactGA.event({
    category: 'Buy',
    action: 'Buy NFT with Game',
  });
  const { Contracts } = store.getState();
  const { claimGuard } = Contracts.contracts;
  if (!claimGuard) {
    throw 'worldTx store failure: no claimGuard contract ';
  }
  console.log('buyNFTWithGameTx tokenId', tokenId);
  console.log('buyNFTWithGameTx price', price);
  console.log('buyNFTWithGameTx buyAmount', buyAmount);

  // the callback on a successful receipt
  async function callback(tx: buyNftwithGameTx) {
    console.log('callback fn', tx.type);
  }

  function onError(error: unknown, tx: buyNftwithGameTx) {
    console.log('worldTx error', error, tx.receipt);
  }

  console.log('buyNFTWithGame starting contract call');
  const transaction: buyNftwithGameTx = {
    type: TransactionType.BuyNFTWithGAME,
    status: TransactionStatus.created,
    function: claimGuard.buyNFTwithGAME,
    params: [tokenId, price, buyAmount.toString()],
    overrides: {
      gasLimit: (500000 + buyAmount * 10000).toString(),
    },
  };
  // The contract call via metamask
  try {
    console.log('worldTx sending tx', transaction);
    sendTransaction(transaction, callback, onError);
  } catch (error) {
    console.log(error);
  }
}
