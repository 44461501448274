import { Contracts } from 'src/contracts/loadContracts';
import { ContractsState } from './Reducer';
import { AccountState } from '../Account/Reducer';
import * as ActionTypes from '../ActionTypes';

export interface StoreContractsPayload {
  contracts: Contracts;
}

export interface MulticallPayload {
  account: AccountState;
  contracts: ContractsState;
}

export const updateTokenPricesAction = (data) => ({
  type: ActionTypes.UPDATE_TOKEN_PRICES_ACTION,
  data,
});

export const storeContractsDataAction = (data: StoreContractsPayload) => ({
  type: ActionTypes.STORE_CONTRACTS,
  data,
});

export const updateTransactionDataAction = (data) => ({
  type: ActionTypes.UPDATE_TRANSACTION_DATA,
  data,
});

export const removeTransactionDataAction = (data) => ({
  type: ActionTypes.REMOVE_TRANSACTION_DATA,
  data,
});
