import { store } from 'src';

export const getClaimSupplyFromContract = async (claimId: number) => {
  const { Contracts } = store.getState();
  const world = Contracts?.contracts?.world;
  if (!world) {
    console.log('no world contract', Contracts.contracts);
    return 0;
  }
  const contractReturn = await world.tokenSupply(claimId);
  return contractReturn.toNumber();
};
