import React from 'react';
import {
  DataTableBody,
  DataTableCell,
  DataTableHeader,
  DataTableLabel,
  DataTableNote,
  DataTableRow,
  DataTableCta,
  DataTableP,
} from './style';
import {
  displayGC,
  displayGenesis,
  displayMatic,
  displayUSD,
  decimalsPrecision8,
  displayMinutesAndSeconds,
} from 'src/util';
import { useTranslation } from 'react-i18next';

export interface TableData {
  title?: string;
  rows: TableRowData[];
  actionButton?: JSX.Element;
  secondbutton?: JSX.Element;
}

export enum TableDataFormat {
  usd,
  percentage,
  Genesis,
  Game,
  Matic,
  integer,
  Time,
  none,
}

export interface TableRowData {
  label: string;
  isLoading: boolean;
  data: number;
  dataFormat?: TableDataFormat;
  noteData?: number;
  noteFormat?: TableDataFormat;
  usdRatio?: number;
}

export function DataTable(table: TableData) {
  const { t } = useTranslation();
  const loadingText = t('section.farm.loading');
  return (
    <DataTableBody>
      {table.title && (
        <DataTableHeader>
          <h5>{table.title}</h5>
        </DataTableHeader>
      )}
      {table?.rows?.length > 0 &&
        table.rows.map((r) =>
          r.label ? (
            <TableRow key={r.label} data={r} loadingText={loadingText} />
          ) : null,
        )}
      {table.actionButton && <DataTableCta>{table.actionButton}</DataTableCta>}
      {table.secondbutton && <DataTableCta>{table.secondbutton}</DataTableCta>}
    </DataTableBody>
  );
}

function TableRow(props: { data: TableRowData; loadingText: string }) {
  const { loadingText } = props;
  const { label, data, dataFormat, noteData, noteFormat, usdRatio, isLoading } =
    props.data;
  if (isLoading || data === null || data === undefined) {
    return (
      <DataTableRow>
        <DataTableLabel>{label}</DataTableLabel>
        <DataTableCell>{loadingText}</DataTableCell>
      </DataTableRow>
    );
  } else {
    return (
      <DataTableRow>
        <DataTableLabel>{label}</DataTableLabel>
        {getDataCell(data, dataFormat)}
        {noteData ? (
          getDataCell(noteData, noteFormat)
        ) : (
          <DataTableNote>
            {usdRatio > 0 ? displayUSD(usdRatio * data) : <></>}
          </DataTableNote>
        )}
      </DataTableRow>
    );
  }
}

function getDataCell(data: number, dataFormat: TableDataFormat) {
  switch (dataFormat) {
    case TableDataFormat.none:
      return getDataCellData('');
    case TableDataFormat.percentage:
      return getDataCellData(`${data.toFixed(2)}%`);
    case TableDataFormat.integer:
      return getDataCellData(`${Math.floor(data)}`);
    case TableDataFormat.Game:
      return getDataCellData(displayGC(data));
    case TableDataFormat.Genesis:
      return getDataCellData(displayGenesis(data));
    case TableDataFormat.Matic:
      return getDataCellData(displayMatic(data));
    case TableDataFormat.Time:
      return getDataCellData(displayMinutesAndSeconds(data));
    default:
      return getDataCellData(decimalsPrecision8(data));
  }
}

function getDataCellData(text: string | JSX.Element) {
  return (
    <DataTableCell>
      <DataTableP>{text}</DataTableP>
    </DataTableCell>
  );
}
