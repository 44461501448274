import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { isHeadless } from 'src/redux';
import { AppState } from 'src/redux/CreateStore';
import { StoryStoryblok, ClaimStoryblok } from 'src/typings';
import styled from 'styled-components';
// components
import {
  Wrapper,
  LoaderBg,
  InnerLoader,
  InnerDivFirst,
  InnerDivSecond,
  InnerDivThird,
} from './style';

interface Props {
  isBgBlur?: boolean;
}

export const LoadingIndicator = ({ isBgBlur = false }: Props) => {
  const stories = useSelector((store: AppState) => store.Stories.stories);
  const claims = useSelector((store: AppState) => store.Stories.claims);

  // Note that this builds a link grid that's used by ract-snap
  // ONLY render this if we are running in headless mode (using react-snap)
  return (
    <Wrapper>
      <LoaderBg blur={isBgBlur}>
        {isHeadless && (
          <ReactSnapLinkGrid
            stories={Object.values(stories)}
            claims={Object.values(claims)}
          />
        )}
        <InnerLoader>
          <InnerDivFirst />
          <InnerDivSecond />
          <InnerDivThird />
        </InnerLoader>
      </LoaderBg>
    </Wrapper>
  );
};

// This is an awful hack, and there's better ways to execute this, I'm sure
// But because we load a lot of data on each render, puppeteering 100+ pages isn't efficient
// So, what we can do is put all the page links in the the loading screen, and then use that
// for building SEO with a modified react snap that follows links
const ReactSnapLinkGrid = ({
  stories,
  claims,
}: {
  stories: StoryStoryblok[];
  claims: ClaimStoryblok[];
}) => {
  // The text "link..." here is slightly longer than 2px, which ensures the pixels are always clickable.
  return (
    <ReactSnapGridStyle>
      {stories?.map((story) => (
        <ReactSnapLink to={`/${story.full_slug}`} key={story.full_slug}>
          link...
        </ReactSnapLink>
      ))}
      {claims?.map((claim) => (
        <ReactSnapLink to={`/mining/claim/${claim.id}`} key={claim.id}>
          link...
        </ReactSnapLink>
      ))}
    </ReactSnapGridStyle>
  );
};

const ReactSnapLink = styled(Link)({
  width: '1px',
  height: '1px',
  fontSize: '1px',
  lineHeight: '1px',
  clip: 'rect(0,0,1,1)',
});

const ReactSnapGridStyle = styled.div({
  opacity: 0,
  fontSize: '1px',
  lineHeight: '1px',
  position: 'absolute',
});
