import { getContractAddresses } from 'src/constants/contractAddresses';

export const contract = getContractAddresses();

export enum ContractNames {
  genesis = 'GENESIS',
  game = 'GAME',
  matic = 'MATIC',
  weth = 'WETH',
  usdc = 'USDC',
  usdt = 'USDT',
  quick = 'QUICK',
  governance = 'GOVERNACE',
}

export * from './ContractTypes';
