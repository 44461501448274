import React, { useState } from 'react';
import { ButtonType } from 'src/typings';
import { ModalBase, ModalProps, ModalHeader, ModalFooter, ModalBody } from '.';
import { ActionButton } from 'src/components/buttons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AppState } from 'src/redux/CreateStore';
import { DataTable, TableDataFormat, TableRowData } from '../table';
import { farmingHarvestTx } from 'src/transactions/farmingHarvestTx';
import { VerticalSpacer } from '..';
import {
  FarmApr,
  calculateCurrentGenesisEarned,
  ethToDecimal,
  useInterval,
} from 'src/util';

export function FarmHarvestModal(props: ModalProps & { farmApr: FarmApr }) {
  const { visible, show, farmApr } = props;
  const { t } = useTranslation();

  const myBalances = useSelector((state: AppState) => state.Account.myBalances);
  const farmData = useSelector((state: AppState) => state.Account.farmData);

  const friendlyFarmBalance = ethToDecimal(myBalances.farm);
  const friendlyGenesisPerDay =
    (farmApr.genesisPerDay * friendlyFarmBalance) /
    Math.max(farmApr.gameLocked, 1);
  const [earnedBalance, setEarnedBalance] = useState(
    calculateCurrentGenesisEarned(farmData, friendlyGenesisPerDay),
  );

  useInterval(() => {
    setEarnedBalance(
      calculateCurrentGenesisEarned(farmData, friendlyGenesisPerDay),
    );
  }, 1000);

  const sendHarvestTx = async () => {
    await farmingHarvestTx();
    show(false);
  };
  const isLoading = myBalances.isLoading;
  const harvestRows: TableRowData[] = [
    {
      label: t('modal.harvest.table.totalStaked'),
      data: farmApr.gameLocked,
      dataFormat: TableDataFormat.Game,
      isLoading,
    },
    {
      label: t('modal.harvest.table.genesisPerDay'),
      data: farmApr.genesisPerDay,
      dataFormat: TableDataFormat.Genesis,
      usdRatio: farmApr.genesisPrice,
      isLoading,
    },
    {
      label: t('modal.harvest.table.apr'),
      data: farmApr.apr,
      dataFormat: TableDataFormat.percentage,
      isLoading: !farmApr.apr || farmApr.apr == 0,
    },
    {
      label: t('modal.harvest.table.genesisToCollect'),
      data: earnedBalance,
      usdRatio: farmApr.genesisPrice,
      isLoading,
    },
  ];

  return (
    <ModalBase visible={visible} show={show}>
      <>
        <ModalHeader>
          <h3>{t('modal.harvest.title')}</h3>
        </ModalHeader>
        <ModalBody>
          <p>{t('modal.harvest.collect')}</p>
          <VerticalSpacer />
          <DataTable rows={harvestRows} />
        </ModalBody>
        <ModalFooter>
          <ActionButton
            type={ButtonType.primary}
            onClick={sendHarvestTx}
            isDisabled={friendlyFarmBalance === 0}
          >
            {t('modal.harvest.button')}
          </ActionButton>
        </ModalFooter>
      </>
    </ModalBase>
  );
}
