import React from 'react';
import useModal from 'src/hooks/useModal';
import { ButtonType } from 'src/typings';
import { GenesisWithdrawModal, GenesisDepositModal } from '../../modals';
import { ActionButton } from 'src/components/buttons';
import { useSelector } from 'react-redux';
import { AppState } from 'src/redux/CreateStore';
import { DataTable, TableDataFormat, TableRowData } from '../../table';
import { FunctionalSectionStyle, TextSectionStyle } from '../style';
import { useTranslation } from 'react-i18next';
import { VerticalSpacer } from 'src/components';
import { ethToDecimal } from 'src/util';

export interface MoralisBalances {
  gameBalance: string;
  farmBalance: string;
  genesisBalance: string;
}

export interface GovernanceData {
  genesisPerDay: number;
  genesisPrice: number;
  apr: number;
  totalStakedGenesis: number;
  totalGenesisPerDay: number;
  myGenesis: number;
  myStakedGenesis: number;
  myGenesisPerDay: number;
  isLoading: boolean;
}

export function calculateGovernanceGenesisPerDay(
  startTime: number,
  miningClaimTotal: bigint,
) {
  const now = Date.now() / 1000;

  const dayStart =
    new Date(Math.max(0, now - startTime) * 1000).setHours(0, 0, 0, 0) / 1000;
  const dayEnd = dayStart + 60 * 60 * 24;
  const dayEmission =
    (10000 *
      (dayEnd / (dayEnd + 63113904) - dayStart / (dayStart + 63113904))) /
    2;
  return dayEmission * Number(miningClaimTotal);
}

export function calculateGovernanceApr(
  genesisPerDay: number,
  govGenesisBalance: bigint,
) {
  return (genesisPerDay * 365 * 100) / ethToDecimal(govGenesisBalance);
}

export function GovernanceStakingSection() {
  const { t } = useTranslation();
  const { visible: withdrawVisible, show: withdrawShow } = useModal();
  const { visible: depositVisible, show: depositShow } = useModal();

  const signer = useSelector((state: AppState) => state.Contracts.signer);

  const myBalances = useSelector((state: AppState) => state.Account.myBalances);

  const globalBalances = useSelector(
    (state: AppState) => state.Contracts.globalBalances,
  );
  const prices = useSelector((state: AppState) => state.Contracts.prices);
  const { miningClaimTotal, govGenesisBalance } = globalBalances;

  const claims = useSelector((state: AppState) => state.Stories.claims);

  const startTime = claims[1].onSale + 604800 * 5; // 5 weeks after claim 1 on sale
  const genesisPerDay = calculateGovernanceGenesisPerDay(
    startTime,
    miningClaimTotal,
  );

  // Calculate friendly balances from Moralis; fallback is our stored data, so if Moralis is down, we still have numbers
  const myVotePower = ethToDecimal(myBalances.governance);
  const friendlyGenesisBalance = ethToDecimal(myBalances.genesis);
  const friendlyGovernanceBalance = ethToDecimal(
    globalBalances.govGenesisBalance,
  );
  const totalVotePower = ethToDecimal(globalBalances.totalVotePower);
  const myGovernanceBalance =
    totalVotePower === 0
      ? 0
      : (myVotePower * friendlyGovernanceBalance) / totalVotePower;

  const myGenesisPerDay =
    (genesisPerDay * myGovernanceBalance) /
    Math.max(friendlyGovernanceBalance, 1);

  // APR is simple - just per day * 365 / total staked
  const apr = calculateGovernanceApr(
    genesisPerDay,
    globalBalances.govGenesisBalance,
  );

  const governanceData: GovernanceData = {
    genesisPrice: prices.genesis,
    genesisPerDay: genesisPerDay,
    apr: apr,
    totalStakedGenesis: ethToDecimal(govGenesisBalance),
    totalGenesisPerDay: genesisPerDay,
    myGenesis: friendlyGenesisBalance,
    myStakedGenesis: myGovernanceBalance,
    myGenesisPerDay: myGenesisPerDay,
    isLoading: myBalances.isLoading,
  };

  const depositButton = (
    <ActionButton
      type={ButtonType.primary}
      onClick={() => depositShow(true)}
      isDisabled={friendlyGenesisBalance == 0}
    >
      {t('button.stake')}
    </ActionButton>
  );

  const withdrawButton = (
    <ActionButton
      type={ButtonType.secondary}
      onClick={() => withdrawShow(true)}
      isDisabled={myGovernanceBalance <= 0}
    >
      {t('button.withdrawStake')}
    </ActionButton>
  );

  const governanceHeader = (
    <TextSectionStyle>
      <h3>{t('section.governance.title')}</h3>
      <p>{t('section.governance.active')}</p>
      <p>
        <a href="https://docs.genesis.game/genesis-worlds-metaverse/general-information/genesis-worlds-whitepaper">
          {t('section.governance.learnMore')}
        </a>
      </p>
    </TextSectionStyle>
  );

  const depositTable = depositStakeTable(
    governanceData,
    t('section.governance.deposittable.title'),
    depositButton,
  );

  const withdrawTable = withdrawStakeTable(
    governanceData,
    t('section.governance.withdrawtable.title'),
    withdrawButton,
  );

  return (
    <>
      {governanceHeader}
      <FunctionalSectionStyle>
        {depositTable}
        {signer && withdrawTable}
      </FunctionalSectionStyle>
      <VerticalSpacer />
      <VerticalSpacer />

      <GenesisWithdrawModal
        visible={withdrawVisible}
        show={withdrawShow}
        governanceData={governanceData}
      />

      <GenesisDepositModal
        visible={depositVisible}
        show={depositShow}
        governanceData={governanceData}
      />
    </>
  );
}

export function depositStakeTable(
  data: GovernanceData,
  title?: string,
  button?: JSX.Element,
) {
  const {
    apr,
    genesisPrice,
    isLoading,
    myGenesis,
    totalGenesisPerDay,
    totalStakedGenesis,
  } = data;
  const { t } = useTranslation();

  const rows: TableRowData[] = [
    {
      label: t('section.governance.deposittable.totalStaked'),
      data: totalStakedGenesis,
      dataFormat: TableDataFormat.Genesis,
      usdRatio: genesisPrice,
      isLoading,
    },
    {
      label: t('section.governance.deposittable.genesisPerDay'),
      data: totalGenesisPerDay,
      dataFormat: TableDataFormat.Genesis,
      usdRatio: genesisPrice,
      isLoading,
    },
    {
      label: t('section.governance.deposittable.apr'),
      data: apr,
      dataFormat: TableDataFormat.percentage,
      isLoading: !apr || apr == 0,
    },
    {
      label: t('section.governance.deposittable.myGenesisToStake'),
      data: Math.floor(myGenesis),
      dataFormat: TableDataFormat.Genesis,
      usdRatio: genesisPrice,
      isLoading,
    },
  ];
  return <DataTable title={title} rows={rows} actionButton={button} />;
}

export function withdrawStakeTable(
  data: GovernanceData,
  title?: string,
  button?: JSX.Element,
) {
  const { apr, genesisPrice, isLoading, myGenesisPerDay, myStakedGenesis } =
    data;
  const { t } = useTranslation();
  const rows: TableRowData[] = [
    {
      label: t('section.governance.withdrawtable.myGenesisLocked'),
      data: myStakedGenesis,
      dataFormat: TableDataFormat.Genesis,
      usdRatio: genesisPrice,
      isLoading,
    },
    {
      label: t('section.governance.withdrawtable.genesisPerDay'),
      data: myGenesisPerDay,
      dataFormat: TableDataFormat.Genesis,
      usdRatio: genesisPrice,
      isLoading,
    },
    myStakedGenesis > 0 && {
      label: t('section.governance.deposittable.apr'),
      data: apr,
      dataFormat: TableDataFormat.percentage,
      isLoading: !apr || apr == 0,
    },
  ];
  return <DataTable title={title} rows={rows} actionButton={button} />;
}
