export const testNetOnSaleDates = {
  1: 1641281111,
  2: 1641282111,
  3: 1641283111,
  106: 33199444799,
  108: 33199444800,
  118: 33199444801,
  113: 33199444802,
  105: 33199444803,
  114: 33199444804,
  107: 33199444805,
  117: 33199444806,
  124: 33199444807,
  121: 33199444808,
};

/*
  4: 1641884911,
  5: 1642490393,
  6: 1643095193,
  7: 1643699993,
  8: 1644304793,
  9: 1644909593,
  10: 1645514393,
*/
