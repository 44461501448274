import * as React from 'react';
import { useInterval } from 'src/util';
import styled from 'styled-components';

export function Countdown(props: { toTime: number }) {
  const { toTime } = props;
  const [now, setNow] = React.useState(Date.now());

  useInterval(() => {
    setNow(Date.now());
  }, 100);

  // We add 2s to the countdown, so we hide more weird artefacts
  const millisecondsRemaining = Math.max(0, toTime * 1000 - now + 2000);
  const secondsRemaining = millisecondsRemaining / 1000;
  const s = Math.floor(secondsRemaining % 60);
  const m = Math.floor(secondsRemaining / 60) % 60;
  const h = Math.floor(secondsRemaining / 60 / 60) % 24;
  const d = Math.floor(secondsRemaining / 60 / 60 / 24);
  return (
    <CountdownContainer>
      {d}d : {h}h : {m < 10 ? '0' : ''}
      {m}m : {s < 10 ? '0' : ''}
      {s}s
    </CountdownContainer>
  );
}

export const CountdownContainer = styled.h5({
  textAlign: 'center',
  margin: 0,
  textTransform: 'lowercase',
  whiteSpace: 'nowrap',
  fontVariant: 'tabular-nums',
});
