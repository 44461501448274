import React from 'react';
import { theme } from 'src/theme';

export function failureSvg() {
  return (
    <svg
      id={'svg'}
      version={'1.1'}
      xmlns={'http://www.w3.org/2000/svg'}
      width="100"
      height="100"
      viewBox="0, 0, 400,400"
    >
      <g id="svgg">
        <path
          id="path0"
          d="M175.781 1.165 C 84.278 12.268,12.122 84.408,1.130 175.781 C -13.272 295.491,79.422 400.000,200.000 400.000 C 330.583 400.000,425.349 279.001,394.445 151.729 C 370.710 53.978,276.297 -11.032,175.781 1.165 M223.828 33.294 C 351.776 50.775,412.569 203.420,331.939 304.747 C 256.811 399.160,109.222 386.089,51.724 279.930 C -13.871 158.824,86.789 14.571,223.828 33.294 M142.372 128.873 C 133.039 131.148,127.922 141.542,131.669 150.611 C 132.466 152.542,140.520 161.192,155.692 176.414 L 178.516 199.313 155.776 221.727 C 131.844 245.316,130.456 247.067,130.486 253.634 C 130.538 265.120,141.676 272.668,152.174 268.331 C 154.108 267.532,162.703 259.517,177.918 244.325 L 200.757 221.519 224.011 244.644 C 249.279 269.771,249.610 270.016,257.462 269.365 C 267.542 268.529,273.791 259.062,271.020 248.828 C 270.313 246.217,266.364 241.879,247.019 222.461 L 223.864 199.219 246.221 176.758 C 258.517 164.404,269.134 153.191,269.813 151.840 C 276.043 139.452,265.510 125.824,252.106 128.930 C 248.200 129.836,247.161 130.753,224.598 153.211 L 201.150 176.551 178.895 154.238 C 153.361 128.636,150.758 126.829,142.372 128.873 "
          stroke="none"
          fill={theme.colors.gold}
          fillRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
