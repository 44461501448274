import './wdyr';

import React, { Suspense } from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import { MoralisProvider } from 'react-moralis';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactGA from 'react-ga';
import './index.css';
import { App } from './App';
import createStore from './redux';

import './i18n';
import 'swiper/swiper-bundle.css';
import { isProduction } from './constants';

ReactGA.initialize('G-17NR2ZKH9C');

export const { store, persistor } = createStore();

export type AppDispatch = typeof store.dispatch;

store.subscribe(() => {
  const state = store.getState();
  console.log('STORE CHANGED', state);
});

const rootElement = document.getElementById('root');

const isProd = isProduction();
const moralisServer = isProd
  ? process.env.REACT_APP_MORALIS_SERVER_PRODUCTION
  : process.env.REACT_APP_MORALIS_SERVER_TEST;
const moralisAppId = isProd
  ? process.env.REACT_APP_MORALIS_APPID_PRODUCTION
  : process.env.REACT_APP_MORALIS_APPID_TEST;

if ('scrollRestoration' in history) {
  // Back off, browser, I got this...
  history.scrollRestoration = 'manual';
}

if (isProd) {
  // https://docs.sentry.io/platforms/javascript/guides/react/performance/
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  });
}

const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback="loading">
        <MoralisProvider appId={moralisAppId} serverUrl={moralisServer}>
          <Sentry.ErrorBoundary
            fallback={<div>An error has occurred</div>}
            showDialog
          >
            <App />
          </Sentry.ErrorBoundary>
        </MoralisProvider>
      </Suspense>
    </PersistGate>
  </Provider>
);

if (rootElement.hasChildNodes()) {
  console.log('HYDRATING');
  hydrate(app, rootElement);
} else {
  console.log('NOT HYDRATING');
  render(app, rootElement);
}
