import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

// images
import LogoImg from 'src/img/svglogo/Logomark.svg';

// hooks
import { useWindowSize } from '../../hooks/useWindowSize';

// components
import {
  HeaderStyle,
  MobileMenu,
  MenuOption,
  Row,
  LogoImage,
  ButtonContainer,
  DesktopButton,
  AccountIconSVG,
  BlurDiv,
  MenuIconSVG,
  MenuBody,
  HeaderBackground,
  RightHandSide,
  MenuButton,
  DesktopMenuButton,
  MenuOptionExternal,
  DropDownContent,
  DropDownLi,
  SubLink,
  MenuOptionContent,
  MenuSubOptionContent,
  MenuRow,
  MenuOptionCaret,
  DownIcon,
  UpIcon,
  MenuBackground,
} from './style';
import {
  MainRoutes,
  MenuRoutes,
  MainSubRoutes,
  MainSubRoutesVisible,
  Routes,
  ReverseSubRoutes,
} from 'src/constants/routes';
import { SocialLinks } from 'src/constants/social';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'src/typings';
import { ConsoleButtons } from '../bottomConsole';
import { NavButton } from '../buttons';

export const Header = () => {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [childRoute, setChildRoute] = useState<Routes>(Routes.home);
  const [parentRoute, setParentRoute] = useState<Routes>(Routes.home);
  const [subMenuVisible, setSubMenuVisible] =
    useState<typeof MainSubRoutesVisible>(MainSubRoutesVisible);
  const { isMobile } = useWindowSize();

  const { t } = useTranslation();

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
    }
  }, [menuOpen]);

  useEffect(() => {
    const path = location.pathname.split('/') || ['', ''];
    const childRoute = Routes[path[1]];
    const parentRoute = ReverseSubRoutes[childRoute] || childRoute;
    setChildRoute(childRoute);
    setParentRoute(parentRoute);
    const visible = { ...MainSubRoutesVisible };
    visible[parentRoute] = true;
    setSubMenuVisible(visible);
  }, [location.pathname]);

  const handleSubMenuVisible = (val) => {
    const tempVal = { ...MainSubRoutesVisible };
    tempVal[val] = !subMenuVisible[val];
    setSubMenuVisible(tempVal);
  };

  const handleMenuClick = (event, url) => {
    event.stopPropagation();
    if (url === SocialLinks.DISCORD) {
    } else if (url === SocialLinks.WHITEPAPER) {
    } else {
      setMenuOpen(false);
    }
  };

  const getMenuLink = (url: string) => {
    if (url.indexOf('://') > 0) {
      return url;
    } else {
      return `/${url}`;
    }
  };

  const menuCtaRoute = Routes.mining;
  const menuCtaText = 'Buy Mining Claims';

  return (
    <HeaderStyle style={{ overflow: 'visible' }}>
      <HeaderBackground />
      <MobileMenu expanded={menuOpen}>
        <MenuBackground onClick={() => setMenuOpen(false)}></MenuBackground>
        <MenuBody>
          {MenuRoutes.map((route, index) => {
            const subRoutes = MainSubRoutes[route];
            const isSubMenu = subRoutes?.length > 0;
            const isSubMenuVisible = subMenuVisible[route];
            return (
              <MenuOption
                onClick={() => handleSubMenuVisible(route)}
                key={`menu-${index}`}
                $first={index === 0}
              >
                {/* Note: The route for "home" is "", which can't be used as a key, so we switch it to "home" here */}
                <MenuRow>
                  <MenuOptionContent
                    onClick={(e) => handleMenuClick(e, route)}
                    to={getMenuLink(route)}
                    $isActive={parentRoute === route}
                    $isMobile={isMobile}
                    $isSubMenu={isSubMenu}
                  >
                    {t(`menu.${Routes[route] || 'home'}`)}
                  </MenuOptionContent>
                  {subRoutes && subRoutes.length > 0 ? (
                    <MenuOptionCaret
                      $isActive={parentRoute === route}
                      $isMobile={isMobile}
                    >
                      {isSubMenuVisible ? <UpIcon /> : <DownIcon />}
                    </MenuOptionCaret>
                  ) : null}
                </MenuRow>
                {isSubMenuVisible &&
                  MainSubRoutes[route]?.map((subRoute, subIndex) => {
                    return (
                      <MenuSubOptionContent
                        key={`mobile-sub-button-${subIndex}`}
                        onClick={(e) => handleMenuClick(e, subRoute)}
                        to={getMenuLink(subRoute)}
                        $isActive={childRoute === subRoute}
                        $isMobile={isMobile}
                      >
                        {t(`menu.${Routes[subRoute] || 'home'}`)}
                      </MenuSubOptionContent>
                    );
                  })}
              </MenuOption>
            );
          })}
          <MenuOptionExternal
            key={`menu-docs`}
            $isActive={false}
            $isMobile={isMobile}
            onClick={(e) => handleMenuClick(e, SocialLinks.DOCS)}
            href={getMenuLink(SocialLinks.DOCS)}
            target="_blank"
          >
            {t('menu.docs')}
          </MenuOptionExternal>
          <MenuOptionExternal
            key={`menu-whitepaper`}
            $isActive={false}
            $isMobile={isMobile}
            onClick={(e) => handleMenuClick(e, SocialLinks.WHITEPAPER)}
            href={getMenuLink(SocialLinks.WHITEPAPER)}
            target="_blank"
          >
            {t('menu.whitepaper')}
          </MenuOptionExternal>
          <MenuButton
            key={`menu-cta`}
            $first={false}
            $isActive={false}
            $isMobile={isMobile}
          >
            <NavButton
              text={menuCtaText}
              type={ButtonType.purchase}
              link={menuCtaRoute}
              tight
              onClick={() => {
                setMenuOpen(false);
                setSubMenuVisible(MainSubRoutesVisible);
              }}
            />
          </MenuButton>
          <MenuOptionExternal
            key={`menu-discord`}
            $isActive={false}
            $isMobile={isMobile}
            onClick={(e) => handleMenuClick(e, SocialLinks.DISCORD)}
            href={getMenuLink(SocialLinks.DISCORD)}
            target="_blank"
          >
            {t('menu.joinDiscord')}
          </MenuOptionExternal>
        </MenuBody>
      </MobileMenu>
      <BlurDiv expanded={menuOpen}></BlurDiv>
      <Row
        $mobile={isMobile}
        flex={1}
        onClick={() => {
          setMenuOpen(false);
        }}
      >
        <Link to={'/'}>
          <LogoImage $mobile={isMobile} src={LogoImg} />
        </Link>
        <ButtonContainer>
          {MainRoutes.map((route, index) => {
            return (
              <DropDownLi key={`desktop-button-${index}`}>
                <DesktopButton
                  $mobile={isMobile}
                  $isActive={parentRoute === route}
                  onClick={(e) => handleMenuClick(e, route)}
                  to={getMenuLink(route)}
                  $isButton={false}
                >
                  {t(`menu.${Routes[route] || 'home'}`)}
                </DesktopButton>
                <DropDownContent>
                  {MainSubRoutes[route]?.map((subRoute, subIndex) => {
                    return (
                      <SubLink
                        key={`desktop-sub-button-${subIndex}`}
                        $mobile={isMobile}
                        $isActive={childRoute === subRoute}
                        onClick={(e) => handleMenuClick(e, subRoute)}
                        to={getMenuLink(subRoute)}
                        $isButton={false}
                      >
                        {t(`menu.${Routes[subRoute] || 'home'}`)}
                      </SubLink>
                    );
                  })}
                </DropDownContent>
              </DropDownLi>
            );
          })}
        </ButtonContainer>
        <RightHandSide>
          {!isMobile && <ConsoleButtons />}
          <DesktopButton
            $mobile
            $isRight
            $isButton={true}
            $isActive={false}
            onClick={(e) => {
              handleMenuClick(e, Routes.account);
            }}
            to={getMenuLink(Routes.account)}
          >
            <AccountIconSVG
              $isActive={parentRoute === Routes.account}
              $isMobile={isMobile}
              width="48"
              height="48"
              viewBox="0 0 48 48"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="24" cy="19" r="5" />
              <path d="M15 29C15 26.2386 17.2386 24 20 24H28C30.7614 24 33 26.2386 33 29V33H15V29Z" />
            </AccountIconSVG>
          </DesktopButton>
          <DesktopMenuButton
            $menuOpen={menuOpen}
            $isButton={true}
            $isActive={false}
            onClick={(e) => {
              e.stopPropagation();
              setMenuOpen(!menuOpen);
            }}
          >
            {menuOpen ? (
              <MenuIconSVG
                $menuOpen={menuOpen}
                width="20"
                height="20"
                viewBox="0 0 18 15"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0)">
                  <rect
                    x="3.34314"
                    y="-0.0710754"
                    width="18"
                    height="2"
                    transform="rotate(45 3.34314 -0.0710754)"
                  />
                  <rect
                    x="1.92896"
                    y="12.6569"
                    width="18"
                    height="2"
                    transform="rotate(-45 1.92896 12.6569)"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="18" height="14" fill="white" />
                  </clipPath>
                </defs>
              </MenuIconSVG>
            ) : (
              <MenuIconSVG
                $menuOpen={menuOpen}
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="15" y="17" width="18" height="2" />
                <rect x="15" y="23" width="18" height="2" />
                <rect x="15" y="29" width="18" height="2" />
              </MenuIconSVG>
            )}
          </DesktopMenuButton>
        </RightHandSide>
      </Row>
    </HeaderStyle>
  );
};
