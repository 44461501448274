import React from 'react';
import { ModalBase, ModalProps, ModalHeader, ModalBody } from '.';

export interface InformationModalProps extends ModalProps {
  title: string;
  information: JSX.Element;
}

export function InformationModal(props: InformationModalProps) {
  const { visible, show, title, information } = props;

  function onShowHide(isShowing: boolean) {
    show(isShowing);
  }

  return (
    <ModalBase visible={visible} show={onShowHide}>
      <>
        <ModalHeader>
          <h3>{title}</h3>
        </ModalHeader>
        <ModalBody>{information}</ModalBody>
      </>
    </ModalBase>
  );
}
