/* eslint-disable @typescript-eslint/no-explicit-any */
// @flow
import { isProduction, overrideToggles } from 'src/constants';
import { FeatureToggles, getDefaultToggles } from 'src/constants/featureToggle';
import { StoryblokFeatureTogggles } from 'src/typings';
import { SET_FEATURE_TOGGLES } from '../ActionTypes';
import { createReducer } from '../CreateReducer';

export type SettingsState = {
  featureToggles: FeatureToggles;
};

const INITIAL_STATE: SettingsState = {
  featureToggles: { ...getDefaultToggles() },
};

const reducers = {
  [SET_FEATURE_TOGGLES]: (
    state: SettingsState,
    { data }: { data: StoryblokFeatureTogggles },
  ) => {
    let toggles = { ...getDefaultToggles() };
    data.map((toggle) => {
      toggles[toggle.name] = toggle.value == 'true' ? true : false;
    });

    // We put the isProduction flag around the return so we can catch more errors in test/staging
    if (isProduction()) {
      toggles = overrideToggles(toggles);
      state.featureToggles = toggles;
    }

    return state;
  },
};

export const reducer = createReducer(INITIAL_STATE, reducers);

export default reducer;
