import React from 'react';
import { formatUnits } from '@ethersproject/units';
import styled from 'styled-components';
import GenesisLogo from 'src/img/Genesis-Currency.svg';
import GCLogo from 'src/img/GAME-Currency.png';
import MaticLogo from 'src/img/polygon-matic-logo.svg';
import { BigNumberish } from 'ethers';
import { theme } from 'src/theme';

export const displayMinutesAndSeconds = (s: number) => {
  return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
};

export const ethToDecimal = (ethAmount: BigNumberish) => {
  return Math.floor(parseFloat(formatUnits(ethAmount, 16))) / 100;
};

export const ethToInteger = (ethAmount: BigNumberish) => {
  return Math.floor(parseFloat(formatUnits(ethAmount, 18)));
};

export const noDecimals = (amount: number) => {
  return fixed0.format(Math.floor(amount) || 0);
};

export const threeSignificant = (amount: number) => {
  return max3significant.format(amount || 0);
};

export const decimalsBelowTenK = (amount: number) => {
  return amount > 10000 ? noDecimals(amount) : fixed2.format(amount || 0);
};

const max3significant = new Intl.NumberFormat('en-US', {
  maximumSignificantDigits: 3,
});
const fixed0 = new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });
const fixed2 = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 2,
});
const fixed4 = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 4,
});

export const decimalsPrecision8 = (amount: number) => {
  return amount >= 100000
    ? fixed0.format(Math.floor(amount))
    : amount >= 1000
    ? fixed2.format(amount)
    : amount > 0
    ? fixed4.format(amount)
    : '0';
};

// Create our number formatter.
export const usd = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export function shortUSD(amount: number) {
  const usdval = usd.format(amount);
  return amount >= 1000 || amount === 0 ? usdval.slice(0, -3) : usdval;
}

const CurrencyLogo = styled.img(() => ({
  display: 'inline-block',
  marginLeft: '2px',
  height: '16px',
  width: '16px',
}));

export const GameCurrencyLogo = <CurrencyLogo src={GCLogo} alt={'GAME'} />;
export const GameCurrencyText = (
  <span
    style={{ color: theme.secondaryColors.gameCreditsGreen, fontWeight: 700 }}
  >
    GAME
  </span>
);
export const GameCurrency = (
  <span style={{ whiteSpace: 'nowrap' }}>
    {GameCurrencyLogo}&nbsp;{GameCurrencyText}
  </span>
);

export const GenesisCurrencyLogo = (
  <CurrencyLogo src={GenesisLogo} alt={'GENESIS'} />
);
export const GenesisCurrencyText = (
  <span style={{ color: theme.colors.purple, fontWeight: 700 }}>GENESIS</span>
);
export const GenesisCurrency = (
  <span style={{ whiteSpace: 'nowrap' }}>
    {GenesisCurrencyLogo}&nbsp;{GenesisCurrencyText}
  </span>
);

export const MaticCurrencyLogo = <CurrencyLogo src={MaticLogo} alt={'MATIC'} />;
export const MaticCurrencyText = (
  <span style={{ color: theme.colors.purple, fontWeight: 700 }}>MATIC</span>
);

export const MaticCurrency = (
  <span style={{ whiteSpace: 'nowrap' }}>
    {MaticCurrencyLogo}&nbsp;{MaticCurrencyText}
  </span>
);

const WholeUSD = styled.span({
  display: 'inline-block',
  whiteSpace: 'nowrap',
});

const WholeGC = styled.span({
  display: 'inline-block',
  whiteSpace: 'nowrap',
});

const WholeMatic = styled.span({
  display: 'inline-block',
  whiteSpace: 'nowrap',
});

const WholeGenesis = styled.span({
  display: 'inline-block',
  whiteSpace: 'nowrap',
});

export function displayUSD(usd: number, punc?: string) {
  return (
    <WholeUSD>
      {shortUSD(usd)}
      {punc}
    </WholeUSD>
  );
}

export function displayGC(gc: number, punc?: string, showText?: boolean) {
  return (
    <WholeGC>
      {decimalsPrecision8(gc)}
      {showText ? GameCurrency : GameCurrencyLogo}
      {punc}
    </WholeGC>
  );
}

export function displayMatic(matic: number, punc?: string, showText?: boolean) {
  return (
    <WholeMatic>
      <b>{decimalsPrecision8(matic)}</b>
      {showText ? MaticCurrency : MaticCurrencyLogo}
      {punc}
    </WholeMatic>
  );
}

export function displayGenesis(
  genesis: number,
  punc?: string,
  showText?: boolean,
) {
  return (
    <WholeGenesis>
      <b>{decimalsPrecision8(genesis)}</b>
      {showText ? GenesisCurrency : GenesisCurrencyLogo}
      {punc}
    </WholeGenesis>
  );
}
