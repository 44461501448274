import styled from 'styled-components';
import { theme } from 'src/theme';
import { Media } from 'src/constants';

export const EmailSignupWrapper = styled.div`
  background: ${theme.colors.purple};

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  padding: 32px 0;
  max-width: 100%;

  ${Media.desktop} {
    padding: 3rem 0;
  }
`;

export const EmailInputTitle = styled.h1`
  text-align: center;
  color: white;
  line-height: 39px;
`;

export const EmailInputSubTitle = styled.h5`
  line-height: 24px;
  text-align: center;
  color: white;
  margin: 16px 0;
  max-width: 480px;
  padding: 0 20px;

  ${Media.tablet} {
    padding: 0;
  }
`;

interface EmailInputProps {
  isError?: boolean;
}

export const EmailInput = styled.input<EmailInputProps>`
  background: ${theme.colors.white};
  border: 1px solid
    ${(props) => (props.isError ? theme.colors.red : theme.colors.darkGrey)};
  box-sizing: border-box;
  font-size: 16px;
  max-width: 345px;
  width: 100%;
  padding: 8px 14px;
  color: ${(props) => props.isError && theme.colors.red};

  &::placeholder {
    color: ${theme.colors.darkGrey};
  }
`;
