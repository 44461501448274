import { theme } from 'src/theme';
import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import { MobileProps } from '..';
import { Media } from 'src/constants';

type WrapperProps = SpaceProps & ColorProps & MobileProps;

export const BottomConsoleStyle = styled.div<
  WrapperProps & { loaded: boolean }
>(
  (props) => ({
    position: 'fixed',
    transition: 'transform 0.5s ease-in-out 1.0s',
    transform: props.loaded ? 'translateY(0)' : 'translateY(150%)',
    zIndex: 101, // under modals & the header, over content
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    right: 0,
    textAlign: 'center',
    [Media.desktop]: {
      display: 'none',
      maxWidth: `calc(800px - ${theme.spacing.desktopDouble})`,
      margin: 'auto',
    },
  }),
  space,
  color,
);

export const BottomConsoleRow = styled.div<WrapperProps>(
  () => ({
    justifyContent: 'center',
    background: theme.colors.white,
    display: 'flex',
    padding: theme.spacing.mobileSingle,
    [Media.desktop]: {
      maxWidth: `calc(800px - ${theme.spacing.desktopDouble})`,
      margin: 'auto',
    },
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px -3px 5px',
    '> div': {
      maxWwidth: '100%',
      width: '376px',
      '> div': {
        a: {
          width: 'unset',
        },
      },
    },
  }),
  space,
  color,
);

export const ConsoleButtonsStyle = styled.div<WrapperProps>(
  () => ({
    display: 'flex',
    flexDirection: 'row',
  }),
  space,
  color,
);

export const GenesisCurrencyTile = styled.div<WrapperProps>(
  () => ({
    cursor: 'pointer',
    minWidth: '100px',
    maxWidth: '180px',
    margin: 'auto',
    // backgroundColor: theme.colors.black,
    color: theme.colors.blue,
    boxShadow: `0px 0px 0px 2px ${theme.colors.blue} inset`,
    //borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    height: '40px',
    ':hover': {
      backgroundColor: theme.colors.blue,
      color: theme.colors.white,
    },
  }),
  space,
  color,
);

export const GameCreditsCurrencyTile = styled.div<WrapperProps>(
  () => ({
    cursor: 'pointer',
    minWidth: '100px',
    maxWidth: '180px',
    margin: 'auto',
    // backgroundColor: theme.colors.black,
    color: theme.secondaryColors.gameCreditsGreen,
    boxShadow: `0px 0px 0px 2px ${theme.secondaryColors.gameCreditsGreen} inset`,
    //borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    height: '40px',
    ':hover': {
      backgroundColor: theme.secondaryColors.gameCreditsGreen,
      color: theme.colors.white,
    },
  }),
  space,
  color,
);

export const CurrencyImage = styled.img<WrapperProps>(() => ({
  width: '24px',
  height: '24px',
  outline: 'none',
  margin: '8px',
}));

export const CurrencyP = styled.p<WrapperProps>(
  () => ({
    margin: '0',
    padding: '0 16px 0 0',
    lineHeight: '40px',
    fontSize: theme.fontSizes[2],
    fontWeight: theme.fontWeights.bold,
  }),
  space,
  color,
);

export const ConsoleButtonTile = styled.div<WrapperProps>(
  () => ({
    flex: 1,
    minWidth: '100px',
    maxWidth: '180px',
    margin: 'auto',
  }),
  space,
  color,
);
