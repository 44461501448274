import { call, put, takeLatest } from 'redux-saga/effects';
import { ClaimStoryblok, StoryStoryblok } from 'src/typings';
import { getAllClaims, getAllStories } from 'src/util';

import { GET_STORIES } from '../ActionTypes';
import * as CreateStoriesActions from './Actions';
import { reloadAccountDataAction } from '../Account/Actions';

export function* getStoryData() {
  try {
    //TODO: get language from redux
    const lang = null;
    const stories: StoryStoryblok[] = yield call(getAllStories, lang);
    const claims: ClaimStoryblok[] = yield call(getAllClaims, lang);
    // const {navigation, fromMain} = payload;
    yield put(CreateStoriesActions.createStoryDataSuccess({ stories, claims }));
    yield put(reloadAccountDataAction());
  } catch (e) {
    yield put(CreateStoriesActions.createStoryDataError(e));
  }
}

export function* getStoriesSaga() {
  yield takeLatest(GET_STORIES, getStoryData);
}

export function* setLanguageSaga() {
  // yield takeLatest(SET_STORIES_LANGUAGE, null, 'eng');
}
