import React, { useEffect } from 'react';
import { ButtonType } from 'src/typings';
import { ModalBase, ModalProps, ModalHeader, ModalBody } from '.';
import { ActionButton, NavButton } from 'src/components/buttons';
import { useSelector } from 'react-redux';
import { AppState } from 'src/redux/CreateStore';
import { useState } from 'react';
import Input from '../numericalInput';
import { farmingDepositTx } from 'src/transactions/farmingDepositTx';
import {
  floatToWei,
  shortUSD,
  ethToDecimal,
  FarmApr,
  getAprAfterAddingGame,
} from 'src/util';
import { useTranslation } from 'react-i18next';
import { DataTable, TableDataFormat, TableRowData } from '../table';
import { VerticalSpacer } from '..';
import { getGameBalanceFromContract } from 'src/calls';
import { Routes } from 'src/constants/routes';

export function FarmDepositModal(props: ModalProps & { farmApr: FarmApr }) {
  const { visible, show, farmApr } = props;
  const { t } = useTranslation();

  // Selectors
  const myBalances = useSelector((state: AppState) => state.Account.myBalances);
  const addy = useSelector((state: AppState) => state.Account.address);

  // State
  const [formAmount, setFormAmount] = useState('0.0');
  const [rpcGameBalance, setRpcGameBalance] = useState(BigInt(-1));

  // Setting the form amount on the modal;
  const formFloat = parseFloat(formAmount);

  // Getting the user's up-to-date GAME balance, from the RPC node
  // This is used to cover the cases where the Moralis node doesn't update correctly
  // It's used here because Farm Deposit is a critical user function.
  const friendlyGameBalance =
    rpcGameBalance >= 0
      ? ethToDecimal(rpcGameBalance)
      : ethToDecimal(myBalances.game);

  // Update form and game balance when the moralis game balance changes
  // AND when the modal opens
  async function getGameBal() {
    const bal = await getGameBalanceFromContract(addy);
    console.log('got game bal', bal.toString());
    setRpcGameBalance(BigInt(bal.toString()));
  }
  useEffect(() => {
    if (visible) {
      getGameBal();
    }
  }, [friendlyGameBalance, visible]);
  useEffect(() => {
    setFormAmount(friendlyGameBalance.toString());
  }, [friendlyGameBalance]);

  // Handler for sending the farm transaction.
  // Limit to the RPC game balance, if known, or the Moralis GAME balance otherwise
  const sendFarmTx = async () => {
    const maxGameBalance =
      rpcGameBalance >= 0 ? rpcGameBalance : myBalances.game;
    const formGameBalance = BigInt(floatToWei(formAmount));
    const farmAmount =
      maxGameBalance < formGameBalance ? maxGameBalance : formGameBalance;
    await farmingDepositTx(farmAmount.toString());
    show(false);
  };

  // Handler for form input
  function onFormAmountInput(input: string) {
    const endPoint = input.endsWith('.') ? '.' : '';
    if (isNaN(parseFloat(input))) {
      console.log('setting form amount nan');
      setFormAmount(`0${endPoint}`);
    } else {
      let inputNum = parseFloat(input);
      inputNum = inputNum <= 0.0 ? 0.0 : inputNum;
      if (inputNum > friendlyGameBalance) {
        setFormAmount(`${friendlyGameBalance}`);
      } else {
        setFormAmount(`${inputNum}${endPoint}`);
      }
    }
  }

  // Get Farm balance and calculate APR based on it
  const friendlyFarmBalance = ethToDecimal(myBalances.farm);
  const updatedApr = getAprAfterAddingGame(farmApr, formFloat);
  const myGenesisEarnings =
    ((friendlyFarmBalance + formFloat) / updatedApr.gameLocked) *
    updatedApr.genesisPerDay;

  // Display table of GAME/Farm data
  const isLoading = myBalances.isLoading;
  const farmRows: TableRowData[] = [
    {
      label: t('modal.farm.table.totalStaked'),
      data: farmApr.gameLocked,
      dataFormat: TableDataFormat.Game,
      usdRatio: farmApr.gamePrice,
      isLoading,
    },
    {
      label: t('modal.farm.table.currentStake'),
      data: friendlyFarmBalance,
      dataFormat: TableDataFormat.Game,
      usdRatio: farmApr.gamePrice,
      isLoading,
    },
    {
      label: t('modal.farm.table.genesisPerDay'),
      data: updatedApr.genesisPerDay,
      dataFormat: TableDataFormat.Genesis,
      usdRatio: farmApr.genesisPrice,
      isLoading,
    },
    {
      label: t('modal.farm.table.gameBalance'),
      data: friendlyGameBalance,
      dataFormat: TableDataFormat.Game,
      usdRatio: farmApr.gamePrice,
      isLoading,
    },
  ];

  return (
    <ModalBase visible={visible} show={show}>
      <>
        <ModalHeader>
          <h3>{t('modal.farm.title')}</h3>
        </ModalHeader>
        <ModalBody>
          <DataTable rows={farmRows} />
          <VerticalSpacer />
          <p>{t('modal.farm.choose')}</p>
          <Input value={formAmount} onUserInput={onFormAmountInput} />
          <p>
            {t('modal.farm.earnings', {
              genesisPerDay: myGenesisEarnings.toFixed(0),
              usd: shortUSD(myGenesisEarnings * updatedApr.genesisPrice),
            })}{' '}
            {t('modal.farm.apr')} <b>{updatedApr.apr.toFixed(2)}%</b>
          </p>
          <ActionButton
            type={ButtonType.primary}
            onClick={sendFarmTx}
            isDisabled={parseFloat(formAmount) === 0}
          >
            {t('modal.farm.button')}
          </ActionButton>
        </ModalBody>
        <ModalBody>
          <p>{t('modal.farm.getGame')}</p>
          <NavButton
            text={t('button.getGame')}
            type={ButtonType.primary}
            link={Routes.getGame}
          />
        </ModalBody>
      </>
    </ModalBase>
  );
}
