import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

import LeftArrowWhiteImg from 'src/img/left_arrow_white.svg';
import LeftArrowImg from 'src/img/left_arrow.svg';
import { theme } from 'src/theme';
import { useTranslation } from 'react-i18next';

const ButtonContainer = styled.div`
  height: 40px;
  border: 1px solid ${theme.colors.lightGrey};
  box-sizing: border-box;
  padding: 0 8px;
  display: flex;
  align-items: center;
  margin-right: auto;
  cursor: pointer;
`;

const ButtonImage = styled.img`
  width: 9px;
  height: 18px;
  margin-right: 8px;
`;

export const BackButton = ({
  white = false,
  marginBottom = '0px',
  height = '40px',
  border = `1px solid ${theme.colors.lightGrey}`,
  padding = '0 8px',
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <ButtonContainer
      onClick={() => navigate(-1)}
      style={{ marginBottom, height, border, padding }}
    >
      <ButtonImage src={white ? LeftArrowWhiteImg : LeftArrowImg} />
      {t('claims.back')}
    </ButtonContainer>
  );
};

BackButton.propTypes = {
  backUrl: PropTypes.string,
  white: PropTypes.bool,
  marginBottom: PropTypes.string,
  height: PropTypes.string,
  border: PropTypes.string,
  padding: PropTypes.string,
};
