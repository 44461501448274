// lib/hubspot.js
import axios from '../util/axios';

export const submitHubspotForm = async (email: string) => {
  const portalId = '20883044'; // hubspot portal ID
  const formGuid = '2ddd2cb7-1408-4b2d-a7b8-6c8b919fc6bb'; // hubspot form GUID
  const config = {
    // important!
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const response = await axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields: [
          {
            name: 'email',
            value: email,
          },
        ],
      },
      config,
    );
    return response;
  } catch (error) {
    return null;
  }
};
