import React, { useState } from 'react';
import ReactPlayer from 'react-player';

type VideoProps = {
  videoUrl: string;
  imgWidth: number;
  imgHeight: number;
  autoplay?: boolean;
};

export const Video = ({
  videoUrl,
  imgWidth,
  imgHeight,
  autoplay,
}: VideoProps) => {
  const [play, setPlay] = useState(autoplay);
  const handleMouseEnter = () => {
    setPlay(true);
  };
  const widthString = `${imgWidth}px`;
  const heightString = `${imgHeight}px`;

  return (
    <div
      onMouseEnter={handleMouseEnter}
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-50%)',
      }}
    >
      <ReactPlayer
        key={videoUrl} // Don't delete the key field; it ensures the player reloads correctly
        width={widthString}
        height={heightString}
        playing={play}
        pip
        playsinline
        autoPlay
        stopOnUnmount
        controls={false}
        config={{
          vimeo: {
            playerOptions: {
              width: widthString,
              height: heightString,
              muted: true,
              loop: true,
              autoplay: !!autoplay,
              dnt: true,
            },
          },
          file: { forceHLS: true },
        }}
        url={videoUrl}
      />
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
        }}
      />
    </div>
  );
};
