import { contract } from 'src/contracts';
import { USDPrices } from 'src/redux/Contracts/Reducer';

// get pair addresses for g/q and g/e
// get pair amounts for game/quick and game/eth
// we already have decent ratios for matic/quick and matic/eth

interface Reserves {
  game: number;
  other: number;
  k: number;
}

export interface ReserveData {
  quickGame: Reserves;
  ethGame: Reserves;
}

export interface MaticPrice {
  price: number;
  path: string[];
}

export function calculateMaticPrice(
  reserves: ReserveData,
  prices: USDPrices,
  priceInGame: number,
): MaticPrice {
  const { quickGame, ethGame } = reserves;
  if (!quickGame?.game || !ethGame?.game) {
    return { price: 0, path: [] };
  }
  const { quick: quickPrice, eth: ethPrice, matic: maticPrice } = prices;
  const modifiedPriceInGame = priceInGame * 1.2;
  const quickNeeded = calcPriceFromReserves(quickGame, modifiedPriceInGame);
  const maticSpentViaQuick = (quickNeeded * quickPrice) / maticPrice;
  const ethNeeded = calcPriceFromReserves(ethGame, modifiedPriceInGame);
  const maticSpentViaEth = (ethNeeded * ethPrice) / maticPrice;
  if (maticSpentViaQuick <= maticSpentViaEth && maticSpentViaQuick > 0) {
    const mp: MaticPrice = {
      price: Math.ceil(maticSpentViaQuick * 100) / 100,
      path: [contract.side.wmatic, contract.side.quick, contract.side.game],
    };
    return mp;
  } else if (maticSpentViaEth > 0) {
    const mp: MaticPrice = {
      price: Math.ceil(maticSpentViaEth * 100) / 100,
      path: [contract.side.wmatic, contract.side.weth, contract.side.game],
    };
    return mp;
  } else {
    return { price: -1, path: [] };
  }
}

function calcPriceFromReserves(reserves: Reserves, gamePrice: number) {
  const { game, other, k } = reserves;
  if (gamePrice * 2 > game) {
    return -1;
  }
  const otherNeeded = k / (game - gamePrice) - other;
  return otherNeeded;
}
