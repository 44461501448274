import { theme } from 'src/theme';
import styled from 'styled-components';
import { space, color } from 'styled-system';

export const DataTableBody = styled.div(
  () => {
    return {
      fontFamily: 'Montserrat',
      display: 'flex',
      flexDirection: 'column',
      margin: `0 auto`,
      maxWidth: '375px',
      minWidth: '344px',
    };
  },
  space,
  color,
);

export const DataTableHeader = styled.div(
  () => {
    return {
      fontFamily: 'Montserrat',
      padding: '8px 0',
      textTransform: 'uppercase',
      fontWeight: theme.fontWeights.bold,
      textAlign: 'center',
      borderBottom: `solid 1px ${theme.colors.blue}`,
    };
  },
  space,
  color,
);
export const DataTableRow = styled.div(
  () => {
    return {
      padding: '8px 0',
      height: '36px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      textTransform: 'uppercase',
      borderBottom: `solid 1px ${theme.colors.blue}`,
    };
  },
  space,
  color,
);
export const DataTableCell = styled.div(
  () => {
    return {
      textAlign: 'right',
      flex: 1.2,
      padding: '0',
      margin: 0,
    };
  },
  space,
  color,
);
export const DataTableP = styled.p(
  () => {
    return {
      margin: 0,
      fontSize: theme.fontSizes[0],
      fontWeight: 700,
      whiteSpace: 'nowrap',
      lineHeight: '20px',
    };
  },
  space,
  color,
);
export const DataTableNote = styled.p(
  () => {
    return {
      textAlign: 'right',
      flex: 1,
      padding: '0 8px',
      margin: 0,
      fontSize: theme.fontSizes[0],
      lineHeight: '20px',
    };
  },
  space,
  color,
);
export const DataTableLabel = styled.p(
  () => {
    return {
      textAlign: 'left',
      flex: 'none',
      width: '45%',
      padding: '0 8px',
      margin: 0,
      fontSize: theme.fontSizes[0],
      lineHeight: '20px',
    };
  },
  space,
  color,
);
export const DataTableCta = styled.div(
  () => {
    return {
      padding: theme.spacing.mobileSingle,
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex',
    };
  },
  space,
  color,
);
