import React from 'react';
import { PageSectionArtCutoutStyle } from './style';

export enum CutoutLocation {
  top = 'top',
  bottom = 'bottom',
  left = 'left',
  right = 'right',
}

export function ArtCutout(location: CutoutLocation, isMobile: boolean) {
  const isLeftLayout = location === CutoutLocation.left;
  const isRightLayout = location === CutoutLocation.right;
  const width = isMobile || isLeftLayout || isRightLayout ? 288 : 480;
  const height = isMobile ? 16 : 24;
  const isHorizontal =
    location === CutoutLocation.top || location === CutoutLocation.bottom;
  const viewBox = isHorizontal
    ? `0 0 ${width} ${height}`
    : `0 0 ${height} ${width}`;

  return (
    <PageSectionArtCutoutStyle
      id="trapezoid"
      viewBox={viewBox}
      location={location}
      height={isHorizontal ? height : width}
    >
      <path d={getPath(location, width, height)} fill="white" />
    </PageSectionArtCutoutStyle>
  );
}

function getPath(location: CutoutLocation, w: number, h: number) {
  switch (location) {
    case CutoutLocation.top:
      return `M0,0 L${h},${h} L${w - h},${h} L${w},0z`;
    case CutoutLocation.bottom:
      return `M${h},0 L0,${h} L${w},${h} L${w - h},0z`;
    case CutoutLocation.left:
      return `M${h},0 L0,${h} L0,${w - h} L${h},${w}z`;
    case CutoutLocation.right:
      return `M0,0 L${h},${h} L${h},${w - h} L0,${w},z`;
    default:
      return '';
  }
}
