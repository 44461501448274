import { eps, farmingStartTime, oneDay, oneYear } from 'src/constants';
import { ethToDecimal } from '.';
import { BigNumberish } from '@ethersproject/bignumber';
import { USDPrices } from 'src/redux/Contracts/Reducer';

export interface FarmApr {
  apr: number;
  gameLocked: number;
  gamePrice: number;
  genesisPerDay: number;
  genesisPrice: number;
}

export function getAprAfterAddingGame(farmApr: FarmApr, gameAdded: number) {
  const newApr = {
    ...farmApr,
    gameLocked: farmApr.gameLocked + gameAdded,
  };
  newApr.apr =
    newApr.gameLocked == 0 || newApr.gamePrice == 0
      ? 0
      : (365 * 100 * newApr.genesisPerDay * newApr.genesisPrice) /
        newApr.gameLocked /
        newApr.gamePrice;
  return newApr;
}

export function calculateFarmApr(
  farmTotalLocked: BigNumberish,
  prices: USDPrices,
) {
  const farmApr: FarmApr = {} as FarmApr;
  farmApr.gamePrice = prices.game;
  farmApr.genesisPrice = prices.genesis;

  farmApr.gameLocked = ethToDecimal(farmTotalLocked);

  const now = Date.now().valueOf() / 1000;
  const genesisPerDay = calculateGenesisPerDay(now).toString();

  farmApr.genesisPerDay = ethToDecimal(genesisPerDay);

  return getAprAfterAddingGame(farmApr, 0);
}

export function calculateGenesisPerDay(timestamp: number): BigInt {
  const startTime = farmingStartTime;
  timestamp = timestamp < startTime ? startTime : timestamp;
  const time = BigInt(Math.floor(timestamp - startTime));
  const timePlusOneDay = time + oneDay;
  const emittedt0 = (time * eps * oneYear) / (oneYear + time);
  const emittedt1 =
    (timePlusOneDay * eps * oneYear) / (oneYear + timePlusOneDay);
  return emittedt1 - emittedt0;
}
