import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { VerticalSpacer } from 'src/components';
import { ActionButton, CtaButton, NavButton } from 'src/components/buttons';
import { ConnectButton } from 'src/components/buttons/ConnectButton';
import { FarmGenesisSection, SimpleClaimGrid } from 'src/components/datadriven';
import { EarnLi } from 'src/components/datadriven/EarnSection/style';
import { Routes } from 'src/constants/routes';
import { NO_ADDRESS } from 'src/redux/Account/Reducer';
import { AppState } from 'src/redux/CreateStore';
import { collectMiningEmissionsTx } from 'src/transactions/collectMiningEmissions';
import {
  ButtonType,
  StoryblokComponentType,
  StoryblokDesktopLayout,
  StoryblokWorldGridLayout,
} from 'src/typings';
import { calculateTotalGenesisMined, web3Modal } from 'src/util';
import { PageSectionTextStyle } from '../PageSection/style';

export function AccountSection() {
  const address = useSelector((state: AppState) => state.Account.address);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const myBalances = useSelector((state: AppState) => state.Account.myBalances);
  const lastEmission = useSelector(
    (state: AppState) => state.Account.lastEmission,
  );
  const storedEmission = useSelector(
    (state: AppState) => state.Account.worldEmission,
  );
  const claimBalances = useSelector(
    (state: AppState) => state.Account.myClaims,
  );
  const globalClaims = useSelector(
    (state: AppState) => state.Contracts.globalClaims,
  );
  const prices = useSelector((state: AppState) => state.Contracts.prices);

  const genesisMined = calculateTotalGenesisMined(
    globalClaims[1]?.onSale,
    myBalances.world,
    lastEmission,
    storedEmission,
  );

  function disconnectAndLogOut() {
    web3Modal.clearCachedProvider();
    navigate('/');
    window.location.reload();
  }

  const isLoggedIn = address !== NO_ADDRESS;
  const hasClaims = Object.keys(claimBalances)?.length > 0;
  const myTotalWorlds = myBalances.world;

  return (
    <>
      <VerticalSpacer />
      <PageSectionTextStyle
        layout={StoryblokDesktopLayout.full}
        isFullWidth={true}
      >
        <h3>{t('account.myaccount')}</h3>
        {isLoggedIn ? (
          <>
            <p style={{ textAlign: 'center' }}>{address}</p>
            <CtaButton
              data={{
                _uid: 'disconnect-cta-button',
                text: t('account.disconnect'),
                type: ButtonType.secondary,
                action: '',
                component: StoryblokComponentType.ActionButton,
              }}
              onClick={() => disconnectAndLogOut()}
            />
            <VerticalSpacer />
          </>
        ) : (
          <>
            <VerticalSpacer />
            <ConnectButton />
            <VerticalSpacer />
          </>
        )}
      </PageSectionTextStyle>
      {isLoggedIn && (
        <>
          <PageSectionTextStyle
            layout={StoryblokDesktopLayout.full}
            isFullWidth={true}
          >
            <h3>{t('account.myclaims')}</h3>

            {!hasClaims && <p>{t('account.getyourclaim')}</p>}
            {!hasClaims && (
              <NavButton
                text={t('claims.buyclaim')}
                type={ButtonType.primary}
                link={Routes.mining}
              />
            )}
            {(hasClaims || storedEmission > 0) && (
              <>
                <p>
                  {t('account.mining', {
                    claimsOwned: myTotalWorlds.toString(),
                    genesisMined:
                      lastEmission === 0 && storedEmission == 0
                        ? `(${t('account.loading')})`
                        : genesisMined.toString(),
                  })}{' '}
                </p>
                <ul>
                  <EarnLi>{t('account.collect')}</EarnLi>

                  <EarnLi>{t('account.staking')}</EarnLi>
                </ul>

                <ActionButton
                  type={ButtonType.primary}
                  onClick={() => {
                    collectMiningEmissionsTx();
                  }}
                >
                  {t('button.collect')}
                </ActionButton>
                <SimpleClaimGrid
                  claims={globalClaims}
                  claimBalances={claimBalances}
                  layout={StoryblokWorldGridLayout.mediumGrid}
                  prices={prices}
                />
              </>
            )}
          </PageSectionTextStyle>

          <VerticalSpacer />
          <VerticalSpacer />
          <h3>{t('account.myfarming')}</h3>
          <FarmGenesisSection noHeader />
        </>
      )}
    </>
  );
}
