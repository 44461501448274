import React, { useEffect, useState } from 'react';
import { ButtonType } from 'src/typings';
import { ModalBase, ModalProps, ModalHeader, ModalFooter, ModalBody } from '.';
import { ActionButton } from 'src/components/buttons';
import { useTranslation } from 'react-i18next';
import { VerticalSpacer } from '..';
import { claimBurnPrices, ClaimData } from 'src/constants/worlds';
import styled from 'styled-components';
import { burnNFTForGameTx } from 'src/transactions/burnNFTForGame';
import { getClaimSupplyFromContract } from 'src/calls/contract/getClaimSupply';
import { useSelector } from 'react-redux';
import { AppState } from 'src/redux/CreateStore';
import { SubHeader } from '../datadriven/ClaimSingle/style';
import { approveNFTBurningTx } from 'src/transactions/approveNFTBurning';

export function SellClaimModal(props: ModalProps & { claim: ClaimData }) {
  const { visible, show, claim } = props;
  const { id, name, myBalance, art, totalSupply, onSale } = claim;
  const { t } = useTranslation();
  const isAllowedToTransact = useSelector(
    (state: AppState) => state.Account.isAllowedToTransact,
  );
  const [rpcClaimSupply, setRpcClaimSupply] = useState(-1);

  const claimSupply = rpcClaimSupply >= 0 ? rpcClaimSupply : totalSupply;
  const burnPrice = claimBurnPrices[claimSupply];

  const maxBurnPrice = Math.floor((Date.now() / 1000 - onSale) / 3600);
  const isTooSoon = maxBurnPrice < 168;
  const isReducedPrice = !isTooSoon && maxBurnPrice < burnPrice;
  const actualBurnPrice = isReducedPrice ? maxBurnPrice : burnPrice;

  const saleStartDays = Math.ceil((168 - maxBurnPrice) / 24);
  const days = Math.ceil((burnPrice - maxBurnPrice) / 24);

  const sendTx = async () => {
    await burnNFTForGameTx(claim.id.toString(), 1);
    show(false);
  };

  const approveTx = async () => {
    await approveNFTBurningTx();
  };

  async function getClaimSupplyBal() {
    const claimSupply = await getClaimSupplyFromContract(id);
    setRpcClaimSupply(claimSupply);
  }

  useEffect(() => {
    // This component holds the claim supply data in state
    // So if the claimId changes, the previous state can pollute
    //   the new claim. Setting to -1 prevents this.
    setRpcClaimSupply(-1);
    if (visible) {
      getClaimSupplyBal();
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      getClaimSupplyBal();
    }
  }, [totalSupply]);

  const sellButton = (
    <ActionButton
      type={ButtonType.primary}
      isDisabled={maxBurnPrice < 168}
      onClick={sendTx}
    >
      {t('modal.sellclaim.button')}
    </ActionButton>
  );

  const approveButton = (
    <ActionButton type={ButtonType.primary} onClick={approveTx}>
      {t('modal.sellclaim.approve')}
    </ActionButton>
  );

  return (
    <ModalBase visible={visible} show={show}>
      <>
        <ModalHeader>
          <h3>{t('modal.sellclaim.title')}</h3>
        </ModalHeader>
        <ModalBody>
          <ModalArt src={art} />
          <VerticalSpacer />
          {!isTooSoon && (
            <p>
              {t('modal.sellclaim.explain', {
                name,
                burnPrice: actualBurnPrice,
                myBalance,
              })}
            </p>
          )}
          {isTooSoon && (
            <>
              <SubHeader>{t('modal.sellclaim.waitForSalesWarning')}</SubHeader>
              <p>
                {t('modal.sellclaim.waitForSales', {
                  name,
                  saleStartDays,
                  days,
                  burnPrice,
                  maxBurnPrice,
                })}
              </p>
            </>
          )}
          {isReducedPrice && (
            <>
              <SubHeader>{t('modal.sellclaim.reducedPriceWarning')}</SubHeader>
              <p>
                {t('modal.sellclaim.reducedPrice', {
                  days,
                  burnPrice,
                  maxBurnPrice,
                })}
              </p>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {isAllowedToTransact.isAllowedToSell ? sellButton : approveButton}
        </ModalFooter>
      </>
    </ModalBase>
  );
}

const ModalArt = styled.img(() => ({
  width: '100%',
}));
