import React from 'react';
import {
  NavButtonStoryblok,
  StoryblokButtons,
  StoryblokComponentType,
  StoryblokLinkType,
} from 'src/typings';
import { ButtonText, ButtonStyle, LinkStyle } from '../style';

interface CtaButtonProps {
  data: StoryblokButtons;
  isDisabled?: boolean;
  isNarrow?: boolean;
  tight?: boolean;
  onClick?: () => unknown;
}

export function CtaButton(props: CtaButtonProps) {
  const { data, isDisabled, isNarrow, tight, onClick } = props;

  switch (data.component) {
    case StoryblokComponentType.ActionButton:
      return (
        <ButtonStyle
          onClick={(event) => {
            isDisabled
              ? () => {
                  event.stopPropagation();
                  event.preventDefault();
                }
              : onClick && onClick();
          }}
          className={`actionButton`}
          $variant={data.type}
          key={data._uid}
          $isDisabled={isDisabled}
          $isNarrow={isNarrow}
          $tight={tight}
        >
          {data.text && <ButtonText>{data.text}</ButtonText>}
        </ButtonStyle>
      );
    case StoryblokComponentType.NavButton:
      const link = (data as NavButtonStoryblok).link;
      const isExternalLink = link?.cached_url?.startsWith('http');
      const isNewTab =
        isExternalLink || link.linktype === StoryblokLinkType.url;
      const linkUrl = link?.cached_url
        ? isExternalLink
          ? link.cached_url
          : `/${link.cached_url}`
        : '#';
      return isNewTab ? (
        <ButtonStyle
          onClick={(event) => {
            isDisabled
              ? () => {
                  event.stopPropagation();
                  event.preventDefault();
                }
              : onClick && onClick();
          }}
          href={linkUrl}
          rel={'noopener,noreferrer'}
          target={'_blank'}
          className={`navButton`}
          $variant={data.type}
          key={data._uid}
          $isDisabled={isDisabled}
          $isNarrow={isNarrow}
          $tight={tight}
        >
          {data.text && <ButtonText>{data.text}</ButtonText>}
        </ButtonStyle>
      ) : (
        <LinkStyle
          onClick={() => {
            onClick && onClick();
          }}
          to={linkUrl}
          className={`navButton`}
          $variant={data.type}
          key={data._uid}
          $isDisabled={isDisabled}
          $isNarrow={isNarrow}
          $tight={tight}
        >
          {data.text && <ButtonText>{data.text}</ButtonText>}
        </LinkStyle>
      );
  }
}
