/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { useNavigate } from 'react-router';

import { StoryblokWorldGridLayout } from 'src/typings';
import {
  GridItem,
  GridImage,
  GridCenterText,
  GridItemDetails,
  GridItemDescription,
  animationDuration,
  GridStyles,
  GridImageHolder,
} from './style';
import { useTranslation } from 'react-i18next';
import WorldCarouselCardTemplateImg from 'src/img/world_card_template.svg';
import { ClaimData } from 'src/constants/worlds';
import { noDecimals, threeSignificant } from 'src/util';
import { imageByWidth } from '../../cmsdriven/imageSize';

import { Video } from './hoverVideo';
import { useWindowSize } from 'src/hooks/useWindowSize';
import { USDPrices } from 'src/redux/Contracts/Reducer';

type ClaimCardProps = {
  layout: StoryblokWorldGridLayout;
  item: ClaimData;
  prices: USDPrices;
};

export const MiningClaimCard = (props: ClaimCardProps) => {
  const navigate = useNavigate();
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const { layout, item, prices, ...restProps } = props;
  const {
    id,
    name,
    totalSupply,
    onSale,
    myBalance,
    gameLocked,
    art,
    video,
    buyPrice,
    yearEmission,
  } = item;
  const imgWidth = GridStyles[props.layout].imgWidth;
  const imgHeight = GridStyles[props.layout].imgHeight;
  const image = art
    ? imageByWidth(art, imgWidth)
    : `https://images.genesis.game/worlds/${id}/600x600.jpg`;
  const nowSeconds = Date.now() / 1000;
  const isComingSoon = onSale > nowSeconds;
  const isComingThisWeek =
    isComingSoon && onSale < nowSeconds + 60 * 60 * 24 * 7;
  const isComingThisMonth =
    isComingSoon && onSale < nowSeconds + 60 * 60 * 24 * 35;

  const apr = !prices
    ? null
    : (yearEmission * (prices.genesis / prices.game) * 100) / buyPrice;

  return (
    <GridItem
      layout={layout}
      isForSale={!isComingSoon}
      delay={Math.floor(Math.random() * animationDuration * 1000)}
      onClick={() => navigate(`/mining/claim/${id}`)}
      {...restProps}
    >
      <GridImageHolder layout={layout}>
        <GridImage
          layout={layout}
          src={image ? image : WorldCarouselCardTemplateImg}
        />
        {video && !isMobile && (
          <Video videoUrl={video} imgWidth={imgWidth} imgHeight={imgHeight} />
        )}
      </GridImageHolder>

      <GridCenterText layout={layout}>{name}</GridCenterText>
      {layout !== StoryblokWorldGridLayout.smallGrid &&
        (isComingThisWeek ? (
          <>
            <GridItemDetails layout={layout}>
              <GridItemDescription layout={layout} isCentered={true}>
                <h6>{t('claims.releaseDate')}:</h6>
              </GridItemDescription>
              <GridItemDescription layout={layout} isCentered={true}>
                <h6>
                  {new Date(onSale * 1000).toLocaleDateString()}
                  <br />
                  {new Date(onSale * 1000).toLocaleTimeString()}
                </h6>
              </GridItemDescription>
            </GridItemDetails>
          </>
        ) : isComingSoon ? (
          <GridItemDetails layout={layout}>
            <GridItemDescription layout={layout} isCentered={true}>
              <h6>
                {isComingThisMonth
                  ? t('claims.launch') +
                    ': ' +
                    new Date(onSale * 1000).toLocaleString('default', {
                      month: 'short',
                      day: 'numeric',
                    })
                  : t('claims.comingSoon')}
              </h6>
            </GridItemDescription>
          </GridItemDetails>
        ) : (
          <>
            <GridItemDetails layout={layout}>
              {layout === StoryblokWorldGridLayout.largeGrid && (
                <GridItemDescription layout={layout}>
                  <h6>{t('claims.tvl')}</h6>
                  <h6>{noDecimals(gameLocked)}</h6>
                </GridItemDescription>
              )}
              {apr && (
                <GridItemDescription layout={layout}>
                  <h6>APR</h6>
                  <h6>{threeSignificant(apr)}%</h6>
                </GridItemDescription>
              )}
              <GridItemDescription layout={layout}>
                <h6>
                  {myBalance > 0 ? t('claims.owned') : t('claims.supply')}
                </h6>
                <h6>
                  {myBalance > 0
                    ? `${myBalance}/${totalSupply.toLocaleString()}`
                    : totalSupply.toLocaleString()}
                </h6>
              </GridItemDescription>
            </GridItemDetails>
          </>
        ))}
    </GridItem>
  );
};
