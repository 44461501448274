import { Feature, FeatureToggles } from './featureToggle';

export const isProduction = (): boolean => {
  return (
    process.env.REACT_APP_ENVIRONMENT == Environment.production.toString() ||
    (testFeatureAsProduction != undefined && isDevelopment())
  );
};

export const isDevelopment = (): boolean => {
  return (
    process.env.REACT_APP_ENVIRONMENT == Environment.development.toString()
  );
};

enum Environment {
  production = 'production',
  staging = 'staging',
  development = 'development',
}

const testFeatureAsProduction: Feature =
  Feature[process.env.REACT_APP_OVERRIDDEN_FEATURE] || undefined;

export const overrideToggles = (toggles: FeatureToggles) => {
  if (isDevelopment() && testFeatureAsProduction) {
    console.error('overriding toggle', testFeatureAsProduction);
    toggles[testFeatureAsProduction] = true;
  }
  return toggles;
};

export * from './farming';
export * from './css';
