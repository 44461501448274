import React, { useState } from 'react';
import { CtaButton } from 'src/components/buttons';
import { FaqSectionStoryblok } from 'src/typings';
import { parseRichText } from 'src/util';

import {
  FaqEntryStyle,
  FaqSectionStyle,
  FaqEntryHeader,
  FaqEntryText,
  FaqButton,
} from './style';

interface FaqSectionProps {
  data: FaqSectionStoryblok;
}

export function FaqSection(props: FaqSectionProps) {
  const { data } = props;
  const [visibleSection, setVisibleSection] = useState('');

  function toggleVisibleSection(sectionId: string) {
    setVisibleSection(visibleSection === sectionId ? '' : sectionId);
  }

  const entries = data.entries.map((entry, index) => (
    <FaqEntryStyle
      key={entry._uid}
      first={index === 0}
      onClick={() => toggleVisibleSection(entry._uid)}
    >
      <FaqEntryHeader>{entry.title}</FaqEntryHeader>
      <FaqEntryText expanded={entry._uid === visibleSection}>
        {parseRichText(entry.text_area)}
        {entry.cta &&
          entry.cta.length > 0 &&
          entry.cta.map((button) => (
            <FaqButton key={button._uid}>
              <CtaButton data={button} />
            </FaqButton>
          ))}
      </FaqEntryText>
    </FaqEntryStyle>
  ));

  return <FaqSectionStyle key={data._uid}>{entries}</FaqSectionStyle>;
}
