export function imageByWidth(url: string, width: number) {
  const multiplier = window.devicePixelRatio > 1 ? 2 : 1;
  const end = url.split('a.storyblok.com/');
  return `https://img2.storyblok.com/${width * multiplier}x0/${end[1]}`;
}

export function imageByHeight(url: string, height: number) {
  const multiplier = window.devicePixelRatio > 1 ? 2 : 1;
  const end = url.split('a.storyblok.com/');
  return `https://img2.storyblok.com/0x${height * multiplier}/${end[1]}`;
}

export function imageByWidthHeight(url: string, height: number, width: number) {
  const multiplier = window.devicePixelRatio > 1 ? 2 : 1;
  const end = url.split('a.storyblok.com/');
  return `https://img2.storyblok.com/${height * multiplier}x${
    width * multiplier
  }/${end[1]}`;
}
