import React from 'react';
import { theme } from 'src/theme';

export function successSvg() {
  return (
    <svg
      id={'svg'}
      version={'1.1'}
      xmlns={'http://www.w3.org/2000/svg'}
      width="100"
      height="100"
      viewBox="0, 0, 400,400"
    >
      <g id="svgg">
        <path
          id="path0"
          d="M180.078 0.509 C 57.151 13.726,-24.606 131.499,6.201 250.981 C 33.575 357.147,143.772 421.442,250.981 393.799 C 357.147 366.425,421.441 256.223,393.796 149.007 C 374.273 73.289,311.078 15.795,232.465 2.230 C 223.308 0.650,189.192 -0.471,180.078 0.509 M224.219 33.193 C 333.120 50.785,397.182 162.293,356.221 262.962 C 302.392 395.258,117.901 404.764,51.169 278.681 C -5.706 171.223,64.626 44.179,187.891 31.714 C 194.079 31.088,216.952 32.020,224.219 33.193 M277.680 126.498 C 272.844 127.689,269.839 130.760,223.091 182.287 L 175.478 234.766 151.997 211.058 C 126.139 184.950,124.964 184.046,117.540 184.519 C 106.823 185.201,100.414 195.277,103.926 205.920 C 105.065 209.370,162.740 268.554,168.137 271.810 C 172.473 274.426,178.932 274.426,183.518 271.810 C 187.396 269.598,292.976 153.741,295.385 149.055 C 301.426 137.303,290.472 123.348,277.680 126.498 "
          stroke="none"
          fill={theme.colors.green}
          fillRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}
