import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonType } from 'src/typings';
import { ActionButton } from '../';

export const ConnectButton = () => {
  const { t } = useTranslation();
  return (
    <ActionButton
      type={ButtonType.information}
      onClick={() => {
        console.log('Connecting to Web3');
      }}
    >
      {t('button.connect')}
    </ActionButton>
  );
};
