export interface ShortClaimData {
  id: number;
  name: string;
  art: string;
  description: string;
  onSale: number;
}

export interface ClaimData extends ShortClaimData {
  yearEmission: number;
  dayEmission: number;
  buyPrice: number;
  burnPrice: number;
  totalSupply: number;
  myBalance: number;
  gameLocked: number;
  video: string;
}

// THe smart contract rounds this to 3 decimals;
//   we round down to 0 so we're never overpromising
export function calculateClaimPrice(supply: number) {
  // This calculation exactly mirrors the calculation in the smart contract.
  // The calculation in the smart contract is:
  //   uint256 price = (printNumber**3).mul(1000).div(9**8); // 43046721 is 9**8
  //   price = price.add(printNumber.mul(125));
  //   price = price.add(50000);
  //   price = price.div(priceDivisor).mul(10**17);
  return Math.floor(
    (Math.floor((1000 * supply ** 3) / 43046721) + supply * 125 + 50000) / 250,
  );
}

const claimBurnPrices = [0];
const claimBuyPrices: number[] = [];
const claimTotalLocked = [0];
let totalPrice = 0;
const MIN_BUY_PRICE = 500;

function getClaimBuyPrice(supply: number) {
  return Math.ceil(claimBuyPrices[supply] / 10) * 10;
}

// The smart contract has no minimum buy price, and rounds to 3 significant digits.
// We round up to the nearest 10, and enforce a minimum of 500; this is our cut.
// The first token to be more than 500 GAME is #480 or so.
for (let i = 0; i < 10000; i++) {
  const price = calculateClaimPrice(i + 1);
  // The 1.083 ensures that the first 500 claims cost 500 GAME
  // The 1.1 is the actual correct listed price
  const buyPrice = Math.ceil((price * (i <= 500 ? 1.083 : 1.1)) / 100) * 100;
  const burnPrice = Math.floor(price * 0.95);
  claimBuyPrices.push(buyPrice > MIN_BUY_PRICE ? buyPrice : MIN_BUY_PRICE);
  claimBurnPrices.push(burnPrice);
  totalPrice += price;
  claimTotalLocked.push(totalPrice);
}

const PRICE_DISCOVERY_START = MIN_BUY_PRICE;

function calculatePrice(onSale: number, buyPrice: number) {
  if (PRICE_DISCOVERY_START <= MIN_BUY_PRICE) {
    return buyPrice;
  }
  const nowSeconds = Date.now() / 1000;
  const discoveredPrice = Math.ceil(
    PRICE_DISCOVERY_START - nowSeconds + onSale,
  );
  return Math.max(buyPrice, Math.min(PRICE_DISCOVERY_START, discoveredPrice));
}

function getClaimCountByPrice(price: number) {
  const maxClaim = claimBuyPrices.length - 1;
  if (price >= claimBuyPrices[maxClaim]) {
    return maxClaim;
  } else if (price < claimBuyPrices[0]) {
    price = claimBuyPrices[0] + 1;
  }

  let claim = Math.floor(claimBuyPrices.length / 2);
  let gap = claim;
  while (gap > 1) {
    gap = Math.min(Math.ceil(claim / 2), Math.ceil(gap / 2));
    const claimPrice = claimBuyPrices[claim];
    if (claimPrice === price) {
      gap = 0;
    } else if (claimPrice < price) {
      claim = Math.min(maxClaim, claim + gap);
    } else {
      claim = Math.max(0, claim - gap);
    }
  }
  return claim;
}

export {
  claimBurnPrices,
  getClaimBuyPrice,
  claimTotalLocked,
  MIN_BUY_PRICE,
  PRICE_DISCOVERY_START,
  calculatePrice,
  getClaimCountByPrice,
};
