// @flow

import { combineReducers } from 'redux';
import configureStore from './CreateStore';
import rootSaga from './Sagas';
import { reducer as Account } from './Account/Reducer';
import { reducer as Contracts } from './Contracts/Reducer';
import { reducer as Settings } from './Settings/Reducer';
import { reducer as Stories } from './Stories/Reducer';
import { AppState } from './CreateStore';

export * from './Settings';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __PRELOADED_STATE__: any;
  }
}

window.__PRELOADED_STATE__ = window.__PRELOADED_STATE__ || {};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isHeadless = (window as any).navigator.webdriver;

export default () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const preloadedState = window.__PRELOADED_STATE__;
  delete window.__PRELOADED_STATE__;
  const rootReducer = combineReducers({
    Account,
    Contracts,
    Settings,
    Stories,
  });

  return configureStore(rootReducer, rootSaga);
};

export const getMyBalances = (state: AppState) => state?.Account?.myBalances;
export const getAddress = (state: AppState) => state?.Account?.address;
export const getFarmContract = (state: AppState) =>
  state?.Contracts?.contracts?.farm;
export const getGenesisContract = (state: AppState) =>
  state?.Contracts?.contracts?.genesis;
