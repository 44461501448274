import styled from 'styled-components';
import { space, color } from 'styled-system';
import { animated } from '@react-spring/web';

export const TickerHolder = styled.div(
  () => {
    return {
      margin: '8px auto',
      width: 'fit-content',
      display: 'flex',
      flexDirection: 'row',
    };
  },
  space,
  color,
);

export const TickerCurrency = styled.div(
  () => {
    return {
      width: '18px',
    };
  },
  space,
  color,
);

export const GenesisTicker = styled(animated.p)(
  () => {
    return {
      margin: '0',
      width: 'fit-content',
    };
  },
  space,
  color,
);
