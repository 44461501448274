import styled from 'styled-components';
import { space, color } from 'styled-system';
import { CutoutLocation } from '.';

export const PageSectionArtCutoutStyle = styled.svg<{
  location: CutoutLocation;
}>(
  (props) => {
    switch (props.location) {
      case CutoutLocation.top:
        return {
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translate(-50%, 0)',
        };
      case CutoutLocation.bottom:
        return {
          position: 'absolute',
          bottom: 0,
          left: '50%',
          transform: 'translate(-50%, 0)',
        };
      case CutoutLocation.left:
        return {
          position: 'absolute',
          top: '50%',
          right: 0,
          transform: 'translate(0, -50%)',
        };
      case CutoutLocation.right:
        return {
          position: 'absolute',
          top: '50%',
          left: 0,
          transform: 'translate(0, -50%)',
        };
      default:
        return {};
    }
  },
  space,
  color,
);
