import React, { useState } from 'react';
import LinesEllipsis from 'react-lines-ellipsis/lib/html';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import { theme } from 'src/theme';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

export function EllipsisParagraph(props: { text: string }) {
  const [numLine, setNumLine] = useState(5);
  return (
    <ResponsiveEllipsis
      onClick={() => setNumLine(numLine === 5 ? 1000 : 5)}
      unsafeHTML={`<p>${props.text}</p>`}
      maxLine={numLine}
      ellipsisHTML={`<span>...&nbsp;<span 
        style="color: ${theme.colors.black}; 
        cursor: pointer;"><u>Read More</u></span></span>`}
      basedOn="letters"
    />
  );
}
