import ReactGA from 'react-ga';
import { batch } from 'react-redux';
import { store } from 'src';
import { reloadAccountDataAction } from 'src/redux/Account/Actions';
import { updateTransactionDataAction } from 'src/redux/Contracts/Actions';
import {
  AnyTransaction,
  TransactionError,
  TransactionStatus,
} from './transactionTypes';

export async function sendTransaction(
  tx: AnyTransaction,
  callback: (tx: AnyTransaction) => void,
  onError: (error: TransactionError, tx: AnyTransaction) => void,
) {
  function sendCallback(tx: AnyTransaction) {
    tx.status = TransactionStatus.success;
    store.dispatch(updateTransactionDataAction(tx));
    store.dispatch(reloadAccountDataAction());
    callback(tx);
  }

  function sendError(error: TransactionError, tx: AnyTransaction) {
    tx.status = TransactionStatus.failed;
    if (error.code == '4001') {
      tx.status = TransactionStatus.rejected;
    }
    batch(() => {
      store.dispatch(updateTransactionDataAction(tx));
      store.dispatch(reloadAccountDataAction());
    });
    onError(error, tx);
  }

  try {
    const { Contracts } = store.getState();
    const { provider } = Contracts;
    const gasPrice = await provider.getGasPrice();
    const overrides = tx.overrides || {};
    overrides.gasPrice = (gasPrice.toBigInt() * BigInt(115)) / BigInt(100);

    console.log('GAS PRICE', gasPrice.toBigInt(), overrides.gasPrice);
    // sign the TX
    tx.status = TransactionStatus.signing;
    store.dispatch(updateTransactionDataAction(tx));
    console.log('tx getting response', ...tx.params, overrides);
    tx.response = await tx.function(...tx.params, overrides || {});
    console.log('tx sending', tx.response);
    ReactGA.event({
      category: 'Approved',
      action: 'Transaction Approved!',
    });
    tx.status = TransactionStatus.pending;
    store.dispatch(updateTransactionDataAction(tx));
    // Send the tx. Can wait for more than one confirmation if we really want to
    tx.receipt = await tx.response.wait();

    if (tx.receipt) {
      if (tx.receipt.status === 1) {
        ReactGA.event({
          category: 'Success',
          action: 'Transaction successful!',
        });
        sendCallback(tx);
      } else {
        sendError('tx failed', tx);
      }
    }
  } catch (error: TransactionError) {
    console.log('TRANSACTION ERROR:', error);
    if (error.code === 'TRANSACTION_REPLACED') {
      if (!tx.receipt) {
        tx.receipt = error.receipt;
      }

      if (error.cancelled || !tx.receipt) {
        // The transaction was replaced  :'(
        sendError(error, tx);
      } else {
        // The user used "speed up" or something similar
        // in their client, but we now have the updated info
        // OR, we didn't get a receipt, so we can't handle the tx
        sendCallback(tx);
      }
    } else {
      sendError(error, tx);
    }
  }
}
