import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import { Media } from 'src/constants';
import { theme } from 'src/theme';
import { Link } from 'react-router-dom';

type WrapperProps = SpaceProps & ColorProps;

export const SiteHeaderStyle = styled.section<
  WrapperProps & { imageUrl: string }
>(
  (props) => ({
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    paddingTop: '20%',
    height: '460px',
    [Media.desktop]: {
      height: '500px',
      paddingTop: 0,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: theme.colors.white,
    backgroundImage: `url(${props.imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }),
  space,
  color,
);

export const LogoStyle = styled.img(
  () => ({
    boxSizing: 'border-box',
    maxWidth: '100%',
    width: '400px',
    margin: '0 auto',
    filter:
      'drop-shadow(0px 2px 5px black) drop-shadow(0px 0px 5px black) drop-shadow(0px -2px 5px black)',
  }),
  space,
  color,
);

export const SubHeaderStyle = styled.h6(
  () => ({
    filter: 'drop-shadow(0px 0px 5px black) drop-shadow(0px -2px 5px black)',
  }),
  space,
  color,
);

export const SubLogoStyle = styled.img(
  () => ({
    boxSizing: 'border-box',
    maxWidth: '60%',
    width: '200px',
    margin: '0 auto',
    filter: 'drop-shadow(0px 0px 5px black) drop-shadow(0px -2px 5px black)',
  }),
  space,
  color,
);

export const SilentLink = styled(Link)<WrapperProps>(
  () => ({
    color: 'unset',
    ':link': {
      textDecoration: 'none',
    },
    ':visited': {
      textDecoration: 'none',
      color: 'unset',
    },
    ':hover': {
      textDecoration: 'none',
      color: 'unset',
    },
    ':active': {
      textDecoration: 'none',
      color: 'unset',
    },
  }),
  space,
  color,
);

export const TitleStyle = styled.h3<WrapperProps>(
  () => ({
    textTransform: 'uppercase',
    boxSizing: 'border-box',
    marginBottom: '16px',
    filter: 'drop-shadow(0px -2px 2px black) drop-shadow(0px 2px 2px black)',
  }),
  space,
  color,
);

export const CornerRibbon = styled.div<WrapperProps>(
  () => ({
    padding: '4px',
    width: '320px',
    top: '40px',
    right: '-95px',
    [Media.desktop]: {
      top: '60px',
      right: '-70px',
    },
    transform: 'rotate(45deg)',
    backgroundColor: theme.colors.purple,
    position: 'absolute',
  }),
  space,
  color,
);
