/* eslint-disable @typescript-eslint/no-empty-function */
import React, { Dispatch, useEffect, useState } from 'react';
import { WorldGridStoryblok, MiningClaimGridFilterType } from 'src/typings';
import { CtaButton } from 'src/components/buttons';
import { useWindowSize, WindowSizeType } from 'src/hooks/useWindowSize';

import { StoryblokWorldGridLayout } from 'src/typings';
import { WorldGridStyle, GridContainer, CarouselContainer } from './style';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import { ClaimData } from 'src/constants/worlds';
import { useSelector } from 'react-redux';
import { AppState } from 'src/redux/CreateStore';
import { AllClaimData } from 'src/redux/Account/Reducer';
import { MininClaimGridFilter } from './miningClaimGridFilter';
import { MiningClaimCard } from './miningClaimCard';
import { USDPrices } from 'src/redux/Contracts/Reducer';

export { MemoSimpleClaimGrid } from './simpleClaimGrid';
export { SimpleClaimGrid } from './simpleClaimGrid';
export { MiningClaimCard } from './miningClaimCard';

SwiperCore.use([Pagination]);

interface ClaimGridProps {
  data: WorldGridStoryblok;
}

export function ClaimGrid(props: ClaimGridProps) {
  const { data } = props;
  const { layout } = data;
  const now = Date.now() / 1000;

  const { isMobile, windowSize } = useWindowSize();
  const myBalances = useSelector((state: AppState) => state.Account.myBalances);
  const myClaims = useSelector((state: AppState) => state.Account.myClaims);
  const prices = useSelector((state: AppState) => state.Contracts.prices);
  const globalClaims: AllClaimData = useSelector(
    (state: AppState) => state.Contracts.globalClaims,
  );
  const [swiper, setSwiper] = useState(null);
  // We use useEffect to trigger off filter changes, but that doesn't trigger
  // on a reselection of the existing filter
  // filterChanged is the way around that, by incrementing each time the filter changes.
  const [filterChanged, setFilterChanged] = useState(0);
  const [filter, setFilter] = useState<MiningClaimGridFilterType>(
    MiningClaimGridFilterType.All,
  );

  let worldArray: ClaimData[] = Object.values(globalClaims);

  switch (filter) {
    case MiningClaimGridFilterType.All:
      worldArray.sort((a, b) => a.id - b.id);
      break;
    case MiningClaimGridFilterType.MostRecent:
      const recent = worldArray
        .filter((a) => a.onSale <= now)
        .sort((a, b) => b.onSale - a.onSale);
      worldArray = recent.concat(
        layout === StoryblokWorldGridLayout.carousel
          ? worldArray
              .filter((a) => a.onSale > now)
              .sort((a, b) => a.onSale - b.onSale)
              .slice(0, 5)
          : worldArray
              .filter((a) => a.onSale > now)
              .sort((a, b) => a.onSale - b.onSale),
      );
      break;
    case MiningClaimGridFilterType.Upcoming:
      const upcoming = worldArray
        .filter((a) => a.onSale > now)
        .sort((a, b) => a.onSale - b.onSale);
      worldArray = upcoming.concat(
        worldArray
          .filter((a) => a.onSale <= now)
          .sort((a, b) => b.onSale - a.onSale)
          .slice(0, 5),
      );
      break;
    case MiningClaimGridFilterType.MyWorlds:
      worldArray = worldArray
        .filter((a) => myClaims[a.id] > 0)
        .sort((a, b) => myClaims[a.id] - myClaims[b.id]);
      break;
    case MiningClaimGridFilterType.PriceAsc:
      worldArray = worldArray.sort((a, b) =>
        a.onSale > now ? a.onSale : a.totalSupply - b.totalSupply,
      );
      break;
    case MiningClaimGridFilterType.MostPopular:
    case MiningClaimGridFilterType.PriceDesc:
      worldArray = worldArray.sort((a, b) => b.totalSupply - a.totalSupply);
      break;
    case MiningClaimGridFilterType.All:
    default:
      worldArray.sort((a, b) => a.id - b.id);
      break;
  }

  if (data.layout === StoryblokWorldGridLayout.carousel) {
    worldArray = worldArray.slice(0, 10);
  }
  const claimsLength = Object.keys(globalClaims).length;

  useEffect(() => {
    if (swiper && worldArray.length > 0) {
      swiper.slideToLoop(windowSize.width / 320, 0);
      swiper.update();
      swiper.slideToLoop(0, 1000);
    }
  }, [swiper, filterChanged, claimsLength]);

  return (
    <WorldGridStyle>
      <MininClaimGridFilter
        filter={filter}
        showMine={
          data.layout !== StoryblokWorldGridLayout.carousel &&
          myBalances.world > 0
        }
        onChange={(value) => {
          setFilter(value);
          setFilterChanged(filterChanged + 1);
        }}
      />
      {data.title && <h1>{data.title}</h1>}
      <BaseGrid
        isMobile={isMobile}
        data={data}
        worldArray={worldArray}
        windowSize={windowSize}
        setSwiper={setSwiper}
        filterChanged={filterChanged}
        prices={prices}
      />
      {data.cta &&
        data.cta.map((button) => <CtaButton data={button} key={button._uid} />)}
    </WorldGridStyle>
  );
}

const BaseGrid = (props: {
  isMobile: boolean;
  data: WorldGridStoryblok;
  worldArray: ClaimData[];
  windowSize: WindowSizeType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSwiper: Dispatch<any>;
  filterChanged: number;
  prices: USDPrices;
}) => {
  const { isMobile, data, worldArray, windowSize, setSwiper, prices } = props;
  return data.layout === StoryblokWorldGridLayout.carousel ? (
    <CarouselContainer $mobile={isMobile}>
      <Swiper
        centeredSlides={true}
        slidesPerView={windowSize.width / 320}
        slidesPerGroup={1}
        grabCursor={true}
        preventClicks={true}
        preventClicksPropagation={true}
        simulateTouch={true}
        spaceBetween={0}
        freeMode={false}
        scrollbar={false}
        pagination={{
          clickable: true,
        }}
        loop={true}
        observer={true}
        observeParents={true}
        onSwiper={setSwiper}
      >
        {worldArray.map((item) => {
          return (
            <SwiperSlide key={`${data._uid}-${item.id}`}>
              <MiningClaimCard
                layout={StoryblokWorldGridLayout.largeGrid}
                item={item}
                prices={prices}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </CarouselContainer>
  ) : (
    <GridContainer $mobile={isMobile}>
      {worldArray.map((item) => {
        return (
          <MiningClaimCard
            key={`${data._uid}-${item.id}`}
            layout={data.layout}
            item={item}
            prices={prices}
          />
        );
      })}
    </GridContainer>
  );
};
