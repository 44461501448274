import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import { desktopHeaderHeight, Media, mobileHeaderHeight } from 'src/constants';

type WrapperProps = SpaceProps & ColorProps;

export const PageStyle = styled.main<WrapperProps>(
  () => ({
    marginTop: mobileHeaderHeight,
    minHeight: '60vh',
    [Media.desktop]: {
      marginTop: desktopHeaderHeight,
    },
    // display: 'flex',
    // flexDirection: 'column',
    // overflow: 'hidden',
  }),
  space,
  color,
);
