/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { MiningClaimGridFilterType } from 'src/typings';
import {
  WorldGridFilterContent,
  WorldGridFilterRow,
  DropdownFilterWrapper,
} from './style';
import { useTranslation } from 'react-i18next';
import DropdownFilter from 'src/components/dropdownFilter';

interface MiningClaimGridFilterProps {
  filter: MiningClaimGridFilterType;
  showMine: boolean;
  onChange: (value: MiningClaimGridFilterType) => void;
}

export const MininClaimGridFilter = ({
  filter,
  onChange,
  showMine,
}: MiningClaimGridFilterProps) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);
  const filterTexts = {
    [MiningClaimGridFilterType.All]: t('filter.all'),
    [MiningClaimGridFilterType.MostRecent]: t('filter.recent'),
    [MiningClaimGridFilterType.Upcoming]: t('filter.upcoming'),
    [MiningClaimGridFilterType.MostPopular]: t('filter.mostPopular'),
    [MiningClaimGridFilterType.PriceDesc]: t('filter.priceDesc'),
    [MiningClaimGridFilterType.PriceAsc]: t('filter.priceAsc'),
  };

  if (showMine) {
    filterTexts[MiningClaimGridFilterType.MyWorlds] = t('filter.myClaims');
  }

  const content = (
    <WorldGridFilterContent>
      {Object.keys(filterTexts).map((key) => (
        <WorldGridFilterRow
          key={key}
          onClick={() => {
            onChange(Number(key) as MiningClaimGridFilterType);
            setExpanded(!expanded);
          }}
        >
          {filterTexts[key]}
        </WorldGridFilterRow>
      ))}
    </WorldGridFilterContent>
  );

  return (
    <DropdownFilterWrapper style={{ width: '100%', maxWidth: 340 }}>
      <DropdownFilter
        icon={null}
        caption={filterTexts[filter]}
        content={content}
        expanded={expanded}
        onClick={() => setExpanded(!expanded)}
      />
    </DropdownFilterWrapper>
  );
};
