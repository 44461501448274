import * as ActionTypes from '../ActionTypes';
import { MulticallPayload } from '../Contracts/Actions';

export const reloadAccountDataAction = () => ({
  type: ActionTypes.RELOAD_ACCOUNT_DATA,
});

export const reloadAccountDataResult = (data: MulticallPayload) => ({
  type: ActionTypes.RELOAD_ACCOUNT_DATA_RESULT,
  data,
});

export const connectAccountSuccessAction = (data) => ({
  type: ActionTypes.CONNECT_ACCOUNT_SUCCESS,
  data,
});

export const connectAccountError = (data) => ({
  type: ActionTypes.CONNECT_ACCOUNT_ERROR,
  data,
});

export const disconnectAccountAction = () => ({
  type: ActionTypes.DISCONNNECT_ACCOUNT,
});

export const getAllowanceFor = (data: {
  address: string;
  token: string;
  spender: string;
}) => ({
  type: ActionTypes.GET_ALLOWANCE,
  data,
});

export const getAllowanceSuccess = (data: {
  token: string;
  spender: string;
  allowance: string;
}) => ({
  type: ActionTypes.GET_ALLOWANCE_SUCCESS,
  data,
});

export const getAllowanceError = (data) => ({
  type: ActionTypes.GET_ALLOWANCE_ERROR,
  data,
});
