import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { contract } from 'src/contracts';
import { shortUSD } from 'src/util';
import { AppState } from 'src/redux/CreateStore';
import {
  NbspLink,
  TokenDetailsImage,
  TokenDetailsSectionStyle,
  TokenDetailsSectionTextStyle,
} from './style';
import GenesisLogo from 'src/img/svglogo/Logomark.svg';
import GCLogo from 'src/img/GAME-Credits-G-180.png';
import { VerticalSpacer } from 'src/components';
import { ActionButton } from 'src/components/buttons';
import { ButtonType } from 'src/typings';

interface TokenDetailsProps {
  isLoading: boolean;
  mainContract: string;
  sideContract: string;
  coingecko: string;
  coinmarketcap: string;
  image: string;
  token: string;
  details: string;
  description: string;
  supply: string;
  price: number;
}

export function GenesisTokenDetailsSection() {
  const { t } = useTranslation();
  const prices = useSelector((state: AppState) => state.Contracts.prices);
  const props: TokenDetailsProps = {
    isLoading: !prices || prices.genesis == 0,
    mainContract: contract.main.genesis,
    sideContract: contract.side.genesis,
    token: 'genesis',
    image: GenesisLogo,
    coingecko: 'https://www.coingecko.com/en/coins/genesis-worlds',
    coinmarketcap: 'https://coinmarketcap.com/currencies/genesis-worlds',
    details: t('section.tokenDetails.genesis.details'),
    description: t('section.tokenDetails.genesis.description'),
    supply: t('section.tokenDetails.genesis.supply'),
    price: prices.genesis,
  };
  return TokenDetailsSection(props);
}

export function GameTokenDetailsSection() {
  const { t } = useTranslation();
  const prices = useSelector((state: AppState) => state.Contracts.prices);
  const props: TokenDetailsProps = {
    isLoading: !prices || prices.game == 0,
    mainContract: contract.main.game,
    sideContract: contract.side.game,
    token: 'game',
    image: GCLogo,
    coingecko: 'https://www.coingecko.com/en/coins/gamecredits',
    coinmarketcap: 'https://coinmarketcap.com/currencies/gamecredits/',
    details: t('section.tokenDetails.game.details'),
    description: t('section.tokenDetails.game.description'),
    supply: t('section.tokenDetails.game.supply'),
    price: prices.game,
  };
  return TokenDetailsSection(props);
}

function TokenDetailsSection(props: TokenDetailsProps) {
  const {
    mainContract,
    sideContract,
    isLoading,
    image,
    coingecko,
    coinmarketcap,
    details,
    description,
    supply,
    price,
  } = props;
  const { t } = useTranslation();

  async function watchToken() {
    try {
      // Like any RPC method, an error may be thrown.
      window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: contract.side.game, // The address that the token is at.
            symbol: 'GAME', // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            image: `https://genesis.game/${GCLogo}`, // A string url of the token logo
          },
        },
      });
      window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20', // Initially only supports ERC20, but eventually more!
          options: {
            address: contract.side.genesis, // The address that the token is at.
            symbol: 'GENESIS', // A ticker symbol or shorthand, up to 5 chars.
            decimals: 18, // The number of decimals in the token
            image: `https://genesis.game/${GenesisLogo}`, // A string url of the token logo
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <TokenDetailsSectionStyle>
      <TokenDetailsSectionTextStyle>
        <h3>
          <span>
            <TokenDetailsImage src={image} />
            {details}
          </span>
        </h3>
        <p>{description}</p>
        <h5>{supply}</h5>
        <VerticalSpacer />
        <h5>
          <NbspLink
            href={`https://quickswap.exchange/#/swap?outputCurrency=${sideContract}`}
          >
            {t('section.tokenDetails.buy')}
          </NbspLink>
          : {isLoading ? t('section.tokenDetails.loading') : shortUSD(price)}
        </h5>

        <VerticalSpacer />
        <h5>
          <NbspLink
            href={`https://polygonscan.com/address/${sideContract}#code`}
          >
            {t('section.tokenDetails.polygonscan')}
          </NbspLink>{' '}
          |{' '}
          <NbspLink href={`https://etherscan.io/address/${mainContract}#code`}>
            {t('section.tokenDetails.etherscan')}
          </NbspLink>{' '}
          |{' '}
          <NbspLink href={coingecko} target="_blank" rel="noopener noreferrer">
            {t('section.tokenDetails.coingecko')}
          </NbspLink>{' '}
          |{' '}
          <NbspLink href={coinmarketcap}>
            {t('section.tokenDetails.coinmarketcap')}
          </NbspLink>
        </h5>
        <VerticalSpacer />
        <ActionButton type={ButtonType.primary} onClick={() => watchToken()}>
          {t('section.tokenDetails.addToMetamask')}
        </ActionButton>
        <VerticalSpacer />
      </TokenDetailsSectionTextStyle>
    </TokenDetailsSectionStyle>
  );
}
