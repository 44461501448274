import styled, { keyframes } from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import { theme } from 'src/theme';

type WrapperProps = SpaceProps & ColorProps;

export const Wrapper = styled.div<WrapperProps>(
  () => ({
    bottom: 0,
    left: 0,
    overflow: 'hidden',
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 999,
    margin: 0,
    padding: 0,
    fontFamily: 'Open Sans, sans-serif',
    fontSize: '62.5%',
    width: '100%',
    height: '100vh',
  }),
  space,
  color,
);

type LoaderBgProps = {
  blur: boolean;
};

export const LoaderBg = styled.div<LoaderBgProps>(
  () => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }),
  (props) =>
    props.blur
      ? {
          animation: 'fadeIn ease 0.8s',
          '-webkit-animation': 'fadeIn ease 0.8s',
          '-moz-animation': 'fadeIn ease 0.8s',
          '-o-animation': 'fadeIn ease 0.8s',
          '-ms-animation': 'fadeIn ease 0.8s',
          background: 'rgba(0,0,0,0.8)',
        }
      : {
          background: theme.colors.lightGrey,
        },
  (props) =>
    props.blur
      ? `
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      background: transparent;
      -webkit-backdrop-filter: blur(3px) brightness(0.8);
      backdrop-filter: blur(3px) brightness(0.8);
    }
  `
      : '',
);

export const InnerLoader = styled.div({
  position: 'relative',
  left: '50%',
  top: '50%',
  '-webkit-transform': 'translate(-50%, -50%)',
  transform: 'translate(-50%, -50%)',
  width: '100px',
  height: '100px',
  '-webkit-perspective': '780px',
  perspective: '780px',
});

export const cssloadRotateOne = keyframes`
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
`;

export const cssloadRotateTwo = keyframes`
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
`;

export const cssloadRotateThree = keyframes`
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);s
  }
`;

export const InnerDivBasic = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  border-radius: 50%;
  -o-border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
`;

export const InnerDivFirst = styled(InnerDivBasic)`
  left: 0;
  top: 0;
  animation: ${cssloadRotateOne} 1.15s linear infinite;
  border-bottom: 3px solid ${theme.colors.purple};
`;

export const InnerDivSecond = styled(InnerDivBasic)`
  right: 0;
  top: 0;
  animation: ${cssloadRotateTwo} 1.15s linear infinite;
  border-right: 3px solid ${theme.colors.purple};
`;

export const InnerDivThird = styled(InnerDivBasic)`
  right: 0;
  bottom: 0;
  animation: ${cssloadRotateThree} 1.15s linear infinite;
  border-top: 3px solid ${theme.colors.purple};
`;
