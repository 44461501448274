// JS reimplementation of Style Closet scales for use in styled-components
const colors = {
  white: '#ffffff',
  lightGrey: '#CCCCCC',
  midGrey: '#6d6d6d',
  darkGrey: '#434343',
  black: '#000000',
  purple: '#882dc9', // '#664FC9',
  blue: '#301CA8', // '#25A4D6',
  gold: '#f9af1f',
  green: '#5fe281',
  red: '#b62ad8', // '#C4033C',
  transparent: 'transparent',
};

const secondaryColors = {
  redDarkest: '#561944',
  redDark: '#882dc9', // '#8e0e3f',
  orangeDark: '#fb573b',
  blueDark: '#080856', // '#90e5f0',
  gameCreditsGreen: '#81c341',
  greenDark: '#5a8a2b',
};

// These breakpoints aren't correct - we need to work on our global breakpoints, and put them here
const breakpoints = ['31.25em', '43.75em', '46.875em'];
const mediaQueries = {
  small: `@media screen and (min-width: ${breakpoints[0]})`,
  medium: `@media screen and (min-width: ${breakpoints[1]})`,
  large: `@media screen and (min-width: ${breakpoints[2]})`,
};
const fontSizes = [
  '.8rem',
  '1.0rem',
  '1.2rem',
  '1.4rem',
  '1.6rem',
  '1.8rem',
  '2.0rem',
  '2.4rem',
];
const space = [
  '0',
  '.4rem',
  '.8rem',
  '1.2rem',
  '1.6rem',
  '2.0rem',
  '3.2rem',
  '4.8rem',
  '6.4rem',
  '9.6rem',
];

const spacing = {
  mobileHalf: '8px',
  mobileSingle: '16px',
  mobileDouble: '32px',
  desktopSingle: '24px',
  desktopDouble: '48px',
  maxContentWidth: '800px',
};

export interface StyleClosetTheme {
  breakpoints: string[];
  fontSizes: string[];
  space: string[];
  colors: { [key in keyof typeof colors]: string };
  secondaryColors: { [key in keyof typeof secondaryColors]: string };
  mediaQueries: { [key in keyof typeof mediaQueries]: string };
  fontWeights: {
    light: number;
    regular: number;
    bold: number;
  };
  spacing: {
    mobileHalf: string;
    mobileSingle: string;
    mobileDouble: string;
    desktopSingle: string;
    desktopDouble: string;
    maxContentWidth: string;
  };
}

export const theme: StyleClosetTheme = {
  breakpoints,
  fontSizes,
  space,
  colors,
  secondaryColors,
  mediaQueries,
  fontWeights: {
    light: 300,
    regular: 500,
    bold: 700,
  },
  spacing,
};
