import { StoryblokDesktopLayout } from 'src/typings';
import styled from 'styled-components';
import { space, color, ColorProps, SpaceProps } from 'styled-system';
import { Media } from 'src/constants';
import { theme } from 'src/theme';

type WrapperProps = SpaceProps &
  ColorProps & { layout?: StoryblokDesktopLayout } & { hasCta?: boolean };

export const PageSectionStyle = styled.section<WrapperProps>(
  (props) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [Media.desktop]: {
      flexDirection:
        props.layout === StoryblokDesktopLayout.full ? 'column' : 'row',
    },
    justifyContent: 'space-between',
  }),
  space,
  color,
);

enum ArtHeight {
  mobile = '200px',
  leftRight = '400px',
  fullWidth = '400px',
}

function getArtWidth(isFullWidth: boolean) {
  return isFullWidth
    ? '100%'
    : `calc(calc(100% - ${theme.spacing.desktopDouble}) / 2)`;
}

function getTextWidth(isFullWidth: boolean) {
  return isFullWidth
    ? '100%'
    : `calc(calc(100% + ${theme.spacing.desktopDouble}) / 2)`;
}

function getPadding(isMobile: boolean, isFullWidth: boolean, hasCta: boolean) {
  if (isMobile) {
    return `0 ${theme.spacing.mobileSingle} ${theme.spacing.mobileSingle}`;
  } else if (isFullWidth) {
    return `${theme.spacing.desktopSingle} ${theme.spacing.desktopSingle} ${
      hasCta ? theme.spacing.desktopSingle : theme.spacing.mobileSingle
    }`;
  } else {
    return theme.spacing.desktopDouble;
  }
}

export const PageSectionTextStyle = styled.div<
  WrapperProps & { isFullWidth: boolean }
>(
  (props) => {
    const { isFullWidth } = props;
    const isLeft = !isFullWidth && props.layout === StoryblokDesktopLayout.left;
    return {
      //height: props.mobile ? '200px' : isFullWidth ? '240px' : 'unset',
      width: getTextWidth(isFullWidth),
      padding: getPadding(true, isFullWidth, false),
      [Media.desktop]: {
        padding: getPadding(false, isFullWidth, props.hasCta),
      },
      margin: isFullWidth ? 'auto' : '0px',
      maxWidth: theme.spacing.maxContentWidth,
      objectFit: 'cover',
      overflow: 'hidden',
      textAlign: isFullWidth ? 'center' : isLeft ? 'left' : 'right',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: isFullWidth ? 'center' : isLeft ? 'flex-start' : 'flex-end',
    };
  },
  space,
  color,
);

export const PageSectionParagraphStyle = styled.div<
  WrapperProps & { isFullWidth: boolean }
>(
  (props) => {
    const { isFullWidth } = props;
    const isLeft = !isFullWidth && props.layout === StoryblokDesktopLayout.left;
    return {
      textAlign: isFullWidth || isLeft ? 'left' : 'right',
      width: '100%',
    };
  },
  space,
  color,
);

export const ArtBoxCutoutStyle = styled.div<
  WrapperProps & { isFullWidth: boolean }
>(
  (props) => {
    const { isFullWidth } = props;
    return {
      height: ArtHeight.mobile,
      minHeight: ArtHeight.mobile,
      [Media.desktop]: {
        height: isFullWidth ? ArtHeight.fullWidth : '',
        minHeight: isFullWidth ? ArtHeight.fullWidth : ArtHeight.leftRight,
      },
      width: getArtWidth(isFullWidth),
      maxWidth: '100%',
      position: 'absolute',
    };
  },
  space,
  color,
);

export const PageSectionArtBoxStyle = styled.div<
  WrapperProps & { isFullWidth: boolean }
>(
  (props) => {
    const { isFullWidth } = props;
    return {
      height: ArtHeight.mobile,
      minHeight: ArtHeight.mobile,
      [Media.desktop]: {
        height: isFullWidth ? ArtHeight.fullWidth : '',
        minHeight: isFullWidth ? ArtHeight.fullWidth : ArtHeight.leftRight,
      },
      width: getArtWidth(isFullWidth),
      maxWidth: '100%',
      position: 'relative',
      overflow: 'hidden',
    };
  },
  space,
  color,
);

export const PageSectionArtStyle = styled.img<WrapperProps>(
  () => {
    return {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      overflow: 'hidden',

      transform: 'scale(120%)',
      transition: 'transform 3s',
    };
  },
  space,
  color,
);
