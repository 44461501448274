// @flow
import { BigNumber, BigNumberish } from 'ethers';
import { ClaimData } from 'src/constants/worlds';
import { ExtendedFarmData, MiningClaimAllowances } from 'src/contracts';
import { ProviderData } from 'src/util';
import {
  RELOAD_ACCOUNT_DATA,
  GET_ALLOWANCE,
  GET_ALLOWANCE_SUCCESS,
  GET_ALLOWANCE_ERROR,
  CONNECT_ACCOUNT_SUCCESS,
  DISCONNNECT_ACCOUNT,
  RELOAD_ACCOUNT_DATA_RESULT,
} from '../ActionTypes';
import { MulticallPayload } from '../Contracts/Actions';
import { createReducer } from '../CreateReducer';

export const NO_ADDRESS = '0x000000000000000000000000000000000000000f';

export interface Balances {
  erc20: {
    [address: string]: BigNumber;
  };
  erc20friendly: {
    [address: string]: number;
  };
}

export interface AccountState {
  address: string;
  maticBalance: bigint;
  myBalances: MyBalances;
  myClaims: MiningClaims;
  allowances: {
    [token: string]: { [spender: string]: string };
  };
  farmData: ExtendedFarmData;
  lastEmission: number;
  worldEmission: BigNumberish;
  worldEmissionUpdated: number;
  isAllowedToTransact: MiningClaimAllowances;
  error: string;
}

export interface Erc20Balance {
  balance: string;
  decimals: string;
  logo: string;
  name: string;
  symbol: string;
  thumbnail: string;
  token_address: string;
}

export interface MyBalances {
  farm: bigint;
  genesis: bigint;
  game: bigint;
  governance: bigint;
  world: bigint;
  isLoading: boolean;
}

export interface MiningClaims {
  [claimId: number]: number;
}

export interface AllClaimData {
  [claimId: number]: ClaimData;
}

export enum ConnectionState {
  none = 'none',
  noProvider = 'noProvider',
  connecting = 'connecting',
  connected = 'connected',
  loggingOut = 'logingOut',
  failed = 'failed',
}

const INITIAL_STATE: AccountState = {
  address: NO_ADDRESS,
  maticBalance: BigInt(0),
  myBalances: {
    farm: BigInt(0),
    game: BigInt(0),
    genesis: BigInt(0),
    governance: BigInt(0),
    world: BigInt(0),
    isLoading: true,
  },
  myClaims: {},
  allowances: {},
  farmData: {
    friendlyGameBalance: 0,
    friendlyGenesisEarned: 0,
    isLoading: true,
  } as ExtendedFarmData,
  lastEmission: 0,
  worldEmission: 0,
  worldEmissionUpdated: 0,
  isAllowedToTransact: {} as MiningClaimAllowances,
  error: undefined,
};

const reducers = {
  [RELOAD_ACCOUNT_DATA_RESULT]: (
    state: AccountState,
    {
      data,
    }: {
      data: MulticallPayload;
    },
  ) => {
    const { account } = data;
    state.farmData = account.farmData;
    state.lastEmission = account.lastEmission;
    state.maticBalance = account.maticBalance;
    state.myBalances = account.myBalances;
    state.myClaims = account.myClaims;
    state.worldEmission = account.worldEmission;
    state.worldEmissionUpdated = account.worldEmissionUpdated;
    state.isAllowedToTransact = account.isAllowedToTransact;
    return state;
  },
  [CONNECT_ACCOUNT_SUCCESS]: (
    state: AccountState,
    { data }: { data: ProviderData },
  ) => {
    if (state.address != data.address.toLowerCase()) {
      state.address = data.address.toLowerCase();
    }
    return state;
  },
  [DISCONNNECT_ACCOUNT]: () => {
    return INITIAL_STATE;
  },
  [RELOAD_ACCOUNT_DATA]: (state: AccountState) => {
    return state;
  },
  [GET_ALLOWANCE]: (state: AccountState) => {
    return state;
  },
  [GET_ALLOWANCE_SUCCESS]: (state: AccountState, { data }) => {
    const { spender, token, allowance } = data;
    const allowances = {};
    let foundKey = false;
    for (const key in state.allowances) {
      const newAllowance = { ...state.allowances[key] };
      if (key === token) {
        foundKey = true;
        newAllowance[spender] = allowance;
      }
      allowances[key] = newAllowance;
    }
    if (!foundKey) {
      allowances[token] = { [spender]: allowance };
    }
    state.allowances = allowances;
    return state;
  },
  [GET_ALLOWANCE_ERROR]: (state: AccountState, { error }) => {
    state.error = error;
    return state;
  },
};

export const reducer = createReducer(INITIAL_STATE, reducers);

export default reducer;
