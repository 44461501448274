// floating at the bottom
import React from 'react';
import { useSelector } from 'react-redux';
import { contract } from 'src/contracts';
import { AppState } from 'src/redux/CreateStore';
import { ButtonType } from 'src/typings';
import { NavButton } from '../buttons';
import {
  BottomConsoleRow,
  BottomConsoleStyle,
  ConsoleButtonsStyle,
  CurrencyImage,
  CurrencyP,
  GameCreditsCurrencyTile,
  GenesisCurrencyTile,
  ConsoleButtonTile,
} from './style';

import GenesisLogo from 'src/img/svglogo/Logomark.svg';
import GCLogo from 'src/img/GAME-Credits-G-180.png';
import { useTranslation } from 'react-i18next';
import useModal from 'src/hooks/useModal';
import { useState } from 'react';
import { CurrencyDetailsModal } from '../modals/CurrencyDetailsModal';
import { Routes } from 'src/constants/routes';
import { HorizontalSpacer } from '..';
import {
  decimalsBelowTenK,
  ethToDecimal,
  getFriendlyGenesisBalance,
  calculateTotalGenesisMined,
} from 'src/util';
import { ConnectButton } from '../buttons/ConnectButton';

export const BottomConsole = () => {
  const stories = useSelector((state: AppState) => state.Stories.stories);
  return (
    <BottomConsoleStyle loaded={Object.keys(stories).length > 0}>
      <BottomConsoleRow>
        <ConsoleButtons />
      </BottomConsoleRow>
    </BottomConsoleStyle>
  );
};

export const ConsoleButtons = () => {
  const { visible, show } = useModal();
  const { t } = useTranslation();
  const signer = useSelector((state: AppState) => state.Contracts.signer);

  const myBalances = useSelector((state: AppState) => state.Account.myBalances);
  const globalBalances = useSelector(
    (state: AppState) => state.Contracts.globalBalances,
  );
  const lastEmission = useSelector(
    (state: AppState) => state.Account.lastEmission,
  );
  const storedEmission = useSelector(
    (state: AppState) => state.Account.worldEmission,
  );
  const globalClaims = useSelector(
    (state: AppState) => state.Contracts.globalClaims,
  );
  const farmData = useSelector((state: AppState) => state.Account.farmData);
  const [currency, setCurrency] = useState('');

  function onClickGame() {
    setCurrency(contract.side.game);
    show(true);
  }
  function onClickGenesis() {
    setCurrency(contract.side.genesis);
    show(true);
  }

  const gameBalance =
    ethToDecimal(myBalances.farm) + ethToDecimal(myBalances.game);
  const genesisMined = calculateTotalGenesisMined(
    globalClaims[1]?.onSale,
    myBalances.world,
    lastEmission,
    storedEmission,
  );
  const genesisBalance =
    getFriendlyGenesisBalance(myBalances, globalBalances, farmData) +
    genesisMined;

  const hasGame = gameBalance > 0;
  const hasGenesis = genesisBalance > 0;

  const ctaButton = hasGame ? (
    <NavButton
      text={t('button.getGenesis')}
      type={ButtonType.primary}
      link={Routes.getGenesis}
      isNarrow={true}
    />
  ) : (
    <NavButton
      text={t('button.getGame')}
      type={ButtonType.primary}
      link={Routes.getGame}
      isNarrow={true}
    />
  );
  return (
    <ConsoleButtonsStyle>
      {signer ? (
        <>
          {hasGenesis && (
            <GenesisCurrencyTile onClick={() => onClickGenesis()}>
              <CurrencyImage src={GenesisLogo}></CurrencyImage>
              <CurrencyP>{decimalsBelowTenK(genesisBalance)}</CurrencyP>
            </GenesisCurrencyTile>
          )}
          {hasGenesis && <HorizontalSpacer />}
          {(!hasGame || !hasGenesis) && (
            <ConsoleButtonTile>{ctaButton}</ConsoleButtonTile>
          )}
          {hasGame && !hasGenesis && <HorizontalSpacer />}
          {hasGame && (
            <GameCreditsCurrencyTile onClick={() => onClickGame()}>
              <CurrencyImage src={GCLogo}></CurrencyImage>
              <CurrencyP>{decimalsBelowTenK(gameBalance)}</CurrencyP>
            </GameCreditsCurrencyTile>
          )}
        </>
      ) : (
        <ConnectButton />
      )}

      <CurrencyDetailsModal visible={visible} show={show} currency={currency} />
    </ConsoleButtonsStyle>
  );
};
