// 1. Require the Storyblok client
import React from 'react';
import Storyblok, { Richtext } from 'storyblok-js-client';
import { ClaimStoryblok, StoryStoryblok } from 'src/typings';
import HTMLReactParser from 'html-react-parser';
import { store } from 'src';
import { setFeatureTogglesAction } from 'src/redux/Settings/Actions';
import { isProduction } from 'src/constants';
import { testNetOnSaleDates } from 'src/constants/testnetWorlds';
import { Link } from 'react-router-dom';
import { NodeWithChildren, Element } from 'domhandler/lib/node';

// 2. Initialize the client with the preview token
// from your space dashboard at https://app.storyblok.com

const storyBlokAccessToken = isProduction()
  ? 'pmps5r8Ixa7YQU95lqSGLQtt'
  : 'ywchpoNLI6flTzyGRrrpXQtt';

export const storyblok = new Storyblok({
  accessToken: storyBlokAccessToken,
  cache: {
    clear: 'auto',
    type: 'memory',
  },
});

const getFeatureToggles = async () => {
  const toggles = await storyblok.getAll('cdn/datasource_entries', {
    datasource: 'featuretoggles',
    cv: Date.now(),
  });
  store.dispatch(setFeatureTogglesAction(toggles));
};

getFeatureToggles();

export const parseRichText = (data: Richtext) => {
  return HTMLReactParser(storyblok.richTextResolver.render(data), {
    replace: (domNode) => {
      const domElement: Element = domNode as Element;
      if (
        domElement?.attribs?.href &&
        !domElement?.attribs?.href.startsWith('http')
      ) {
        return (
          <Link to={domElement.attribs.href}>
            {
              (domElement.firstChild as NodeWithChildren & { data: unknown })
                ?.data
            }
          </Link>
        );
      }
      return domNode;
    },
  });
};

export const getStory = async (
  slug: string,
  lang?: string,
): Promise<StoryStoryblok> => {
  const url = lang
    ? `cdn/stories/${slug}?language=${lang}`
    : `cdn/stories/${slug}`;

  const result = await storyblok.get(url, {
    version: isProduction() ? 'published' : 'draft',
  });
  return result.data;
};

export const getAllStories = async (
  lang?: string,
): Promise<StoryStoryblok[]> => {
  const url = lang ? `cdn/stories?language=${lang}` : `cdn/stories`;
  const result = await storyblok.get(url, {
    version: isProduction() ? 'published' : 'draft',
    per_page: 100,
    excluding_slugs: 'claims/*',
  });
  return result.data.stories;
};

export const getAllClaims = async (
  lang?: string,
): Promise<ClaimStoryblok[]> => {
  const isProd = isProduction();
  const url = lang ? `cdn/stories?language=${lang}` : `cdn/stories`;
  const result = await storyblok.get(url, {
    version: isProd ? 'published' : 'draft',
    per_page: 100,
    starts_with: 'claims/',
  });

  // handle staging with different on sale dates

  return result.data.stories
    .map((story) => {
      const content = story.content;
      const id = parseInt(content.claimId);
      return {
        id: id,
        name: content.name,
        description: content.description,
        image: content.image.filename,
        video: content.video,
        gif: content.gif,
        onSale: isProd
          ? parseInt(content.onSale || '0')
          : testNetOnSaleDates[id] || 0,
      } as ClaimStoryblok;
    })
    .filter((claim) => !!claim.onSale);
};
