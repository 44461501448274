import { MobileProps } from 'src/components';
import { Media } from 'src/constants';
import { theme } from 'src/theme';
import styled from 'styled-components';
import {
  space,
  color,
  ColorProps,
  SpaceProps,
  BorderColorProps,
  BackgroundColorProps,
  borderColor,
  justifyContent,
  JustifyContentProps,
} from 'styled-system';
import { buttonHeight, FontSize, largeCardWidth } from '../../cmsdriven';

type WrapperProps = SpaceProps & ColorProps & MobileProps;

type ButtonGroupProps = SpaceProps &
  ColorProps &
  MobileProps &
  BorderColorProps &
  BackgroundColorProps &
  JustifyContentProps;

export const WorldSingleStyle = styled.div<WrapperProps>(
  () => ({
    width: '100%',
    maxWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    [Media.desktop]: {
      flexDirection: 'row',
    },
    margin: 'auto',
  }),
  space,
  color,
);

export const NFTImage = styled.img<{ imgWidth: number }>((props) => ({
  width: '100%',
  [Media.desktop]: {
    width: props.imgWidth,
  },
}));

export const NFTVideo = styled.iframe<{ imgWidth: number }>((props) => ({
  width: '100%',
  [Media.desktop]: {
    width: props.imgWidth,
    marginTop: theme.spacing.desktopDouble,
  },
}));

export const PriceDiscoveryText = styled.h5({
  width: '100%',
  padding: theme.spacing.mobileSingle,
  fontVariantNumeric: 'tabular-nums',
  borderBottom: `1px solid ${theme.secondaryColors.redDarkest}`,
});

export function getImageWidth(windowWidth: number) {
  const sidebarWidth = 376;
  const maxPanel = 1024;
  const panelWidth = Math.min(maxPanel, windowWidth - sidebarWidth);
  const gridcount = Math.floor(panelWidth / 136);
  return gridcount * 136 - 16;
}

export const NFTImageContainer = styled.div({
  width: '100%',
  maxWidth: '1024px',
  padding: '0',
  margin: '0',
});

export const NFTImageInner = styled.div({
  width: '100%',
  justifyContent: 'center',
  display: 'flex',
  position: 'relative',
  [Media.desktop]: {
    marginTop: theme.spacing.desktopDouble,
  },
});

export const ContentContainer = styled.div<MobileProps>({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderWidth: '0',
  [Media.desktop]: {
    width: '376px',
  },
});

export const BackButtonContainer = styled.div<MobileProps>(() => ({
  position: 'absolute',
  height: '60px',
  padding: `0 ${theme.spacing.mobileSingle}`,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.colors.lightGrey,
  cursor: 'pointer',
  userSelect: 'none',
}));

export const BackButtonImage = styled.img(() => ({
  width: '10px',
  height: '18px',
  opacity: '0.3',
  marginRight: '12px',
}));

export const BackButtonText = styled.p(() => ({
  margin: '0',
  padding: '0',
}));

export const NFTTitle = styled.h3<MobileProps>(() => ({}));

export const ButtonGroup = styled.div<WrapperProps>(
  () => ({
    width: largeCardWidth,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
  }),
  space,
  color,
);

export const ButtonContainer = styled.div<ButtonGroupProps>(
  (props) => ({
    height: buttonHeight,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #0000',
    cursor: props.borderColor ? 'pointer' : 'default',
  }),
  space,
  color,
  borderColor,
  justifyContent,
);

export const ButtonInnerText = styled.div<WrapperProps>(
  () => ({
    fontSize: theme.fontSizes[2],
    fontWeight: theme.fontWeights.bold,
  }),
  space,
  color,
);

export const SubHeader = styled.h5<WrapperProps>(() => ({}), space, color);

export const SubWorldHeader = styled(SubHeader)<WrapperProps>(
  () => ({
    fontSize: FontSize.mobileHeading,
    margin: `${theme.spacing.desktopDouble} 0 ${theme.spacing.mobileSingle}`,
  }),
  space,
  color,
);

export const GridContainer = styled.div<WrapperProps>(
  () => ({
    padding: `${theme.spacing.mobileSingle} 0`,
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
    justifyContent: 'center',
    maxWidth: '1024px',
    margin: 'auto',
  }),
  space,
  color,
);

export const InnerContainer = styled.div<MobileProps>({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing.mobileSingle,
  margin: `${theme.spacing.mobileSingle} auto`,
  [Media.desktop]: {
    padding: theme.spacing.mobileDouble,
    width: '408px',
  },
  fontFamily: 'Montserrat',
});

export function getComingSoonGradient(isComingThisMonth: boolean) {
  return `background: linear-gradient(
    -45deg,
    ${isComingThisMonth ? theme.colors.blue : theme.colors.midGrey} 0
      10%,
    ${isComingThisMonth ? theme.colors.purple : theme.colors.lightGrey},
    ${isComingThisMonth ? theme.colors.blue : theme.colors.midGrey}
      120%
  );`;
}

export const ComingSoon = styled.div<{ $isComingThisMonth: boolean }>`
  ${(props) => getComingSoonGradient(props.$isComingThisMonth)}
  background-size: 150%;
  color: ${(props) =>
    props.$isComingThisMonth ? theme.colors.white : theme.colors.darkGrey};
`;

export const DownloadLink = styled.a<MobileProps>(() => ({
  textAlign: 'left',
  color: theme.colors.black,
}));
