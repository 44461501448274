import React, { useEffect, useState } from 'react';
import { ButtonType } from 'src/typings';
import {
  ModalBase,
  ModalProps,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalButtonHolder,
} from '.';
import { ActionButton, NavButton } from 'src/components/buttons';
import { useSelector } from 'react-redux';
import { AppState } from 'src/redux/CreateStore';
import { useTranslation } from 'react-i18next';
import { VerticalSpacer } from '..';
import {
  calculatePrice,
  getClaimBuyPrice,
  ClaimData,
} from 'src/constants/worlds';
import { buyNFTWithGameTx } from 'src/transactions/buyNFTWithGame';
import { Routes } from 'src/constants/routes';
import styled from 'styled-components';
import { calculateMaticPrice, getGameBalanceFromContract } from 'src/calls';
import { buyNFTWithMaticTx } from 'src/transactions/buyNFTWithMatic';
import { displayGC, displayMatic, ethToDecimal } from 'src/util';
import { getClaimSupplyFromContract } from 'src/calls/contract/getClaimSupply';
import Input from '../numericalInput';

const MAX_CLAIMS_PURCHASED = 10;

export function BuyClaimModal(props: ModalProps & { claim: ClaimData }) {
  const { visible, show, claim } = props;
  const { name, art, totalSupply, id, onSale } = claim;
  const { t } = useTranslation();

  // Selectors
  const myBalances = useSelector((state: AppState) => state.Account.myBalances);
  const addy = useSelector((state: AppState) => state.Account.address);
  const maticBalance = useSelector(
    (state: AppState) => state.Account.maticBalance,
  );
  const reserves = useSelector((state: AppState) => state.Contracts.reserves);
  const prices = useSelector((state: AppState) => state.Contracts.prices);
  const claimLimits = useSelector(
    (state: AppState) => state.Contracts.claimLimits,
  );

  let maxClaimPurchaseLimit = MAX_CLAIMS_PURCHASED;
  if (totalSupply < 500) {
    maxClaimPurchaseLimit = Math.min(
      MAX_CLAIMS_PURCHASED,
      claimLimits.first500,
    );
  } else if (totalSupply < 1000) {
    maxClaimPurchaseLimit = Math.min(
      MAX_CLAIMS_PURCHASED,
      claimLimits.first1000,
    );
  } else if (totalSupply < 1500) {
    maxClaimPurchaseLimit = Math.min(
      MAX_CLAIMS_PURCHASED,
      claimLimits.first1500,
    );
  } else if (totalSupply < 2000) {
    maxClaimPurchaseLimit = Math.min(
      MAX_CLAIMS_PURCHASED,
      claimLimits.first2000,
    );
  }

  // State
  const [formAmount, setFormAmount] = useState(1);
  const [rpcGameBalance, setRpcGameBalance] = useState(BigInt(-1));
  const [rpcClaimSupply, setRpcClaimSupply] = useState(-1);

  const friendlyMaticBalance = ethToDecimal(maticBalance);
  const friendlyGameBalance =
    rpcGameBalance >= 0
      ? ethToDecimal(rpcGameBalance)
      : ethToDecimal(myBalances.game);

  const claimSupply = rpcClaimSupply >= 0 ? rpcClaimSupply : totalSupply;
  let buyPrice = 0;
  const buyAmount = Math.max(1, formAmount);
  for (let i = 1; i <= buyAmount; i++) {
    buyPrice += calculatePrice(onSale, getClaimBuyPrice(claimSupply + i));
  }

  function onFormAmountInput(input: string) {
    const inputNum = parseInt(input);
    if (isNaN(inputNum) || inputNum < 1) {
      setFormAmount(0);
    } else {
      if (inputNum > maxClaimPurchaseLimit) {
        setFormAmount(maxClaimPurchaseLimit);
      } else {
        setFormAmount(inputNum);
      }
    }
  }

  async function getGameBal() {
    const bal = await getGameBalanceFromContract(addy);
    setRpcGameBalance(BigInt(bal.toString()));
  }

  async function getClaimSupplyBal() {
    const claimSupply = await getClaimSupplyFromContract(id);
    console.log('claimSupply', claimSupply);
    setRpcClaimSupply(claimSupply);
  }

  function updateFormAmount() {
    let initialFormAmount = 0;
    let totalPrice = 0;
    while (
      initialFormAmount < maxClaimPurchaseLimit &&
      totalPrice <= friendlyGameBalance
    ) {
      initialFormAmount++;
      totalPrice += calculatePrice(
        onSale,
        getClaimBuyPrice(claimSupply + initialFormAmount),
      );
      if (totalPrice >= friendlyGameBalance) {
        initialFormAmount--;
      }
    }
    setFormAmount(initialFormAmount);
  }

  useEffect(() => {
    // This component holds the claim supply data in state
    // So if the claimId changes, the previous state can pollute
    //   the new claim. Setting to -1 prevents this.
    setRpcClaimSupply(-1);
    if (visible) {
      getGameBal();
      getClaimSupplyBal();
      updateFormAmount();
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      getGameBal();
      getClaimSupplyBal();
    }
  }, [friendlyGameBalance, totalSupply]);

  const { price: maticPrice, path } = calculateMaticPrice(
    reserves,
    prices,
    buyPrice,
  );

  const fullPrice = BigInt(buyPrice) * BigInt('1000000000000000000');

  const sendBuyWithMaticTx = async () => {
    await buyNFTWithMaticTx(claim.id.toString(), path, maticPrice, buyAmount);
    show(false);
  };

  const sendBuyWithGameTx = async () => {
    await buyNFTWithGameTx(id.toString(), fullPrice.toString(), buyAmount);
    show(false);
  };

  function buyWithGame() {
    sendBuyWithGameTx();
  }

  function buyWithMatic() {
    sendBuyWithMaticTx();
  }

  return (
    <ModalBase visible={visible} show={show}>
      <>
        <ModalHeader>
          <h3>{t('modal.buyclaim.title')}</h3>
        </ModalHeader>
        <ModalBody>
          <ModalArt src={art} />
          <VerticalSpacer />
          <BuyEntryStyle>
            <h4>
              {t('modal.buyclaim.numberToBuy', { max: maxClaimPurchaseLimit })}
            </h4>
            <Input
              value={formAmount > 0 ? `${formAmount}` : ''}
              onUserInput={onFormAmountInput}
              placeholder={'1'}
              style={{ flex: 0.8, margin: 0 }}
            />
          </BuyEntryStyle>
          <VerticalSpacer />
          <p>
            {buyAmount <= 1
              ? t('modal.buyclaim.explain', {
                  name,
                })
              : t('modal.buyclaim.explainMult', {
                  amount: buyAmount,
                  name,
                })}{' '}
            {t('modal.buyclaim.buyWithGame')}{' '}
            {displayGC(buyPrice, maticPrice > 0 ? ',' : '.')}{' '}
            {maticPrice > 0
              ? t('modal.buyclaim.buyWithMatic') + ' '
              : t('modal.buyclaim.maticPriceTooHigh')}
            {maticPrice > 0 && displayMatic(maticPrice, '.')}
          </p>
        </ModalBody>
        <ModalFooter>
          <ModalButtonHolder>
            {friendlyGameBalance >= buyPrice ? (
              <ActionButton
                type={ButtonType.primary}
                onClick={buyWithGame}
                isDisabled={myBalances.game < fullPrice}
              >
                {t('modal.buyclaim.gameButton')}
              </ActionButton>
            ) : (
              <NavButton
                text={t('button.getGame')}
                type={ButtonType.primary}
                link={Routes.getGame}
              />
            )}
            <VerticalSpacer />
            <h6>
              {t('modal.buyclaim.price')} {displayGC(buyPrice)}
            </h6>
            <h6>
              {t('modal.buyclaim.balance')} {displayGC(friendlyGameBalance)}
            </h6>
            {friendlyGameBalance < buyPrice && (
              <>
                <VerticalSpacer />
                <p>
                  {t('modal.buyclaim.nsf', {
                    name,
                    buyPrice,
                    friendlyGameBalance,
                  })}
                </p>
              </>
            )}
          </ModalButtonHolder>
          <ModalButtonHolder>
            <ActionButton
              type={ButtonType.primary}
              onClick={buyWithMatic}
              isDisabled={maticPrice > friendlyMaticBalance || maticPrice <= 0}
            >
              {t('modal.buyclaim.maticButton')}
            </ActionButton>
            <VerticalSpacer />
            {maticPrice >= 0 ? (
              <h6>
                {t('modal.buyclaim.price')} {displayMatic(maticPrice)}
              </h6>
            ) : (
              <h6>{t('modal.buyclaim.priceLoading')}</h6>
            )}
            <h6>
              {t('modal.buyclaim.balance')} {displayMatic(friendlyMaticBalance)}
            </h6>
          </ModalButtonHolder>
        </ModalFooter>
        <ModalBody>
          <p>
            <i>
              {t('modal.buyclaim.note')}
              <br />
              {t('modal.buyclaim.priceNote')}
            </i>
          </p>
        </ModalBody>
      </>
    </ModalBase>
  );
}

const ModalArt = styled.img(() => ({
  width: '100%',
}));

const BuyEntryStyle = styled.div(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));
